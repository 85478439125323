import { registerSheet } from 'react-native-actions-sheet';

import DistanceSelector from './DistanceSelector';
import Dropdown from './Dropdown';
import FullMenuList from './FullMenuList';
import MealPlanList from './MealPlanList';
import MealPlanTypeSelector from './MealPlanTypeSelector';
import MultiVariant from './MultiVariant';
import OrderAgain from './OrderAgain';
import PriceSelector from './PriceSelector';
import Product from './Product';
import PromoDetails from './PromoDetails';
import Prompt from './Prompt';
import PromptWithInput from './PromptWithInput';
import PublicReview from './PublicReview';
import RatingSelector from './RatingSelector';
import ViewReview from './ViewReview';
import WebCheckoutSchedulePicker from './WebCheckoutSchedulePicker';

import modals from './modals';

registerSheet(modals.DISTANCE_SELECTOR, DistanceSelector);
registerSheet(modals.DROPDOWN, Dropdown);
registerSheet(modals.FULL_MENU_LIST, FullMenuList);
registerSheet(modals.MEAL_PLAN_LIST, MealPlanList);
registerSheet(modals.MEAL_PLAN_TYPE_SELECTOR, MealPlanTypeSelector);
registerSheet(modals.MULTIPLE_VARIANT, MultiVariant);
registerSheet(modals.ORDER_AGAIN, OrderAgain);
registerSheet(modals.PRICE_SELECTOR, PriceSelector);
registerSheet(modals.PRODUCT, Product);
registerSheet(modals.PROMO_DETAILS, PromoDetails);
registerSheet(modals.PROMPT, Prompt);
registerSheet(modals.PROMPT_WITH_INPUT, PromptWithInput);
registerSheet(modals.PUBLIC_REVIEW, PublicReview);
registerSheet(modals.RATING_SELECTOR, RatingSelector);
registerSheet(modals.VIEW_REVIEW, ViewReview);
registerSheet(modals.WEB_CHECKOUT_SCHEDULE_PICKER, WebCheckoutSchedulePicker); // used for web only

export {};
