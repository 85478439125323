import { Platform } from 'react-native';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import { globalSpacing } from '../../Theme/styles';

const OsVer = Platform.constants?.['Release'];
const isAndroid12Below = constants.isAndroid && OsVer <= 12; // TODO - change this to detection if android has gesture or on screen navigation

export const scrollProps = {
  bounces: false,
  showsVerticalScrollIndicator: false,
};

export default {
  defaultOverlayOpacity: 0.7,
  gestureEnabled: true,
  safeAreaInsets: { left: 0, bottom: 0, right: 0, top: 0 },
  containerStyle: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    // has bug on library on spacing, can't find related issue for patching the package
    paddingBottom: isAndroid12Below
      ? constants.statusBarHeight
      : constants.isIOS
      ? constants.statusBarHeight // use statusbar height as padding bottom if ios
      : 0,
  },
  indicatorStyle: {
    marginTop: globalSpacing / 2,
    backgroundColor: ThemeColor.darkGray,
    width: 150,
    zIndex: 1,
  },
};
