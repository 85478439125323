/*
  This file is for handling the conditional render of menu
*/
import dayjs from 'dayjs';
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';

import StoreHelper from './Store';

dayjs.extend(dayjsIsSameOrAfter);

// this is the array needed when lodash picking on item object when saving/updating on redux aside from api data
const additionalDataNeededForCart = [
  'active_discount',
  'discount_type',
  'discount_value',
  'is_bulk',
];

const getStock = (itemStock, categoryStock) => {
  const hasCategoryLimit = categoryStock !== -1;
  const hasItemLimit = itemStock !== -1;
  if (hasItemLimit) {
    // if has item limit
    if (hasCategoryLimit) {
      // if has category limit
      if (itemStock >= categoryStock) {
        // if item stock is more than category stock
        return {
          stock: categoryStock,
          isCategoryStock: true,
          hasCategoryLimit,
          hasItemLimit,
        }; // use category stock
      }
    }
  } else if (hasCategoryLimit) {
    // if no item limit but has category limit
    return {
      stock: categoryStock,
      isCategoryStock: true,
      hasCategoryLimit,
      hasItemLimit,
    }; // use category stock
  }
  // if condition above not met
  return {
    stock: itemStock,
    isCategoryStock: false,
    hasCategoryLimit,
    hasItemLimit,
  }; // use item stock
};

// general function for displaying category or item stock purpose
const getCategoryOrItemStock = ({
  item_limit_per_day,
  item_limit_per_time_slot,
  item,
  stock,
}) => {
  const actualStock = item?.stock || stock;
  const geStock = () =>
    actualStock >= 0 && actualStock <= 10 ? actualStock : undefined; // only show stock if low (0 - 10)
  if (
    typeof item_limit_per_day !== 'undefined' ||
    typeof item_limit_per_time_slot !== 'undefined'
  ) {
    // item item_limit_per_day & item_limit_per_time_slot?.[0]?.limit no limit value is 0, unlike stock no limit is -1
    const limit = item_limit_per_day || item_limit_per_time_slot?.[0]?.limit; // index 0 bcs all index is same value
    if (typeof limit === 'number' && limit !== 0) {
      // if item has limit, only show stock
      return geStock();
    } else {
      // if no limit, just return undefined
      return undefined;
    }
  } else {
    // category or not item
    return geStock();
  }
};

/**
 * Get the available stock for an item/category considering the quantity in the cart.
 * @param {object} item - The item object containing its details and stock, including category stock or just { "id/item_id": "", stock: 1, category_name: "", category_stock: 1 }
 * @param {object} shopData - The shop data containing information about items, categories, and their menu or just { id: "", menu_list: [] }
 * @param {object} cartData - The cart data of the shop.
 * @returns {object} - An object containing stock information.
 *   - categoryName (string): The name of the category to which the item belongs.
 *   - isCategoryStock (boolean): Whether the category has its own stock limit independent of individual items.
 *   - remainingStock (number): The remaining stock of the item after checking the cart and item/category limit.
 *   - stock (number): The available stock for the requested item, considering items already in the cart.
 */
const getItemStockConsideringCart = (item, shopData, cartData) => {
  const categoryStock = item?.category_stock || -1;
  const itemStock = item?.stock || -1;
  const { hasCategoryLimit, hasItemLimit, isCategoryStock, stock } = getStock(
    itemStock,
    categoryStock
  );
  const itemId = item.id || item.item_id;
  let remainingStock = stock;
  let isCategory = isCategoryStock; // just re initialize it, will only overwrite it if item stock is not enough for category stock
  let categoryName = '';
  if (!lodashIsEmpty(shopData)) {
    // get store carts of the store
    const storeCarts = StoreHelper.getStoreCartData(shopData.id, cartData);
    if (storeCarts?.items?.length >= 0) {
      // if has cart, check existing quantity on the cart
      let sameCategoryQty = 0;
      let sameItemQty = 0;
      storeCarts.items.forEach((storeCart) => {
        // get this item category on cart item
        const cartItemInCategory = lodashFind(
          shopData.menu_list,
          (menu) => menu.item?.id === storeCart.item_id
        );
        if (cartItemInCategory?.category === item?.category_name) {
          // if from same category
          sameCategoryQty += storeCart.quantity;
          if (storeCart.item_id === itemId) {
            // if same item
            sameItemQty += storeCart.quantity;
          }
        }
      });
      // process the sameCategoryQty & sameItemQty
      if (isCategoryStock) {
        // if hitting the category limit and same category item in cart is less than or equal the stock
        remainingStock = stock - sameCategoryQty; // reduce the same category item to the stock
      } else if (hasCategoryLimit && hasItemLimit) {
        // if has category limit and item limit and same item in cart is less than or equal the stock
        // e.g: categoryStock = 5, itemStock = 3, sameCategoryQty = 3, sameItemQty = 2
        const existingCategoryQty = sameCategoryQty - sameItemQty; // 3 - 2 = 1 (exclude same item on same category)
        const totalCategoryQty = existingCategoryQty + sameItemQty; // 1 + 2 = 3 (count all same category)
        const remainingCategoryStock = categoryStock - totalCategoryQty; // 5 - 3 = 2(check remaining stock)
        const useItemStock = remainingCategoryStock + sameItemQty >= itemStock; // 2 + 2 = 4, 4 >= 3 (check if going to use the item stock instead of category stock)
        if (useItemStock) {
          remainingStock = itemStock - sameItemQty; // 3 - 2 = 1 (store remainingStock)
        } else {
          isCategory = true; // flag limit as category limit
          remainingStock = categoryStock - sameCategoryQty; // 5 - 3 = 2 (store remainingStock)
        }
      } else if (hasItemLimit) {
        // if has item limit / hitting item limit and same item in cart is less than or equal the stock
        remainingStock = stock - sameItemQty; // reduce the same item on the cart to stock
      }
      // handle if remainingStock become < 0 while it has category and item stock limit
      if ((hasCategoryLimit || hasItemLimit) && remainingStock < 0) {
        remainingStock = 0; // set remainingStock as 0 bcs cart qty is more than the stock might be due to other user already bought it
      }
    }
    categoryName = item?.category_name;
  }
  // return the information
  return {
    categoryName,
    isCategoryStock: isCategory,
    remainingStock,
    stock,
  };
};

const hasDiscount = (item) => {
  if (lodashIsEmpty(item)) {
    return false;
  } else if (item.active_discount <= 0) {
    return false;
  } else if (!item.discount_type || !item.discount_value) {
    return false;
  } else if (item.discount_type === 'noDiscount') {
    return false;
  }
  return true;
};

const isItemUnavailable = ({ available, off_dates, item }) => {
  if (Array.isArray(off_dates)) {
    return (
      !available || off_dates.includes(dayjs().format(constants.DBDATE_FORMAT))
    );
  }
  return (
    !available ||
    item?.off_dates.includes(dayjs().format(constants.DBDATE_FORMAT))
  );
};

// only use on store page for not including the item on store menu, getAvailableItem return boolean
const getAvailableItem = (categoryItem, isMealPlan) => {
  const dateNow = dayjs().format(constants.DBDATE_FORMAT);
  const hasStock = categoryItem.stock === -1 || categoryItem.stock > 0;
  const isOffDate = categoryItem.item.off_dates?.includes(dateNow);
  const isAvailable = categoryItem.available && !isOffDate;
  // visible only if visible flag is true or offDate and visible on off date flag is true
  const isVisible =
    categoryItem.visible ||
    (isOffDate && categoryItem.item.is_visible_on_off_date);
  // if has stock, check if available or visible, if no stock just check if visible
  const shouldShow = hasStock ? isAvailable || isVisible : isVisible;
  if (isMealPlan) {
    // menu item should show if shouldShow is true and meal plan
    return shouldShow && categoryItem.item.is_meal_plan;
  } else {
    // menu item should show if shouldShow is true and not meal plan
    return shouldShow && !categoryItem.item.is_meal_plan;
  }
};

const showDiscountDescription = (item) => {
  return item?.active_discount > 0 && !!item?.discount_description;
};

export default {
  additionalDataNeededForCart,
  getCategoryOrItemStock,
  getItemStockConsideringCart,
  hasDiscount,
  isItemUnavailable,
  getAvailableItem,
  showDiscountDescription,
};
