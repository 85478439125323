import React, { useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import ActionSheet from 'react-native-actions-sheet';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import constants from '../../Config/constants';
import routeList from '../../Routes/list';
import ReviewItem from '../../Screens/StoreReviews/ReviewItem';
import ThemeStyle from '../../Theme/styles';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

function ViewReviewSheet({ sheetId }) {
  const navigation = useNavigation();

  const [data, setData] = useState();

  const _onClose = () => modals.hide(modals.VIEW_REVIEW);

  const _onGoToProduct = async (item) => {
    await _onClose();
    modals.show(modals.PRODUCT, { id: item.id });
  };

  const _onViewAllReviews = async () => {
    await _onClose();
    navigation.navigate(routeList.STORE_REVIEWS, {
      id: data.storeId,
      name: data.storeName,
    });
  };

  return (
    <ActionSheet
      id={sheetId}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={ThemeStyle.spacingTopMedium}>
        {!lodashIsEmpty(data) && (
          <ReviewItem {...data} onProductPress={_onGoToProduct} bigAuthorName />
        )}

        <View style={styles.contentContainer}>
          <Button
            status="basic"
            style={ThemeStyle.spacingBottomMedium}
            onPress={_onViewAllReviews}
          >
            View All Reviews
          </Button>

          <Button status="control" onPress={_onClose}>
            Close
          </Button>
        </View>
      </View>
    </ActionSheet>
  );
}

export default ViewReviewSheet;
