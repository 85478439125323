import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import { Icon, Layout, Text } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import dayjs from 'dayjs';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';

import constants from '../../../Config/constants';
import { pluralize } from '../../../Helper';
import CheckoutHelper from '../../../Helper/Checkout';
import Service from '../../../Service';
import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import BreakPoints from '../../../Theme/styles/breakpoints';
import { SECTION_TITLE_LIGHT } from '../../../Theme/typographyProps';

const { ORDER_TYPES } = constants;

const ASAP = CheckoutHelper.ASAP;

const DeliveryEstimate = forwardRef((props, ref) => {
  const {
    disabled,
    deliverySchedule,
    storeHours,
    storeOffDates,
    daysAcceptingInAdvance,
    prepTime,
    orderType,
    toggleScheduleModal,
    onDeliveryScheduleChange,
  } = props;

  const dateOptions = Service.generateDateOptions({
    storeHours: storeHours,
    offDates: storeOffDates,
    daysInAdvance: daysAcceptingInAdvance,
    prepTime: prepTime,
    asap: ASAP,
  });

  useImperativeHandle(ref, () => ({
    _getDateOptions() {
      return dateOptions;
    },
  }));

  const canAsap = lodashIsEqual(dateOptions[0], ASAP);

  const isStandard =
    deliverySchedule?.value === ASAP.value || lodashIsEmpty(deliverySchedule);

  const maximumDate = dateOptions[dateOptions.length - 1];

  const isPickup = orderType?.includes(ORDER_TYPES.PICKUP);

  const hasPreorderDate = !!maximumDate?.value;
  const cantAsapAndNoAdvanceOrder = !canAsap && !hasPreorderDate;

  const _onChangeDeliverySchedule = (type) => () => {
    if (type === ASAP.label) {
      onDeliveryScheduleChange(ASAP);
    } else {
      toggleScheduleModal();
    }
  };

  return (
    <Layout>
      <View style={ThemeStyle.pageVerticalSpacingMedium}>
        <View
          style={[
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
            ThemeStyle.pageVerticalSpacingSmall,
          ]}
        >
          <Text {...SECTION_TITLE_LIGHT}>
            {isPickup ? 'Prep time estimate' : 'Delivery Schedule'}
          </Text>

          {isPickup && (
            <Text category="p2">
              {prepTime} {pluralize(prepTime, 'minute')}
            </Text>
          )}
        </View>

        <View
          dataSet={{ media: ids.deliveryScheduleContainer }}
          style={styles.deliveryScheduleContainer}
        >
          {(canAsap || cantAsapAndNoAdvanceOrder) && (
            <TouchableOpacity
              disabled={disabled}
              onPress={_onChangeDeliverySchedule(ASAP.label)}
              style={
                isStandard
                  ? styles.deliveryScheduleSelectedCardContainer
                  : styles.deliveryScheduleUnselectedCardContainer
              }
            >
              <View>
                <View style={styles.deliveryCardRowHeader}>
                  <Text category="p2">NOW (ASAP ORDER)</Text>
                  {isStandard && (
                    <Icon
                      name={'check'}
                      pack="feather"
                      fill={ThemeColor.black}
                      style={styles.iconCheck}
                    />
                  )}
                </View>
              </View>

              <Text category="c1" status="primary">
                {`${dayjs().format(constants.DATE_DISPLAY_FORMAT)} (ASAP)`}
              </Text>
            </TouchableOpacity>
          )}

          {(hasPreorderDate || cantAsapAndNoAdvanceOrder) && (
            <TouchableOpacity
              disabled={disabled}
              onPress={_onChangeDeliverySchedule('Scheduled')}
              style={
                !isStandard
                  ? styles.deliveryScheduleSelectedCardContainer
                  : styles.deliveryScheduleUnselectedCardContainer
              }
            >
              <View>
                <View style={styles.deliveryCardRowHeader}>
                  <Text category="p2">SCHEDULED</Text>
                  {!isStandard && (
                    <Icon
                      name={'check'}
                      pack="feather"
                      fill={ThemeColor.black}
                      style={styles.iconCheck}
                    />
                  )}
                </View>
              </View>
              {!isStandard ? (
                <View
                  dataSet={{ media: ids.deliveryScheduleContainer }}
                  style={styles.deliveryScheduleContainer}
                >
                  <View>
                    <Text category="c1" status="primary">
                      Date
                    </Text>
                    <Text category="c1" status="primary">
                      {dayjs(deliverySchedule?.date).format('dddd, MMM DD')}
                    </Text>
                  </View>

                  <View>
                    <Text category="c1" status="primary">
                      Time
                    </Text>
                    <Text category="c1" status="primary">
                      {`${dayjs(deliverySchedule?.time, 'hh:mm').format(
                        constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
                      )} - ${dayjs(deliverySchedule?.time, 'hh:mm')
                        .add(30, 'minutes')
                        .format(constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`}
                    </Text>
                  </View>
                </View>
              ) : (
                <Text category="c1" status="primary">
                  Select Schedule for later
                </Text>
              )}
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Layout>
  );
});
const { ids, styles } = StyleSheet.create({
  deliveryScheduleUnselectedCardContainer: {
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
    minWidth: 300,
    minHeight: 105,
    padding: 20,
    borderRadius: 14,
  },
  deliveryScheduleSelectedCardContainer: {
    borderWidth: 1,
    borderColor: ThemeColor.green,
    minWidth: 300,
    minHeight: 105,
    padding: 20,
    borderRadius: 14,
  },
  deliveryScheduleContainer: {
    rowGap: 10,
    columnGap: 10,
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexDirection: 'row',
    },
  },
  deliveryCardRowHeader: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    ...ThemeStyle.spacingBottomSmall,
    height: 20,
  },
  iconCheck: { width: 20, height: 20 },
  iconCalendar: { width: 15, height: 15 },
});
DeliveryEstimate.defaultProps = {
  daysAcceptingInAdvance: 0,
};

DeliveryEstimate.propTypes = {
  prepTime: PropTypes.number.isRequired,
  daysAcceptingInAdvance: PropTypes.number,
  deliverySchedule: PropTypes.object,
  disabled: PropTypes.bool,
  orderType: PropTypes.string,
  storeHours: PropTypes.array.isRequired,
  storeOffDates: PropTypes.array.isRequired,
  onDeliveryScheduleChange: PropTypes.func.isRequired,
};

export default DeliveryEstimate;
