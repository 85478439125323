import React, { useState, useRef, useEffect, Fragment } from 'react';
import { View, ScrollView } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashFindIndex from 'lodash/findIndex';
import lodashReduce from 'lodash/reduce';
import lodashValues from 'lodash/values';
import lodashIsEmpty from 'lodash/isEmpty';
//COMPONENTS
import TabBar from './TabBar';

import ListGroupSection from './ListGroupSection';

const TabSectionList = ({
  data = {},
  search,
  onSearch,
  onPressSearch,
  scrollEnabled,
  renderSectionTitle,
  renderSectionItem,
  contentContainerStyle,
  ListHeaderComponent,
  ListEmptyComponent,
  ListFooterComponent,
}) => {
  const scroll = useRef();
  const isPressTab = useRef();
  const section = useRef({});

  ///STATES
  const [activeSlide, setActiveSlide] = useState(0);

  const prepareSections = data?.map((item, index) => ({ ...item, index }));

  const sectionArray = lodashValues(section.current);

  useEffect(() => {
    setActiveSlide(0);
  }, [data]);

  const _onScroll = ({ layoutMeasurement, contentOffset, contentSize }) => {
    if (!isPressTab.current) {
      if (layoutMeasurement.height + contentOffset.y >= contentSize.height) {
        setActiveSlide(sectionArray.length - 1);
      } else {
        const getNearestValue = lodashReduce(section.current, (a, b) => {
          return Math.abs(b.y - contentOffset.y) <
            Math.abs(a.y - contentOffset.y)
            ? b
            : a;
        });
        const result = lodashFindIndex(
          sectionArray,
          (sc) => sc.y === getNearestValue?.y
        );
        setActiveSlide(result);
      }
    }
  };

  const _renderTab = ({ title, isActive, index }) => {
    return (
      <Text
        key={index}
        category={isActive ? 'p1' : 'p2'}
        status={isActive ? 'basic' : 'primary'}
        numberOfLines={1}
      >
        {title}
      </Text>
    );
  };

  const _onPressTab = (index) => {
    isPressTab.current = true;
    setActiveSlide(index);
    scroll.current.scrollTo({
      animated: true,
      y: section.current[index]?.y - 70,
    });
    setTimeout(() => (isPressTab.current = false), [2000]);
  };

  const _onSectionLayout = (key) => (ev) => {
    const { x, y, width, height } = ev.nativeEvent.layout;
    section.current[key] = {
      x,
      y,
      width,
      height,
    };
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        ref={scroll}
        stickyHeaderIndices={[1]}
        scrollEventThrottle={16}
        onScroll={({ nativeEvent }) => _onScroll(nativeEvent)}
        contentContainerStyle={contentContainerStyle}
        scrollEnabled={scrollEnabled}
      >
        {ListHeaderComponent()}
        {!lodashIsEmpty(prepareSections) && (
          <TabBar
            sections={prepareSections}
            renderTab={_renderTab}
            currentIndex={activeSlide}
            onPress={(index) => _onPressTab(index)}
            searchTxt={search}
            onSearch={onSearch}
            onPressSearch={onPressSearch}
          />
        )}
        {!lodashIsEmpty(prepareSections)
          ? prepareSections.map((section, index) => (
              <ListGroupSection
                key={index}
                onLayout={_onSectionLayout(index)}
                title={section.title}
                sections={section}
                renderSectionHeader={renderSectionTitle}
                renderItem={renderSectionItem}
              />
            ))
          : ListEmptyComponent()}
        {ListFooterComponent()}
      </ScrollView>
    </View>
  );
};

export default TabSectionList;
