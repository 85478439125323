import React, { useEffect, useState } from 'react';
import ActionSheet from 'react-native-actions-sheet';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';
import lodashIsUndefined from 'lodash/isUndefined';
import lodashSome from 'lodash/some';
import lodashSortBy from 'lodash/sortBy';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

const priceList = [
  { value: 9.99, label: '$' },
  { value: 99.99, label: '$$' },
  { value: 999.99, label: '$$$' },
  { value: 9999.99, label: '$$$$' },
];

function PriceSelectorSheet({ sheetId }) {
  const [data, setData] = useState();
  const [prices, setPrices] = useState([priceList[0]]);

  useEffect(() => {
    if (data?.value?.value) {
      setPrices(data.value.value);
    }
  }, [data?.value]);

  const _onReset = () => modals.hide(modals.PRICE_SELECTOR);
  const _onViewResult = () => {
    const payload = !lodashIsEmpty(prices) && {
      isAll: prices.length === priceList.length,
      value: prices,
    };
    modals.hide(modals.PRICE_SELECTOR, payload);
  };
  const _toggle = (item, isSelected) => () => {
    setPrices((prev) => {
      let newPrice = JSON.parse(JSON.stringify(prev));
      if (isSelected) {
        newPrice = lodashFilter(newPrice, (c) => c.value !== item.value);
      } else {
        newPrice.push(item);
      }
      return lodashSortBy(newPrice, 'value');
    });
  };

  return (
    <ActionSheet
      id={sheetId}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        <Text category="h5">Price</Text>

        <View style={localStyles.priceButtonContainer}>
          {priceList.map((p, i) => {
            const isSelected = lodashSome(prices, p);

            return (
              <Button
                key={i}
                style={[
                  localStyles.priceButton,
                  {
                    borderColor: isSelected
                      ? ThemeColor.green
                      : ThemeColor.black,
                    backgroundColor: isSelected
                      ? ThemeColor.lightGreen
                      : ThemeColor.white,
                  },
                ]}
                onPress={_toggle(p, isSelected)}
                plain
              >
                <Text
                  style={{
                    color: isSelected ? ThemeColor.green : ThemeColor.gray,
                  }}
                >
                  {p.label}
                </Text>
              </Button>
            );
          })}
        </View>

        <Button
          status="success"
          style={ThemeStyle.spacingBottomMedium}
          onPress={_onViewResult}
        >
          View Results
        </Button>

        <Button
          status="control"
          onPress={_onReset}
          disabled={lodashIsUndefined(data?.value)}
        >
          Reset
        </Button>
      </View>
    </ActionSheet>
  );
}

const localStyles = StyleSheet.create({
  priceButtonContainer: [
    ThemeStyle.flexDirectionRowCenterSpaceBetween,
    ThemeStyle.pageVerticalSpacing,
  ],
  priceButton: {
    ...ThemeStyle.flexDirectionRowCenterCenter,
    borderWidth: 2,
    width: 60,
    height: 60,
    borderRadius: 30,
  },
});

export default PriceSelectorSheet;
