import { SheetManager } from 'react-native-actions-sheet';
import lodashIsUndefined from 'lodash/isUndefined';

function hide(name, data) {
  return SheetManager.hide(
    name,
    !lodashIsUndefined(data) ? { payload: data } : { payload: null }
  );
}

function isOpen(name) {
  return SheetManager.get(name)?.current?.isOpen() || false;
}

async function show(name, data) {
  return SheetManager.show(
    name,
    !lodashIsUndefined(data) ? { payload: data } : null
  );
}

const modals = {
  hide,
  isOpen,
  show,
  DISTANCE_SELECTOR: 'DistanceSelector',
  DROPDOWN: 'Dropdown',
  FULL_MENU_LIST: 'FullMenuList',
  MEAL_PLAN_LIST: 'MealPlanList',
  MEAL_PLAN_TYPE_SELECTOR: 'MealPlanTypeSelector',
  MULTIPLE_VARIANT: 'MultiVariant',
  ORDER_AGAIN: 'OrderAgain',
  PRICE_SELECTOR: 'PriceSelector',
  PRODUCT: 'Product',
  PROMO_DETAILS: 'PromoDetails',
  PROMPT: 'prompt',
  PROMPT_WITH_INPUT: 'PromptWithInput',
  PUBLIC_REVIEW: 'PublicReview',
  RATING_SELECTOR: 'RatingSelector',
  VIEW_REVIEW: 'ViewReview',
  WEB_CHECKOUT_SCHEDULE_PICKER: 'WebCheckoutSchedulePicker', // used for web only
};

export default modals;
