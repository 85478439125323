import React, { useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import Tooltip from 'rn-tooltip';
import lodashPick from 'lodash/pick';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import MenuItemHelper from '../../Helper/MenuItem';
import Service from '../../Service';
import TagIconList from '../../Service/TagIconList';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_SUB_TITLE } from '../../Theme/typographyProps';

import Badge from '../Badge';
import Button from '../Button';
import Image from '../Image';
import QuickCart from '../QuickCart';

import { itemLargeProps } from './itemPropCheck';
import styles, { titleProps, subTitleProps, ids } from './styles';
import { ScaleButtonAnimation } from '../Web/Intereaction';

function ItemLarge({
  containerStyle,
  isMealPlan,
  onError,
  onPress,
  ...itemInfo
}) {
  const {
    description,
    discounted_price,
    image,
    is_bulk,
    name,
    price,
    regular_price,
    stock,
    tags,
  } = itemInfo;
  const tooltipRef = useRef();
  const store_theme = useTheme();
  const hasDiscount = MenuItemHelper.hasDiscount(
    lodashPick(itemInfo, ['active_discount', 'discount_type', 'discount_value'])
  );
  const isUnavailable = MenuItemHelper.isItemUnavailable(
    lodashPick(itemInfo, ['available', 'off_dates'])
  );
  const limit = MenuItemHelper.getCategoryOrItemStock(
    lodashPick(itemInfo, [
      'item_limit_per_day',
      'item_limit_per_time_slot',
      'stock',
      'category_stock',
    ])
  );
  const disabled = isUnavailable || limit === 0;
  const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;

  let color_val = '';
  if (
    !lodashIsEmpty(store_theme['icon_color']) &&
    store_theme['icon_color'] !== ''
  ) {
    const val = store_theme['icon_color'];
    color_val = val.length > 7 ? val.substring(0, val.length - 2) : val;
  }

  const conditionalProps = constants.isWeb
    ? {
        buttonStyle: ({ hovered }) => [
          styles.itemLargeContainer,
          hovered &&
            (color_val !== ''
              ? {
                  borderRadius: 5,
                  backgroundColor: `${color_val}1A` || ThemeColor.green100,
                }
              : ThemeStyle.buttonHovered1),
          ,
          StyleSheet.flatten(containerStyle),
        ],
      }
    : {
        style: [styles.itemLargeContainer, StyleSheet.flatten(containerStyle)],
        plain: true,
      };

  const _renderTag = () => {
    if (lodashIsEmpty(tags)) {
      return null;
    }
    const tag = tags[0];
    const result = TagIconList.getTagIcon(tag);
    const isNotAvailable = isUnavailable || stock === 0;
    if (isNotAvailable) {
      return (
        <Badge
          type={Badge.TYPES.SOLID}
          category={Badge.CATEGORIES.DANGER}
          size="c2"
        >
          Unavailable
        </Badge>
      );
    }
    return (
      <Badge type={result.type} category={result.category} size="c2">
        {`${result.icon} ${result.name}`}
      </Badge>
    );
  };

  const _renderPrice = () => {
    if (isMealPlan) {
      return null;
    }
    return (
      <View style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRowCenter]}>
        {/* Normal Price */}
        <Text
          {...titleProps}
          style={[
            ThemeStyle.spacingRightSmall,
            hasDiscount && ThemeStyle.strikethrough,
          ]}
        >
          {Service.commafyNumber(
            Number(hasDiscount ? regular_price : price),
            true
          )}
        </Text>
        {/* Compare price */}
        {hasDiscount && (
          <Text
            {...titleProps}
            style={[ThemeStyle.noticeText, ThemeStyle.spacingRightSmall]}
          >
            {Service.commafyNumber(Number(discounted_price), true)}
          </Text>
        )}
      </View>
    );
  };

  return (
    <View style={ThemeStyle.flex1}>
      <ClickableComponent
        disabled={disabled}
        onPress={onPress}
        {...conditionalProps}
      >
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.flexDirectionRow,
            ThemeStyle.spacingRightSmall,
          ]}
        >
          {/* 1st Row, Image */}
          <Image
            dataSet={{ media: ids.itemLargeImage }}
            resizeMode="cover"
            source={{ uri: image }}
            style={[styles.itemLargeImage, ThemeStyle.spacingRightMedium]}
          />
          {/* 2nd Row, Name, Tag, description price etc. */}
          <View style={ThemeStyle.flex1}>
            <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
              {/* Name */}
              <Text
                category={titleProps.category}
                numberOfLines={1}
                style={[ThemeStyle.flex1, titleProps.style]}
              >
                {name}
              </Text>
              {/* Tag */}
              <View style={ThemeStyle.spacingLeftSmall}>{_renderTag()}</View>
            </View>
            {/* Description */}
            {Boolean(description) && (
              <View style={ThemeStyle.spacingRightMedium}>
                <Text
                  {...subTitleProps}
                  style={[
                    subTitleProps.style,
                    ThemeStyle.spacingRightSmall,
                    ThemeStyle.spacingTopSmall,
                  ]}
                  numberOfLines={3}
                >
                  {description}
                </Text>
              </View>
            )}
            <View style={ThemeStyle.spacingTopSmall}>
              <View
                style={[
                  ThemeStyle.flexDirectionRowCenter,
                  ThemeStyle.spacingTopSmall,
                ]}
              >
                {/* Price */}
                {_renderPrice()}
                {/* Bulk icon */}
                {is_bulk && (
                  <Tooltip
                    ref={tooltipRef}
                    width={200}
                    height={50}
                    withOverlay={false}
                    containerStyle={styles.itemLargeTooltip}
                    pointerColor={ThemeColor.green}
                    popover={
                      <Text appearance="alternative" {...SECTION_SUB_TITLE}>
                        This item is bulky and can only be delivered by cars
                      </Text>
                    }
                  >
                    <Button
                      onPress={() => tooltipRef.current.toggleTooltip()}
                      hitSlop={10}
                      plain
                    >
                      <Icon
                        name={TagIconList.fontAwesomeIcon.bulk}
                        pack="fa5"
                        fill={ThemeColor.orange}
                        style={styles.itemLargeTooltipIcon}
                      />
                    </Button>
                  </Tooltip>
                )}
              </View>
              {/* Limit */}
              {Boolean(limit) && (
                <Text
                  {...subTitleProps}
                  style={[
                    subTitleProps.style,
                    ThemeStyle.noticeText,
                    ThemeStyle.spacingTopSmall,
                  ]}
                >
                  {limit} items left!
                </Text>
              )}
            </View>
          </View>
        </View>
      </ClickableComponent>
      {/* Add / Update cart button */}
      {!disabled && (
        <QuickCart
          item={itemInfo}
          store_theme={store_theme}
          position={{ bottom: 10, right: 15 }}
          onError={onError}
        />
      )}
    </View>
  );
}

ItemLarge.propTypes = itemLargeProps;

export default ItemLarge;
