import React, { Fragment, useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Text } from '@ui-kitten/components';
import { useQuery } from '@tanstack/react-query';
import Collapsible from 'react-native-collapsible';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import TextInput from '../../Components/TextInputDebounce';
import constants from '../../Config/constants';
import messages from '../../Config/messages';
import CartHelper from '../../Helper/Cart';
import Sentry from '../../Helper/Sentry';
import { promoTypes } from '../../Helper/Promo';
import { selectCart } from '../../RTK/cart/selectors';
import { setIsPromoOk, updateCheckoutDetails } from '../../RTK/checkout';
import {
  checkoutOrderTypeSelector,
  checkoutPromoSelector,
  checkoutStoreInfoSelector,
  checkoutStorePromoSelector,
  storeCheckoutSelector,
} from '../../RTK/checkout/selectors';
import { checkout } from '../../RTK/defaultValues';
import { setCheckoutData } from '../../RTK/mealPlan';
import {
  mealPlanCheckoutPromoSelector,
  mealPlanItemsSelector,
  mealPlanStorePromoSelector,
} from '../../RTK/mealPlan/selectors';
import { shopSelector } from '../../RTK/shop/selectors';
import Service from '../../Service';
import checkoutApi from '../../Service/api/checkout';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';

const cartPromoKey = checkout.keys.PROMO_STORE;
const checkoutPromoKey = checkout.keys.PROMO_CODE;
function PromoCode({
  isQuotationNotReady, // quotation is error or still loading
  label = 'Promo Code',
}) {
  // #region common hooks
  const dispatch = useDispatch();
  const storeId = useSelector(
    (state) => checkoutStoreInfoSelector(state).store_id
  );
  const storeMealPlanDiscount = useSelector(
    (state) => shopSelector(state)?.meal_plan_discount
  );
  const orderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, storeId)
  );
  // #endregion
  // #region normal checkout redux selector
  const normalCheckoutItems = useSelector((state) =>
    selectCart(state, storeId)
  );
  const normalCheckoutAddedPromo = useSelector((state) =>
    checkoutStorePromoSelector(state, storeId)
  );
  const normalCheckoutPaymentOption = useSelector(
    (state) =>
      storeCheckoutSelector(state, storeId)?.[checkout.keys.PAYMENT_OPTIONS]
        ?.value
  );
  const normalCheckoutAppliedPromo = useSelector((state) =>
    checkoutPromoSelector(state, storeId)
  );
  // #endregion
  // #region meal plan checkout redux selector
  const mealPlanCheckoutItems = useSelector(mealPlanItemsSelector);
  const mealPlanCheckoutAddedPromo = useSelector(mealPlanStorePromoSelector);
  const mealPlanCheckoutPaymentOption = useSelector(
    (state) =>
      state.mealPlan.data.checkoutData?.[checkout.keys.PAYMENT_OPTIONS]?.value
  );
  const mealPlanCheckoutAppliedPromo = useSelector(
    mealPlanCheckoutPromoSelector
  );
  // #endregion
  // #region common states
  const [isCollapsed, setIsCollapsed] = useState(false);
  // #endregion
  // #region common variable
  const isMealPlan = orderType === constants.ORDER_TYPES.MEAL_PLAN;
  const cartItems = isMealPlan
    ? mealPlanCheckoutItems
    : normalCheckoutItems?.[0]?.items;
  const cartPromo = isMealPlan
    ? mealPlanCheckoutAddedPromo
    : normalCheckoutAddedPromo;
  const checkoutPaymentOption = isMealPlan
    ? mealPlanCheckoutPaymentOption
    : normalCheckoutPaymentOption;
  const checkoutPromo = isMealPlan
    ? mealPlanCheckoutAppliedPromo
    : normalCheckoutAppliedPromo;
  const isDelivery = orderType === constants.ORDER_TYPES.DELIVERY;
  const { subTotal } = CartHelper.getBreakdown({
    cart: cartItems,
    subTotalPromoPercent: isMealPlan ? storeMealPlanDiscount : 0,
  });
  const code = cartPromo?.code || checkoutPromo?.code;
  // #endregion
  // #region variable that usedin /from useQuery
  const isCOD =
    checkoutPaymentOption === constants.DELIVERY_PAYMENT_OPTION.CASH;
  const isPromoMinNotMet = subTotal < cartPromo?.minimum_purchase; // if promo minimum purchase is not yet met
  const isFreeDeliveryNA = // promo is not available if:
    isDelivery && // delivery
    cartPromo?.type === promoTypes.FREE_DELIVERY && // promo type is free delivery AND
    isCOD; // payment method is cod
  const payload = {
    promo_code: code?.toUpperCase?.(),
    store_id: storeId,
    order_type: isMealPlan ? constants.ORDER_TYPES.DELIVERY : orderType,
    is_cod: isCOD,
    items: isMealPlan
      ? cartItems?.map?.((item) => ({
          id: item.id,
          quantity: item.quantity,
          extras: item.extras?.map((ie) => ie.id) || [],
        }))
      : undefined,
  };
  const queryKey = [
    payload.promo_code,
    payload.store_id,
    payload.order_type,
    payload.is_cod,
    // transform the extras id to comma separated string "id1,id2" to detect changes of item extra in order for verify promo to refetch automatically
    payload.items?.map?.((item) => item.extras.join(','))?.join?.(','), // this line return undefined on normal checkout
  ];
  const isQueryEnabled =
    Boolean(code) && !isPromoMinNotMet && !isFreeDeliveryNA;
  const { data, isFetching: isLoading } = useQuery({
    queryKey: queryKey,
    queryFn: () => checkoutApi.verifyPromoCode(payload),
    enabled: isQueryEnabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const result = isLoading ? {} : data?.data;
  const isSuccess = data?.ok;
  const isError =
    !isLoading && // not loading
    Boolean(code) && // has promo code
    (data?.ok === false || isPromoMinNotMet || isFreeDeliveryNA);
  const isTobeApply = // show to be apply if:
    isQuotationNotReady && // quotation is not yet success
    isSuccess && // fetching promo is success
    isDelivery && // delivery order type
    checkoutPromo?.type === promoTypes.FREE_DELIVERY; // promo type is free delivery

  // #endregion
  const Container = constants.isWeb ? Collapsible : Fragment;
  const containerProps = constants.isWeb
    ? { collapsed: !isCollapsed, style: { paddingTop: 10 } }
    : null;

  // effect for cleanup & initial value of states
  useEffect(() => {
    if (
      cartPromo?.code && // if has cart promo
      cartPromo?.code !== checkoutPromo?.code // and its not match with checkout promo
    ) {
      _updatePromoValue(checkoutPromoKey); // remove checkout promo
    }
    if (isError) {
      setIsCollapsed(true);
    }
  }, []);

  // effect of settled request of verify promo
  useEffect(() => {
    if (isQueryEnabled && !isLoading && !lodashIsEmpty(result)) {
      if (data.ok) {
        const saveToRedux = { code, ...result };
        if (isMealPlan) {
          // overwrite, value when used on meal plan
          saveToRedux.promo.original =
            result.promo.original - result.promo.meal_plan;
          saveToRedux.promo.applied =
            result.promo.applied - result.promo.meal_plan;
        }
        _updatePromoValue(checkoutPromoKey, saveToRedux);
      } else {
        _updatePromoValue(checkoutPromoKey);
      }
    }
  }, [isLoading, isQueryEnabled]);

  // effect for changes of error
  useEffect(() => {
    dispatch(setIsPromoOk(!isError && !isLoading)); // promo is ok if not loading && no error
  }, [isError, isLoading]);

  const _getAccessoryRight = (props) => {
    if (isLoading) {
      return <ActivityIndicator {...props} color={ThemeColor.green} />;
    } else if (isPromoMinNotMet) {
      return (
        <Button onPress={_removePromo} plain>
          <Icon name="close-circle-outline" fill={ThemeColor.red} {...props} />
        </Button>
      );
    } else if (isError) {
      return (
        <Icon name="alert-circle-outline" fill={ThemeColor.red} {...props} />
      );
    } else if (isSuccess || isTobeApply) {
      return <Icon name="checkmark" fill={ThemeColor.green} {...props} />;
    }
    return null;
  };

  const _getNoteText = () => {
    if (isError) {
      return (
        <Text
          style={[ThemeStyle.noticeText, ThemeStyle.spacingTopSmall]}
          {...SECTION_SUB_TITLE}
        >
          {_getTransformedError()}
        </Text>
      );
    } else if (isTobeApply) {
      return (
        <Text
          style={[{ color: ThemeColor.violet }, ThemeStyle.spacingTopSmall]}
          {...SECTION_SUB_TITLE}
        >
          Promo to be applied after delivery quotation.
        </Text>
      );
    } else if (isSuccess) {
      return (
        <Text
          status="success"
          style={ThemeStyle.spacingTopSmall}
          {...SECTION_SUB_TITLE}
        >
          Promo code applied!
        </Text>
      );
    }
    return null;
  };

  const _getTransformedError = () => {
    if (isPromoMinNotMet) {
      return `Add ${Service.commafyNumber(
        cartPromo.minimum_purchase - subTotal,
        true
      )} more to use this voucher.`;
    } else if (isFreeDeliveryNA) {
      return 'Promo not applicable for cash on delivery orders';
    }
    const firstError = result?.message?.[0];
    const isSingleError = typeof firstError === 'string';
    const error = result.message || messages.COMMON_ERROR_MESSAGE;
    const errMsg = isSingleError ? firstError : 'Promo code is invalid.';
    Sentry.reportError('Error checking promo code', result);
    if (isSingleError) {
      return errMsg;
    } else {
      try {
        return `${errMsg} ${Service.handleErrorMessage(error)}`;
      } catch (e) {
        return `${errMsg} ${Service.handleFormError(error, true)}`;
      }
    }
  };

  const _onChange = (val) => {
    if (cartPromo?.code || checkoutPromo?.code) {
      // if has applied or added promo, when user type clear it
      _removePromo();
    }
    _updatePromoValue(checkoutPromoKey, val);
  };

  const _removePromo = () => {
    const keys = [cartPromoKey, checkoutPromoKey];
    keys.forEach((key) => _updatePromoValue(key));
    dispatch(setIsPromoOk(true));
  };

  const _updatePromoValue = (key, value) => {
    if (isMealPlan) {
      dispatch(
        setCheckoutData({
          key,
          data: value,
        })
      );
    } else {
      dispatch(
        updateCheckoutDetails({
          store_id: storeId,
          keyToUpdate: key,
          keyValue: value,
        })
      );
    }
  };

  return (
    <Fragment>
      {constants.isWeb && (
        <Button onPress={() => setIsCollapsed((e) => !e)} plain>
          <View style={ThemeStyle.flexDirectionRowCenter}>
            <Icon
              name="pricetags-outline"
              fill={ThemeColor.green}
              pack="ion"
              style={[styles.accordionIcon, ThemeStyle.spacingRight]}
            />
            <Text category="p2" style={ThemeStyle.flex1}>
              Promo Code {checkoutPromo?.code}
            </Text>
            <Icon
              name={isCollapsed ? 'angle-down' : 'angle-right'}
              fill={ThemeColor.green}
              pack="fa"
              style={[styles.accordionIcon, ThemeStyle.spacingLeft]}
            />
          </View>
        </Button>
      )}
      <Container {...containerProps}>
        {!constants.isWeb && (
          <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE_LIGHT}>
            {label}
          </Text>
        )}
        <TextInput
          direction="column"
          placeholder="Please enter a promo code"
          accessoryRight={_getAccessoryRight}
          textStyle={{ marginHorizontal: 0 }}
          value={code}
          onChange={_onChange}
          withBackground
          noBorder
        />
        {_getNoteText()}
      </Container>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  accordionIcon: {
    width: 18,
    height: 18,
  },
});

export default PromoCode;
