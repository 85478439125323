import React, { useState, Fragment, useEffect, useRef } from 'react';
import { View, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty as lodashIsEmpty, find as lodashFind } from 'lodash';
import { Icon, Text, useTheme } from '@ui-kitten/components';

import constants from '../../../Config/constants';

import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkout } from '../../../RTK/defaultValues';

import ScreenSize from '../../../Helper/ScreenSize';

import withFavourite from '../../../Hooks/withFavourite';
import useMealPlan from '../../../Hooks/useMealPlan';

import ThemeStyle from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';

import Button from '../../../Components/Button';
import { RectangleLoader } from '../../../Components/Loader';

import useStoreDescriptionController from './controller/StoreDescription.controller';
import useDetailsController from '../controller/details';

import routeList from '../../../Routes/list';

import { capitalize, formatDistance } from '../../../Helper';

import { checkoutOrderTypeSelector } from '../../../RTK/checkout/selectors';
import { shopSelector } from '../../../RTK/shop/selectors';
import OrderTypeNote from '../OrderTypeNote';

import RatingText from '../../../Components/RatingText';

import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

import { RAZZLE_BUILD_MODE } from '@env';

const StoreDescription = ({ store_id, distance, seeReviews, ...props }) => {
  const { isSavedToFavourite, toggleFavourite } = props;
  const store_theme = useTheme();

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const orderTypeHasBeenSet = useRef();

  const shopData = useSelector(shopSelector);

  const { ratings } = useStoreDescriptionController(store_id);

  const { getStoreStatus } = useDetailsController({
    additionalCondition: true,
    isModal: false,
  });

  const { showModalPrompt } = useModalPrompt();
  const { itemRequired } = useMealPlan();

  const { xs, xl, isSmallScreen, isMedium } = ScreenSize();

  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, shopData?.id, true)
  );

  let paramOrderType = '';

  // Check if running in web
  if (constants.isWeb) {
    if (RAZZLE_BUILD_MODE === 'branded') {
      const params = new URLSearchParams(document?.location?.search); // check url params for order_type
      paramOrderType = params.get('order_type') || paramOrderType; // default order type as string
    }
  }

  const searchTagsFilter = useSelector((state) => state.filter.search.tags);
  const homeTagsFilter = useSelector((state) => state.filter.home.tags);
  const homeOrderTypeFilter = lodashFind(homeTagsFilter, {
    paramName: 'order_type',
  });

  useEffect(() => {
    if (!lodashIsEmpty(shopData) && !orderTypeHasBeenSet.current) {
      // check for previous screen only for search
      // because aside from search, other screen to access store page must use the homepage tag filter
      const routes = navigation.getState().routes; // get routes
      const prevRoutes = routes[routes.length - 2]; // get previous routes
      const isPreviousRouteSearch =
        prevRoutes?.name === routeList.SEARCH ? true : false; // is previous page is search ?
      const homeOrderTypeFilter = lodashFind(homeTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on homepage
      const searchOrderTypeFilter = lodashFind(searchTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on search page
      const tagFilter = isPreviousRouteSearch
        ? searchOrderTypeFilter
        : homeOrderTypeFilter; // if previous route is search: use the search tag filter, else home tag filter
      if (!lodashIsEmpty(tagFilter)) {
        _changeOrderTypeToSelectedTagFilter(tagFilter);
      }
      orderTypeHasBeenSet.current = true; // for flag, for this not to be called every change of menu (meal plan to non meal plan vice versa)
    }
    if (!selectedOrderType?.value) {
      _changeOrderTypeToSelectedTagFilter(homeOrderTypeFilter);
    }
  }, [shopData]);

  const _changeOrderTypeToSelectedTagFilter = (orderTypeFilter) => {
    const orderType = orderTypeFilter?.values[0].value || paramOrderType;
    const type = lodashFind(constants.ORDER_TYPE_DATA, { value: orderType });
    if (type) {
      dispatch(
        updateCheckoutDetails({
          store_id: store_id,
          keyToUpdate: checkout.keys.ORDER_TYPE,
          keyValue: type,
        })
      );
    }
  };

  const moreInfoPress = () =>
    showModalPrompt(MODALPROMPT.storeInformation, { shopName: shopData?.name });

  const StoreIndicator = () => {
    const { status, statusText, statusDescription } = getStoreStatus();

    return (
      <View style={styles.largeStoreDescriptionRow}>
        <View
          style={
            isSmallScreen
              ? styles.smallStoreDescriptionRowColumn1
              : styles.largeStoreDescriptionRowColumn1
          }
        >
          <View style={styles.storeDescriptionRowFlexBase}>
            <View style={{ marginRight: 5 }}>
              <Icon
                name={'clock-time-four-outline'}
                fill={store_theme['icon_color'] || ThemeColor.green}
                pack="material"
                style={styles.time_icon}
              />
            </View>

            <View style={{ justifyContent: 'center' }}>
              <Text category="p2" status={status}>
                {statusText}
              </Text>
            </View>

            <View>
              <Icon
                name={'dot-single'}
                fill={ThemeColor.darkGray}
                pack="entypo"
                style={styles.control_icon}
              />
            </View>

            <View style={{ justifyContent: 'center' }}>
              <Text category="p2" status="primary">
                {statusDescription}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const _onButtonPressed = () =>
    Linking.openURL(`https://www.google.com/maps/place/${shopData.location}`);

  return (
    <Fragment>
      {!lodashIsEmpty(shopData) ? (
        <View style={styles.wrapper}>
          {/*
          store name section
          */}
          <View
            style={!xs ? styles.storeDescription : styles.storeDescriptionSmall}
          >
            <Text category="h5">{shopData?.name}</Text>
            {!!shopData.store_tags && (
              <Text category="p2" status="primary">
                {capitalize(shopData.store_tags.join(' · '))}
              </Text>
            )}
            <View
              style={
                isMedium
                  ? styles.smallStoreDescriptionRow
                  : styles.largeStoreDescriptionRow
              }
            >
              <View style={ThemeStyle.spacingBottomSmall}>
                <View style={styles.largeStoreDescriptionRowColumn1}>
                  <View style={[styles.storeDescriptionRowFlexBase]}>
                    {RAZZLE_BUILD_MODE !== 'branded' && (
                      <Fragment>
                        <View style={ThemeStyle.justifyContentCenter}>
                          <RatingText
                            storeId={store_id}
                            type={2}
                            category="p2"
                            status="primary"
                          />
                        </View>
                        <View>
                          <Icon
                            name={'dot-single'}
                            fill={ThemeColor.darkGray}
                            pack="entypo"
                            style={styles.control_icon}
                          />
                        </View>
                      </Fragment>
                    )}
                    {typeof distance === 'number' && (
                      <View style={ThemeStyle.justifyContentCenter}>
                        <Text category="p2" status="primary">
                          {`${formatDistance(distance)} away`}
                        </Text>
                      </View>
                    )}

                    {RAZZLE_BUILD_MODE !== 'branded' && (
                      <>
                        {typeof distance === 'number' && (
                          <View>
                            <Icon
                              name={'dot-single'}
                              fill={ThemeColor.darkGray}
                              pack="entypo"
                              style={styles.control_icon}
                            />
                          </View>
                        )}
                        <View style={styles.labelContainer}>
                          <TouchableOpacity onPress={seeReviews}>
                            <Text category="p2" status="success">
                              See reviews
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </>
                    )}
                  </View>
                </View>
                <StoreIndicator />
              </View>
              <View style={{ ...ThemeStyle.flexDirectionRowSpaceBetween }}>
                {RAZZLE_BUILD_MODE !== 'branded' && (
                  <Button
                    testID="save_btn"
                    style={styles.more_info_button}
                    onPress={toggleFavourite({ id: store_id })}
                    plain
                  >
                    <Icon
                      name={isSavedToFavourite(store_id) ? 'heart' : 'heart-o'}
                      fill={ThemeColor.green}
                      pack="fa"
                      style={styles.control_icon}
                    />
                    <View style={{ marginLeft: 10, alignItems: 'center' }}>
                      <Text category="c1">
                        {isSavedToFavourite(store_id)
                          ? 'Remove from My Favorites'
                          : 'Add to My Favorites'}
                      </Text>
                    </View>
                  </Button>
                )}

                <View style={{ width: 10 }} />

                <Button
                  testID="more_info_btn"
                  onPress={moreInfoPress}
                  style={styles.more_info_button}
                  plain
                >
                  <Icon
                    name={'storefront-outline'}
                    fill={store_theme['icon_color'] || ThemeColor.green}
                    pack="material"
                    style={styles.control_icon}
                  />
                  {xl ? (
                    <View style={{ marginLeft: 5, alignItems: 'center' }}>
                      <Text category="c1">Store Info</Text>
                    </View>
                  ) : null}
                </Button>
              </View>
            </View>

            <OrderTypeNote
              mealPlanRequiredItem={itemRequired}
              storeAddress={shopData.location}
              type={selectedOrderType?.value || paramOrderType}
              goToLocation={_onButtonPressed}
            />
          </View>
        </View>
      ) : (
        <View
          style={
            !xs
              ? styles.loadingSkeletonContainer
              : styles.loadingSkeletonContainerSmall
          }
        >
          <View style={ThemeStyle.spacingTopMedium}>
            <RectangleLoader width={250} height={30} />
          </View>
          <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={150} height={20} />
            </View>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={100} height={20} />
            </View>
          </View>

          <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
            <View>
              <View style={ThemeStyle.spacingTopMedium}>
                <RectangleLoader width={150} height={20} />
              </View>
              <View style={ThemeStyle.spacingTopMedium}>
                <RectangleLoader width={150} height={20} />
              </View>
            </View>
            <View style={styles.loading_column_2_container}>
              <View style={ThemeStyle.spacingTopMedium}>
                <RectangleLoader width={130} height={25} />
              </View>
              <View style={ThemeStyle.spacingTopMedium}>
                <RectangleLoader width={130} height={25} />
              </View>
            </View>
          </View>
        </View>
      )}
    </Fragment>
  );
};

const styles = StyleSheet.create({
  contentContainer: [
    ThemeStyle.spacingTopMedium,
    ThemeStyle.spacingBottomMedium,
  ],
  selectionContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacing,
    backgroundColor: ThemeColor.gray,
    borderRadius: 10,
  },
  active: {
    backgroundColor: ThemeColor.green,
  },
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: 20,
    height: 20,
  },

  wrapper: {
    flex: 1,
  },
  storeDescription: { paddingLeft: 20, marginHorizontal: 50 },
  storeDescriptionSmall: {
    paddingHorizontal: 20,
  },
  storeTagsTxt: {
    color: ThemeColor.darkGray,
  },
  largeStoreDescriptionRow: {
    marginTop: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  smallStoreDescriptionRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    flexBasis: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  largeStoreDescriptionRowColumn1: {
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'row',
  },
  smallStoreDescriptionRowColumn1: {
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  storeDescriptionRowFlexBase: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexShrink: 1,
    flexBasis: 'auto',
  },
  storeDescriptionRowColumn2: {
    width: '30%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusText: {
    ...ThemeStyle.regular,
    ...ThemeStyle.alignSelfStart,
  },
  statusDescriptionTxt: {
    ...ThemeStyle.regular,
    ...ThemeStyle.alignSelfStart,
    color: ThemeColor.darkGray,
  },
  more_info_button: {
    display: 'flex',
    height: 40,
    borderRadius: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: ThemeColor.lightGray,
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
    paddingLeft: 10,
    paddingRight: 10,
  },
  time_icon: { width: 20, height: 20 },
  control_icon: { width: 20, height: 20 },
  box: {
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 400,
    padding: 5,
  },

  loadingSkeletonContainer: {
    ...ThemeStyle.spacingTopLarge,
    paddingLeft: 20,
    marginHorizontal: 50,
  },
  loadingSkeletonContainerSmall: {
    ...ThemeStyle.spacingTopLarge,
  },
  loading_column_2_container: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    width: '30%',
  },

  storInfoModalMobileWrapper: {
    width: '90%',
    top: null,
  },
  storInfoModalWrapper: {},
  portrait: {
    marginTop: 30,
  },
  landscape: {
    marginTop: 30,
    height: 250,
  },
  labelGreen: [
    ThemeStyle.regular,
    ThemeStyle.alignSelfStart,
    { color: ThemeColor.green },
  ],
  labelContainer: {
    marginLeft: 5,
    justifyContent: 'center',
  },
  mealPlanTypeHeader: { height: 30, alignItems: 'center' },
});

export default withFavourite(StoreDescription);
