import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import Image from '../../Components/Image';
import ListItem from '../../Components/ListItem';

import ThemeStyle from '../../Theme/styles';

class ResultSuggestionItem extends React.PureComponent {
  _renderAccessoryLeft = (uri) => {
    return (
      <View style={ThemeStyle.spacingRight}>
        <Image source={{ uri: uri }} style={{ width: 50, height: 50 }} />
      </View>
    );
  };

  render() {
    const { index, name, tags, avatar, onPress } = this.props;
    const { searchFor, searchValue } = this.props;

    if (searchFor) {
      return (
        <View style={ThemeStyle.pageHorizontalSpacing}>
          <ListItem
            testID={`searchForButton${index}`}
            leftIcon="search"
            title={`Search for "${searchValue}"...`}
            rightIcon="arrow-ios-forward-outline"
            onPress={onPress}
          />
        </View>
      );
    }

    return (
      <View style={ThemeStyle.pageHorizontalSpacing}>
        <ListItem
          testID={`searchSuggestionButton${index}`}
          accessoryLeft={this._renderAccessoryLeft(avatar)}
          title={name}
          subTitle={tags?.join(', ')}
          rightIcon="arrow-ios-forward-outline"
          onPress={onPress}
        />
      </View>
    );
  }
}

ResultSuggestionItem.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export default ResultSuggestionItem;
