import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import lodashIsEmpty from 'lodash/isEmpty';

import StoreCard from '../../Components/Card/Store';
import ItemSmall from '../../Components/Card/ItemSmall';
import HorizontalListing, {
  getMarginStyle,
} from '../../Components/HorizontalListing';
import ThemeStyle from '../../Theme/styles';

const Result = ({ searched_items, onItemPress, ...rest }) => {
  const hasItemResult = !lodashIsEmpty(searched_items);

  const _renderItem = ({ isFirstOfList, isLastOfList, item }) => (
    <View style={getMarginStyle(isFirstOfList, isLastOfList, false)}>
      <ItemSmall {...item} onPress={onItemPress} />
    </View>
  );

  return (
    <Fragment>
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.spacingTop,
          ThemeStyle.spacingBottomMedium,
        ]}
      >
        <StoreCard {...rest} />
      </View>
      {hasItemResult && (
        <View style={ThemeStyle.spacingBottomMedium}>
          <HorizontalListing data={searched_items} renderItem={_renderItem} />
        </View>
      )}
    </Fragment>
  );
};

Result.propTypes = {
  searched_items: PropTypes.array,
  onItemPress: PropTypes.func.isRequired,
};

export default Result;
