import React, { Fragment, useEffect, useState } from 'react';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { Card, Layout, Select, SelectItem, Text } from '@ui-kitten/components';

import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsUndefined from 'lodash/isUndefined';

import constants from '../../../../Config/constants';

import storeApi from '../../../../Service/api/store';

import StoreHelper from '../../../../Helper/Store';

//THEMES
import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';

import { checkout } from '../../../../RTK/defaultValues';
import messages from '../../../../Config/messages';
import { shopSelector } from '../../../../RTK/shop/selectors';
import { checkoutSelector } from '../../../../RTK/checkout/selectors';
import { whenFilterSelector } from '../../../../RTK/filter/selectors';
import { MODALPROMPT } from './config';
import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';
import {
  AvailableOrderDates,
  CartDetails,
  StoreAvailableOrderDates,
  TimeOptions,
} from '../../../../Types';
import { useQuery } from '@tanstack/react-query';
import useMealPlan from '../../../../Hooks/useMealPlan';
//@ts-ignore: JS Code error
import { RAZZLE_BUILD_MODE } from '@env';
import { View } from 'react-native';

const { ORDER_TYPES } = constants;

const RescheduleTextIfLimit = 'Do you want to change your order schedule?';
const RescheduleTextIfClosedOrClosing = 'Reschedule';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(tz);

const timeZone = dayjs.tz.guess();

type ChangeDateTimePayload = {
  hasAsapAndPreorderBtn?: boolean;
  modalTitle?: string;
  storeHours: StoreAvailableOrderDates;
  value: {
    label?: string;
    value?: string;
    date?: string;
    time?: string;
    canAsap: boolean;
  };
  error: {
    errorType: string;
    title: string;
    message: string;
    unavailableItems: Array<CartDetails>;
  };
};

const ChangeDateTimeSchedule = () => {
  const shopData = useSelector(shopSelector);
  const checkoutData = useSelector(checkoutSelector);
  const checkoutParams = useSelector((state: any) => state.checkout.params);
  const whenFilter = useSelector(whenFilterSelector);

  const { hideModalPrompt } = useModalPrompt();
  const { isMealPlan } = useMealPlan();
  const [data, setData] = useState<ChangeDateTimePayload>();
  const [isAsap, setAsap] = useState<boolean>(false);
  const [changeSchedule, setChangeSchedule] = useState(false);
  const [selectedAdvanceDay, setSelectedAdvanceDay] =
    useState<AvailableOrderDates>();
  const [selectedAdvanceTime, setSelectedAdvanceTime] = useState<TimeOptions>();

  const { storeHours, error, value, modalTitle, hasAsapAndPreorderBtn } =
    data || {};

  const { data: storeHoursResult, isFetching: storeHoursLoading } = useQuery({
    queryKey: [shopData?.id, 'store-hours'],
    queryFn: () => storeApi.getStoreOrderDates(shopData?.id),
    enabled:
      Boolean(shopData?.id) && !isMealPlan && lodashIsUndefined(storeHours), // since web is using it on meal plan and non meal plan order, prevent fetch if has mealPlanType
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setAsap(
      lodashIsEmpty(whenFilter?.value) && lodashIsUndefined(selectedAdvanceDay)
    );
    return () => {};
  }, []);

  const scheduleParams =
    shopData !== null
      ? { ...shopData, store_id: shopData?.id }
      : checkoutParams;

  const storeCheckoutData = StoreHelper.getStoreCheckoutData(
    scheduleParams?.store_id,
    checkoutData
  );

  const hasError = !lodashIsEmpty(error?.errorType);
  const showSelector = RAZZLE_BUILD_MODE === 'branded' || hasAsapAndPreorderBtn;
  const showPreOrderOptions = !showSelector || (showSelector && !isAsap);

  const isClosingOrClosed =
    error?.errorType === messages.STORE_CLOSED_MOBILE.title ||
    error?.errorType === messages.STORE_CLOSING.title;

  const orderType = storeCheckoutData[checkout.keys.ORDER_TYPE];

  const isPickup = orderType?.value?.includes(ORDER_TYPES.PICKUP);

  const orderTypeLabel = `${isPickup ? 'Pickup' : 'Delivery'}`;

  const disableBtn = showSelector
    ? false
    : !selectedAdvanceDay?.value ||
      !selectedAdvanceTime?.value ||
      storeHoursLoading;
  const orderAdvanceDay = !lodashIsUndefined(storeHours)
    ? storeHours
    : storeHoursResult?.data?.length > 0
    ? storeHoursResult?.data
    : [
        {
          value: '',
          iso: '',
          time_options: [],
        },
      ];

  const selectedDateValue = selectedAdvanceDay?.value
    ? dayjs(selectedAdvanceDay?.value).format('dddd, MMM DD')
    : !lodashIsEmpty(value?.date)
    ? dayjs(value?.date).format('dddd, MMM DD')
    : !lodashIsEmpty(whenFilter?.date)
    ? dayjs(whenFilter?.date).format('dddd, MMM DD')
    : '';

  const selectedTimeValue = selectedAdvanceTime?.value
    ? `${dayjs
        .utc(selectedAdvanceTime?.start)
        .tz(timeZone)
        .format(constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT)} - ${dayjs
        .utc(selectedAdvanceTime?.end)
        .tz(timeZone)
        .add(30, 'minutes')
        .format(constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`
    : !lodashIsEmpty(value?.time) && lodashIsUndefined(selectedAdvanceDay)
    ? `${dayjs(value?.time, 'hh:mm').format(
        constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
      )} - ${dayjs(value?.value, 'hh:mm')
        .add(30, 'minutes')
        .format(constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`
    : !lodashIsEmpty(whenFilter?.time) && lodashIsUndefined(selectedAdvanceDay)
    ? `${dayjs(whenFilter?.time, 'hh:mm').format(
        constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
      )} - ${dayjs(whenFilter?.time, 'hh:mm')
        .add(30, 'minutes')
        .format(constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`
    : '';

  const onSelectAdvanceDate = async (index) => {
    //get selected date
    const selectData = orderAdvanceDay[index - 1];
    setSelectedAdvanceDay(selectData);
    setSelectedAdvanceTime(undefined);
  };

  const onSelectAdvanceTime = async (index) => {
    //get selected time
    const timeOptions = selectedAdvanceDay.time_options;
    const selectData = timeOptions[index];
    setSelectedAdvanceTime(selectData);
  };

  const _toggleOrderTime = () => {
    setSelectedAdvanceDay(undefined);
    setSelectedAdvanceTime(undefined);
    setAsap(!isAsap);
  };

  const _onPressProceed = async () => {
    if (
      !lodashIsEmpty(selectedAdvanceDay?.value) &&
      !lodashIsEmpty(selectedAdvanceDay?.value)
    ) {
      const updatedWhenFilter = {
        label: dayjs(
          new Date(
            `${selectedAdvanceDay?.value} ${selectedAdvanceTime?.value}`
          ).toISOString()
        ).format(constants.DATE_DISPLAY_FORMAT),
        value: new Date(
          `${selectedAdvanceDay?.value} ${selectedAdvanceTime?.value}`
        ).toISOString(),
        date: dayjs(selectedAdvanceDay?.value).format(constants.DBDATE_FORMAT),
        time: selectedAdvanceTime?.value,
      };
      setChangeSchedule(false);
      hideModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, updatedWhenFilter);
    } else if (showSelector) {
      const updatedWhenFilter = {
        label: 'ASAP',
        value: '',
        date: '',
        time: '',
      };
      setChangeSchedule(false);
      hideModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, updatedWhenFilter);
    }
  };
  const _onPressClosed = () => {
    setChangeSchedule(false);
    hideModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, {});
  };

  return (
    <ModalPrompt
      id={MODALPROMPT.changeDateAndTimeSchedule}
      title={
        !lodashIsEmpty(modalTitle)
          ? modalTitle
          : changeSchedule
          ? 'Change Schedule'
          : error?.title
      }
      dataSet={{ media: ids.mainWrapper }}
      mainContentWrapperStyle={styles.mainWrapper}
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      onCloseButtonClick={_onPressClosed}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <Layout
        style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomSmall]}
      >
        {hasError && !changeSchedule && (
          <Fragment>
            <Card
              style={[
                { backgroundColor: '#ffe2e6' },
                ThemeStyle.spacingBottomMedium,
              ]}
            >
              <Text category="p2" status="danger">
                {error?.message}
              </Text>
            </Card>
            <Button
              style={
                isClosingOrClosed
                  ? styles.rescheduleButtonAlignToCenter
                  : styles.rescheduleButton
              }
              onPress={() => {
                setChangeSchedule(true);
              }}
              status="success"
            >
              {() => (
                <Text category="c1" status="control">
                  {isClosingOrClosed
                    ? RescheduleTextIfClosedOrClosing
                    : RescheduleTextIfLimit}
                </Text>
              )}
            </Button>
          </Fragment>
        )}

        {(!hasError || changeSchedule) && (
          <Fragment>
            {showSelector && (
              <Layout style={[ThemeStyle.spacingBottom]}>
                <Layout style={styles.container}>
                  <Layout style={styles.layout} level="1">
                    <Button
                      onPress={!isAsap && _toggleOrderTime}
                      style={isAsap ? styles.enableBtn : styles.disableBtn}
                      status={isAsap ? 'success' : 'control'}
                    >
                      <View style={styles.innerBtn}>
                        <Text category="p2" status={isAsap && 'control'}>
                          ASAP
                        </Text>
                      </View>
                    </Button>
                  </Layout>

                  <Layout style={styles.layout} level="1">
                    <Button
                      onPress={isAsap && _toggleOrderTime}
                      style={!isAsap ? styles.enableBtn : styles.disableBtn}
                      status={!isAsap ? 'success' : 'control'}
                    >
                      <View style={styles.innerBtn}>
                        <Text category="p2" status={!isAsap && 'control'}>
                          Pre-Order
                        </Text>
                      </View>
                    </Button>
                  </Layout>
                </Layout>
              </Layout>
            )}
            {showPreOrderOptions && (
              <Layout style={[ThemeStyle.spacingBottomMedium]}>
                <Layout style={styles.container}>
                  <Layout style={styles.layout} level="1">
                    <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                      <Text category="label">{orderTypeLabel} Date</Text>
                    </Layout>
                    <Select
                      onSelect={(index) => onSelectAdvanceDate(index)}
                      value={selectedDateValue}
                      size="large"
                    >
                      {orderAdvanceDay.map(({ value }, index) => (
                        <SelectItem
                          key={index}
                          title={dayjs(value).format('dddd, MMM DD')}
                        />
                      ))}
                    </Select>
                  </Layout>

                  <Layout style={styles.layout} level="1">
                    <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                      <Text category="label">{orderTypeLabel} Time</Text>
                    </Layout>
                    <Select
                      onSelect={(index) => onSelectAdvanceTime(index)}
                      value={selectedTimeValue}
                      size="large"
                    >
                      {selectedAdvanceDay?.time_options.map(
                        ({ value, start, end }, index) =>
                          value !== 'asap_order_time' && (
                            <SelectItem
                              key={index}
                              title={`${dayjs
                                .utc(start)
                                .tz(timeZone)
                                .format(
                                  constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
                                )} - ${dayjs
                                .utc(end)
                                .tz(timeZone)
                                .add(30, 'minutes')
                                .format(
                                  constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
                                )}`}
                            />
                          )
                      )}
                    </Select>
                  </Layout>
                </Layout>
              </Layout>
            )}

            <Button
              disabled={disableBtn}
              style={styles.proceedBtn}
              onPress={_onPressProceed}
              plain
            >
              <Text category="label" status="basic">
                Proceed
              </Text>
            </Button>
          </Fragment>
        )}
      </Layout>
    </ModalPrompt>
  );
};
const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    height: 'auto',
    [`@media ${BreakPoints.xs}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.md}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 450,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 500,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 550,
    },
  },
  container: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
  layout: {
    width: '48%',
  },
  selectWhole: {
    flex: 1,
  },
  selectSmall: {
    flex: 1,
    margin: 2,
  },
  rescheduleButton: {
    ...ThemeStyle.spacingBottomMedium,
  },
  rescheduleButtonAlignToCenter: {
    ...ThemeStyle.spacingBottomMedium,
    ...ThemeStyle.alignSelfCenter,
  },
  enableBtn: {
    flex: 1,
    marginTop: 10,
    borderRadius: 0,
    height: 50,
  },
  disableBtn: {
    height: 50,
    borderRadius: 0,
    backgroundColor: ThemeColor.gray,
    flex: 1,
    marginTop: 10,
    opacity: 0.5,
    elevation: 0,
  },
  innerBtn: {
    height: 50,
    minWidth: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  proceedBtn: {
    height: 50,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
});
export default React.memo(ChangeDateTimeSchedule);
