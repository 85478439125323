import React, { useRef, useState } from 'react';
import ActionSheet, { useScrollHandlers } from 'react-native-actions-sheet';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';

import commonProps, { scrollProps } from './commonProps';
import modals from './modals';
import styles from './styles';

/**
 * useful data shape is
 * {
 *   list: Array<{ title: string }>,
 *   value: number
 * }
 */
// 50% of screen height - status bar height
const maxHeight = deviceHeight * 0.5 - constants.statusBarHeight;
function FullMenuListSheet({ sheetId }) {
  const sheetRef = useRef();
  const scrollHandlers = useScrollHandlers('mealPlanList', sheetRef);

  const [data, setData] = useState();

  const list = data?.list || [];
  const value = data?.value || 0;

  const _onSelect = (index) => () => modals.hide(modals.FULL_MENU_LIST, index);

  return (
    <ActionSheet
      id={sheetId}
      ref={sheetRef}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={[styles.contentContainer, ThemeStyle.spacingBottomMedium]}>
        <Text {...SECTION_TITLE}>Menu</Text>
      </View>

      <View style={{ maxHeight }}>
        <ScrollView {...scrollProps} {...scrollHandlers}>
          {list.map((item, index) => (
            <Button key={index} onPress={_onSelect(index)} plain>
              {value === index && <View style={localStyles.activeIndicator} />}
              <View
                style={[
                  ThemeStyle.pageVerticalSpacingMedium,
                  ThemeStyle.pageHorizontalSpacing,
                  ThemeStyle.flexDirectionRowCenterSpaceBetween,
                ]}
              >
                <Text>{item.title}</Text>
                {/* <Text category="s1" style={ThemeStyle.bold}>
                  {item.data.length}
                </Text> */}
              </View>
            </Button>
          ))}
        </ScrollView>
      </View>
    </ActionSheet>
  );
}

const localStyles = StyleSheet.create({
  activeIndicator: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 5,
    backgroundColor: ThemeColor.green,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
});

export default FullMenuListSheet;
