import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';

import MasterCardSvg from '../Assets/payment_mastercard.svg';
import McafeeSvg from '../Assets/payment_mcafee.svg';
import VisaSvg from '../Assets/payment_visa.svg';
import GcashSvg from '../Assets/payment_gcash.svg';
import GrabPaySvg from '../Assets/payment_grabpay.svg';
import constants from '../Config/constants';
import useFormField from '../Hooks/useFormField';
import ThemeStyle from '../Theme/styles';
import { SECTION_TITLE_LIGHT } from '../Theme/typographyProps';

import ErrorPromptBelowInput from './FormFields/ErrorPromptBelowInput';
import TextInput from './TextInputDebounce';
import PaymentMethodItem from './PaymentMethodItem';

const paymentMethods = [
  {
    label: 'Credit / Debit Card',
    value: 'card',
    images: [
      { image: McafeeSvg, width: 45, aspectRatio: 2.7 },
      { image: MasterCardSvg, width: 25, aspectRatio: 1.2 },
      { image: VisaSvg, width: 50, aspectRatio: 3.1 },
    ],
  },
  {
    label: 'Gcash',
    value: 'gcash',
    images: [{ image: GcashSvg, width: 75, aspectRatio: 4.1 }],
  },
  {
    label: 'Grabpay',
    value: 'grab_pay',
    images: [{ image: GrabPaySvg, width: 70, aspectRatio: 3.7 }],
  },
];

function PaymentMethods({ error, value, onInputChange, onMethodChange }) {
  const { getInputProps } = useFormField();

  const method = value?.paymentMethod || value?.method;

  const _onInputChange = (fieldName) => (value) =>
    onInputChange(fieldName)(value);

  const CardInput = (
    <View style={ThemeStyle.spacingTopMedium}>
      <View style={ThemeStyle.spacingBottomMedium}>
        <TextInput
          label={(props) => (
            <Text category={constants.isWeb ? 'c1' : 'p2'} style={props.style}>
              Card Number
            </Text>
          )}
          direction="column"
          type="cardnumber"
          placeholder="Card Number"
          value={value?.cardNumber || ''}
          onChange={_onInputChange('cardNumber')}
          {...getInputProps(1)}
          autoComplete="off"
          withBorder
        />
      </View>
      <View style={ThemeStyle.flexDirectionRowEndEnd}>
        {/* Exp Month */}
        <View style={ThemeStyle.flex1}>
          <TextInput
            type="number"
            label={(props) => (
              <Text
                category={constants.isWeb ? 'c1' : 'p2'}
                style={props.style}
                numberOfLines={1}
              >
                Expiration Date
              </Text>
            )}
            placeholder="MM"
            direction="column"
            maxLength={2}
            value={value?.cardMonth || ''}
            onChange={_onInputChange('cardMonth')}
            {...getInputProps(2)}
            autoComplete="off"
            withBorder
          />
        </View>
        <View style={ThemeStyle.rowSeparator} />
        {/* Exp Year */}
        <View style={ThemeStyle.flex1}>
          <TextInput
            type="number"
            placeholder="YY"
            direction="column"
            maxLength={2}
            value={value?.cardYear || ''}
            onChange={_onInputChange('cardYear')}
            autoComplete="off"
            {...getInputProps(3)}
            withBorder
          />
        </View>
        <View style={ThemeStyle.rowSeparator} />
        {/* CVV */}
        <View style={ThemeStyle.flex1}>
          <TextInput
            name="cardCvv"
            type="number"
            placeholder="CVC"
            direction="column"
            maxLength={4}
            value={value?.cardCvv || ''}
            onChange={_onInputChange('cardCvv')}
            {...getInputProps(4, true)}
            secureTextEntry
            autoComplete="off"
            withBorder
          />
        </View>
      </View>
    </View>
  );

  return (
    <View style={ThemeStyle.flex1}>
      <View style={ThemeStyle.spacingBottomMedium}>
        <Text {...(constants.isWeb ? { category: 's2' } : SECTION_TITLE_LIGHT)}>
          Payment Method
        </Text>
      </View>

      {paymentMethods.map((pm, i) => {
        const isSelected = method === pm.value;
        const isCard = i === 0;
        const isLast = i === paymentMethods.length - 1;
        return (
          <View style={!isLast && ThemeStyle.spacingBottomMedium} key={i}>
            <PaymentMethodItem
              {...pm}
              checked={isSelected}
              status={isSelected ? 'success' : 'basic'}
              onChange={() => onMethodChange(pm.value)}
            />
            {/* For card input */}
            {isCard && (
              <Collapsible
                collapsed={!isSelected}
                style={{ overflow: constants.isWeb ? 'clip' : 'hidden' }}
              >
                {CardInput}
              </Collapsible>
            )}
          </View>
        );
      })}

      <ErrorPromptBelowInput text={error} />
    </View>
  );
}

export default PaymentMethods;
