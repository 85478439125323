import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import { TYPES, CATEGORIES } from './constants';
import getBackgroundColor from './getBackgroundColor';
import getBorderColor from './getBorderColor';
import getTextColor from './getTextColor';

const typeList = Object.keys(TYPES).map((k) => TYPES[k]);
const categoryList = Object.keys(CATEGORIES).map((k) => CATEGORIES[k]);

function Badge({ category, children, size, style, type, ...rest }) {
  const backgroundColor = getBackgroundColor(category, type);
  const borderColor = getBorderColor(category);
  const color = getTextColor(category, type);
  return (
    <View
      style={[styles.container, { backgroundColor }, { borderColor }, style]}
    >
      {(() => {
        if (typeof children === 'string') {
          return (
            <Text style={{ color }} {...rest} category={size}>
              {children}
            </Text>
          );
        } else if (typeof children === 'function') {
          return children(color);
        } else {
          return children;
        }
      })()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    paddingVertical: 2,
    paddingHorizontal: 5,
    borderRadius: 5,
  },
});

Badge.defaultProps = {
  category: CATEGORIES.BASIC,
  type: TYPES.OUTLINE,
  size: 'p2',
};

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  category: PropTypes.oneOf(categoryList),
  type: PropTypes.oneOf(typeList),
};

Badge.TYPES = TYPES;
Badge.CATEGORIES = CATEGORIES;

export default Badge;
