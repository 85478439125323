import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Icon, Text, useTheme } from '@ui-kitten/components';

import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

function QuickCartQtyBadge({ size, textSize, value }) {
  const store_theme = useTheme();
  return (
    <View
      style={[
        ThemeStyle.shadow,
        ThemeStyle.alignItemsCenter,
        ThemeStyle.justifyContentCenter,
        {
          backgroundColor: store_theme['icon_color'] || ThemeColor.green,
          zIndex: 1,
          width: size,
          height: size,
          borderRadius: size / 2,
        },
      ]}
    >
      <Text
        category={textSize || 'p2'}
        appearance="alternative"
        adjustsFontSizeToFit
      >
        {value}&times;
      </Text>
    </View>
  );
}

QuickCartQtyBadge.defaultProps = {
  size: 30,
};

QuickCartQtyBadge.propTypes = {
  size: PropTypes.number,
  value: PropTypes.number,
};

export default QuickCartQtyBadge;
