import { RefObject } from 'react';
import { actionModalEventManager } from './useModalEventManager';
import { providerRegistryStack, modalRegistry } from './provider';
import { ModalRef } from '../types/types';

export interface Modals {}
let baseZindex = 999;
// Array of all the ids of Modals currently rendered in the app.
const ids: string[] = [];
const refs: { [name: string]: RefObject<any> } = {};

/**
 * Get rendered action modal stack
 * @returns
 */
export function getSheetStack() {
  return ids.map((id) => {
    return {
      id: id.split(':')[0],
      context: id.split(':')?.[1] || 'globalModal',
    };
  });
}

/**
 * A function that checks whether the modal with the given id is rendered on top or not.
 * @param id
 * @param context
 * @returns
 */
export function isRenderedOnTop(id: string, context?: string) {
  return context
    ? ids[ids.length - 1] === `${id}:${context}`
    : ids[ids.length - 1].startsWith(id);
}

/**
 * Set the base zIndex upon which modal will be stacked. Should be called once in the globalModal space.
 *
 * Default `baseZIndex` is `999`.
 *
 * @param zIndex
 */
export function setBaseZIndexForActionSheets(zIndex: number) {
  baseZindex = zIndex;
}

/**
 * Since non modal based modal are stacked one above the other, they need to have
 * different zIndex for gestures to work correctly.
 * @param id
 * @param context
 * @returns
 */
export function getZIndexFromStack(id: string, context: string) {
  const index = ids.indexOf(`${id}:${context}`);
  if (index > -1) {
    return baseZindex + index + 1;
  }
  return baseZindex;
}

const useModalManager = () => {
  const context = (options?: { context?: string; id?: string }) => {
    if (!options) options = {};
    if (!options?.context) {
      // If no context is provided, use the current top most context
      // to render the sheet.
      for (const context of providerRegistryStack.slice().reverse()) {
        // We only automatically select nested sheet providers.
        if (
          context.startsWith('$$-auto') &&
          !context.includes(options?.id as string)
        ) {
          options.context = context;
          break;
        }
      }
    }
    return options.context;
  };

  const show = async <ModalId extends keyof any>(
    id: ModalId | (any & {}),
    options?: {
      /**
       * Any data to pass to the ActionSheet. Will be available from the component `props` or in `onBeforeShow` prop on the action sheet.
       */
      payload?: any[ModalId]['payload'];

      /**
       * Receive payload from the Sheet when it closes
       */
      onClose?: (data: any[ModalId]['returnValue'] | undefined) => void;

      /**
       * Provide `context` of the `SheetProvider` where you want to show the action sheet.
       */
      context?: string;
    }
  ): Promise<any[ModalId]['returnValue']> => {
    return new Promise((resolve) => {
      let currentContext = context({
        ...options,
        id: id,
      });
      const handler = (data: any, context = 'globalModal') => {
        if (
          context !== 'globalModal' &&
          currentContext &&
          currentContext !== context
        )
          return;

        options?.onClose?.(data);
        sub?.unsubscribe();
        resolve(data);
      };
      var sub = actionModalEventManager.subscribe(`onclose_${id}`, handler);

      // Check if the sheet is registered with any `SheetProviders`.
      let isRegisteredWithSheetProvider = false;
      for (let ctx in modalRegistry) {
        for (let _id in modalRegistry[ctx]) {
          if (_id === id) {
            isRegisteredWithSheetProvider = true;
          }
        }
      }

      actionModalEventManager.publish(
        isRegisteredWithSheetProvider ? `show_wrap_${id}` : `show_${id}`,
        options?.payload,
        currentContext || 'globalModal'
      );
    });
  };

  const hide = async <ModalId extends keyof any>(
    id: ModalId | (string & {}),
    options?: {
      /**
       * Return some data to the caller on closing the Sheet.
       */
      payload?: any[ModalId]['returnValue'];
      /**
       * Provide `context` of the `SheetProvider` to hide the action sheet.
       */
      context?: string;
    }
  ): Promise<any[ModalId]['returnValue']> => {
    let currentContext = context({
      ...options,
      id: id,
    });
    return new Promise((resolve) => {
      let isRegisteredWithSheetProvider = false;
      // Check if the sheet is registered with any `SheetProviders`
      // and select the nearest context where sheet is registered.

      for (const _id of ids) {
        if (_id === `${id}:${currentContext}`) {
          isRegisteredWithSheetProvider = true;
          break;
        }
      }

      const hideHandler = (data: any, context = 'globalModal') => {
        if (
          context !== 'globalModal' &&
          currentContext &&
          currentContext !== context
        )
          return;
        sub?.unsubscribe();
        resolve(data);
      };
      var sub = actionModalEventManager.subscribe(`onclose_${id}`, hideHandler);
      actionModalEventManager.publish(
        isRegisteredWithSheetProvider ? `hide_wrap_${id}` : `hide_${id}`,
        options?.payload,
        !isRegisteredWithSheetProvider ? 'globalModal' : currentContext
      );
    });
  };

  const hideAll = <ModalId extends keyof any>(id?: ModalId | (string & {})) => {
    ids.forEach((_id: any) => {
      if (id && !_id.startsWith(id)) return;
      actionModalEventManager.publish(`hide_${_id.split(':')?.[0]}`);
    });
  };

  const registerRef = (
    id: string,
    context: string,
    instance: RefObject<any>
  ) => {
    refs[`${id}:${context}`] = instance;
  };

  const get = <ModalId extends keyof Modals>(
    id: ModalId | (string & {}),
    context?: string
  ): RefObject<ModalRef<ModalId>> => {
    if (!context) {
      for (let ctx of providerRegistryStack.slice().reverse()) {
        for (let _id in modalRegistry[ctx]) {
          if (_id === id) {
            context = ctx;
            break;
          }
        }
      }
    }
    return refs[`${id}:${context}`] as RefObject<ModalRef<ModalId>>;
  };

  const add = (id: string, context: string) => {
    if (ids.indexOf(id) < 0) {
      ids[ids.length] = `${id}:${context}`;
    }
  };

  const remove = (id: string, context: string) => {
    if (ids.indexOf(`${id}:${context}`) > -1) {
      ids.splice(ids.indexOf(`${id}:${context}`));
    }
  };

  return {
    context,
    show,
    hide,
    hideAll,
    registerRef,
    get,
    add,
    remove,
  };
};

/**
 * Modal Manager is used to imperatively show/hide any ActionSheet with a
 * unique id prop.
 */
export const ModalManager = useModalManager();
