import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import Tooltip from 'rn-tooltip';
import lodashFindIndex from 'lodash/findIndex';

import constants from '../Config/constants';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

const ErrorHint = ({
  children,
  containerStyle,
  name,
  errorText,
  scrollReference,
  height = 50,
  width = 200,
  show,
  onClose,
}: any) => {
  const tooltipRef = useRef({ current: {} }); // this will become current: { [name]: ref }
  const coordinates = useRef([]);
  const [isOpen, setOpen] = useState(false);
  const scrollRef: ScrollView = scrollReference?.current;

  useEffect(() => {
    if (show) {
      if (errorText) {
        // if has tooltip / hint on mealPlanStep
        // we will show tips only on the first occurence if hint is inside the loop
        const reference = tooltipRef?.current?.[name];
        if (reference) {
          // wait a little for transition
          setTimeout(() => {
            // if has scroll config
            if (scrollRef) {
              // then scroll to the hint
              const position = coordinates.current
                .filter(Number)
                .sort((a, b) => a - b);

              scrollRef?.scrollTo({
                y: position[0],
                animated: true,
              });
            }
            // after scrolling (if has scroll config), wait a little for scroll animation to end
            setTimeout(() => {
              // then show tooltip
              reference.toggleTooltip();
            }, 500);
          }, 100);
        }
      }
    }
  }, [show, errorText, name, scrollRef]);

  const _onLayout = (event) => {
    const layout = event.nativeEvent.layout;
    coordinates.current[0] = layout.y;
  };

  const _open = () => {
    setOpen(true);
  };
  const _onClose = () => {
    setOpen(false);
    onClose(false);
  };
  return (
    <View onLayout={_onLayout}>
      <Tooltip
        ref={(r) => (tooltipRef.current[name] = r)}
        actionType="none"
        backgroundColor={ThemeColor.green}
        overlayColor={`${ThemeColor.darkGray}96`}
        height={height}
        width={width}
        popover={
          <Text appearance="alternative" category="p2" style={ThemeStyle.flex1}>
            {errorText}
          </Text>
        }
        onOpen={_open}
        onClose={_onClose}
      >
        <View style={[containerStyle, { position: 'relative' }]}>
          {/* Overlay to prevent clicking the element inside, this should be click first to close the highlight */}
          {isOpen && <View style={[StyleSheet.absoluteFill, { zIndex: 1 }]} />}

          {children}
        </View>
      </Tooltip>
    </View>
  );
};

export default ErrorHint;
