import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

import SearchInput from '../../Components/Search';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

class SearchSection extends React.PureComponent {
  render() {
    const { onRef, onSearchChange, ...rest } = this.props;

    return (
      <View
        style={[
          ThemeStyle.pageBackground,
          constants.isWeb && { backgroundColor: 'transparent' },
        ]}
      >
        <View
          style={[
            ThemeStyle.pageHorizontalSpacing,
            ThemeStyle.spacingTopSmall,
            ThemeStyle.spacingBottomMedium,
          ]}
        >
          <SearchInput
            ref={onRef}
            placeholder="Looking for something?"
            onSearch={onSearchChange}
            {...rest}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: { borderColor: ThemeColor.gray },
});

SearchSection.defaultProps = {
  onSearchChange: () => {},
};

SearchSection.propTypes = {
  onRef: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSearchPress: PropTypes.func,
};

export default SearchSection;
