import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Platform, StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Divider, Text } from '@ui-kitten/components';
import dayjs from 'dayjs';
import lodashGet from 'lodash/get';

import { RAZZLE_BUILD_MODE } from '@env';

import Button from '../../../Components/Button';
import List from '../../../Components/List';
import MealPlanItem from '../../../Components/StoreCart/MealPlanItem';
import MinimumOrderNote from '../../../Components/StoreCart/MinimumOrderNote';
import StoreCartSummary from '../../../Components/StoreCart/CartSummary';
import { pluralize } from '../../../Helper';
import CartHelper from '../../../Helper/Cart';
import MealPlanHelper from '../../../Helper/MealPlan';
import { getCartTerm } from '../../../Helper/RemoteConfig';
import usePromoCart from '../../../Hooks/usePromoCart';
import useSetCheckoutStoreInfo from '../../../Hooks/useSetCheckoutStoreInfo';
import { removeItem } from '../../../RTK/mealPlan';
import routeList from '../../../Routes/list';
import ThemeColor from '../../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../../Theme/styles';

import checkoutApi from '../../../Service/api/checkout';

import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

const MealPlanList = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { showModalPrompt } = useModalPrompt();

  const mealPlanData = useSelector((state) => state.mealPlan?.data);
  const shopData = useSelector((state) => state.shop.data);
  let paramMealPlanType = '';

  if (RAZZLE_BUILD_MODE === 'branded') {
    const params = new URLSearchParams(document?.location?.search); // check url params for order_type
    paramMealPlanType = params.get('meal_plan_type') || paramMealPlanType; // default order type as string
  }

  // Branded loader state
  const [isPaymentLinkLoading, setPaymentLinkLoaderStatus] = useState(false);

  const orderTimeInitial = dayjs(`1/1/1 ${shopData?.meal_plan_order_time}`);
  const mealPlanDispatchTime = `${orderTimeInitial.format(
    'hh:mm A'
  )} to ${orderTimeInitial.add(30, 'minute').format('hh:mm A')}`;
  const list = mealPlanData?.items || [];
  const dayCount =
    (mealPlanData?.type || paramMealPlanType)?.substring?.(0, 1) || 0;
  const dispatchTime = mealPlanDispatchTime || '';
  const schedule = MealPlanHelper.generateSchedule(`${dayCount}_day`);

  const { promotion, promotionMinimum, storePromo, onPromoRemove } =
    usePromoCart({
      fetchPromo: list.length >= dayCount && dayCount != 0,
    });
  const setCheckoutStoreInfo = useSetCheckoutStoreInfo();

  const { subTotal } = CartHelper.getBreakdown({
    isDelivery: true,
    promotion,
    cart: list,
  });
  const isLessThanMinimum = subTotal < promotionMinimum;

  const _onCheckout = async () => {
    if (list.length > 0 && list.length == dayCount) {
      const storeData = shopData;
      if (RAZZLE_BUILD_MODE === 'branded') {
        setPaymentLinkLoaderStatus(true);

        const { ok, data } = await checkoutApi.placeOrderMealPlan({
          items: list.map(({ quantity, id, extras = [] }) => ({
            quantity,
            id,
            extras: extras.map(({ id }) => id),
          })),
          store_id: storeData.id,
          promo_code: lodashGet(promotion, 'promo_code', ''),
        });

        if (ok) {
          window?.localStorage?.setItem('BRANDED_LVS', window?.location?.href);
          navigation.push('checkout', {
            storeId: storeInfo.id,
            url: data?.redirect,
          });
          setPaymentLinkLoaderStatus(false);
        } else {
          setPaymentLinkLoaderStatus(false);
        }
      } else {
        setCheckoutStoreInfo(shopData);
        navigation.push(routeList.CHECKOUT_NAVIGATOR);
      }
    }
  };
  const _onRemove = (index) => () => dispatch(removeItem(index));

  const _onRemoveAll = () => {
    dispatch(removeItem());
  };
  const _toggleRemoveModal = () =>
    showModalPrompt(MODALPROMPT.prompt, {
      title: 'Are you sure?',
      message: `Do you really want to remove ${
        shopData?.name
      } from your ${getCartTerm()}?`,
      buttons: [
        {
          size: 'small',
          status: 'danger',
          text: 'Yes',
          onPress: _onRemoveAll,
        },
        { size: 'small', text: 'No' },
      ],
    });
  const _renderItem = ({ index, item }) => {
    // exclusive item use end of mealPlanSchedule date (friday)
    const dateIndex = item.is_exclusive ? schedule?.length - 1 : index;
    const date = schedule[dateIndex];
    return <MealPlanItem {...item} date={date} onRemove={_onRemove(index)} />;
  };

  return (
    <Fragment>
      {/* show list when trigger */}
      <View
        style={[ThemeStyle.spacingTopMedium, ThemeStyle.spacingBottomMedium]}
      >
        {/* Title */}
        <Text category="s2">Your Meal Plan Order</Text>

        <View style={ThemeStyle.pageVerticalSpacingMedium}>
          <Divider style={ThemeStyle.divider} />
        </View>

        {/* Dispatch time */}
        <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
          <View style={ThemeStyle.spacingBottomMedium}>
            <Text category="p1">{dayCount} DAY MEAL PLAN</Text>
            <Text category="p2">Dispatch Time: {dispatchTime}</Text>
          </View>
          {list.length > 0 && (
            <Button onPress={_toggleRemoveModal} plain>
              <View style={styles.itemRemoveButton}>
                <Text category="c1" status="control">
                  Remove
                </Text>
              </View>
            </Button>
          )}
        </View>

        {/* Meal plan list */}
        <List
          data={list}
          renderItem={_renderItem}
          ListEmptyComponent={
            <Text category="p1" style={ThemeStyle.spacingBottomMedium}>
              No item added to your {getCartTerm()}
            </Text>
          }
          style={{ maxHeight: 450 }}
          withSeparator
          plain
        />

        {/* Item remaining, Summary */}
        {list.length < dayCount ? (
          (() => {
            const itemRemaining = dayCount - list.length;
            return (
              <Text category="c1" status="danger">
                You need to add {itemRemaining} more{' '}
                {pluralize(itemRemaining, 'item')} for this {dayCount} day meal
                plan.
              </Text>
            );
          })()
        ) : (
          <View style={isLessThanMinimum && ThemeStyle.spacingBottomMedium}>
            <StoreCartSummary
              cart={list}
              isDelivery={false} // always false on cart page, since we only need to display the promo details here
              promotion={promotion}
              subTotalPromoPercent={shopData?.meal_plan_discount}
              noHspacing
            />

            <MinimumOrderNote
              current={subTotal}
              minimum={promotionMinimum}
              show={isLessThanMinimum}
              voucher={storePromo}
              onRemove={onPromoRemove}
            />
          </View>
        )}

        {/* Proceed button */}
        <Button
          status="success"
          disabled={list.length == 0 || list.length != dayCount}
          onPress={_onCheckout}
          loading={isPaymentLinkLoading}
        >
          {() => (
            <Text category="c1" status="control">
              Create meal plan
            </Text>
          )}
        </Button>
      </View>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  buttonListWrapper: {
    position: 'absolute',
    ...Platform.select({
      android: {
        bottom: 70,
        right: globalSpacing,
      },
      ios: {
        bottom: 100,
        right: globalSpacing,
      },
    }),
  },
  listImage: {
    width: 60,
    height: 60,
  },
  itemRemoveButton: {
    ...ThemeStyle.pageVerticalSpacingSmall,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    backgroundColor: ThemeColor.red,
    borderRadius: 10,
  },
  itemRemoveTxt: { ...ThemeStyle.bold, fontSize: 12 },
});

export default MealPlanList;
