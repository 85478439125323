import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import lodashPick from 'lodash/pick';

import constants from '../../Config/constants';
import MenuItemHelper from '../../Helper/MenuItem';
import Service from '../../Service';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';
import QuickCart from '../QuickCart';
import ScaleButtonAnimation from '../Web/Intereaction/ScaleButtonAnimation';

import BaseCard from './';
import styles, { ids, titleProps, subTitleProps } from './styles';
import { itemMediumProps } from './itemPropCheck';

function ItemMedium({
  containerStyle,
  isMealPlan,
  onError,
  onPress,
  ...itemInfo
}) {
  const { discounted_price, image, name, price, regular_price } = itemInfo;
  const hasDiscount = MenuItemHelper.hasDiscount(
    lodashPick(itemInfo, ['active_discount', 'discount_type', 'discount_value'])
  );
  const isUnavailable = MenuItemHelper.isItemUnavailable(
    lodashPick(itemInfo, ['available', 'off_dates'])
  );
  const limit = MenuItemHelper.getCategoryOrItemStock(
    lodashPick(itemInfo, [
      'item_limit_per_day',
      'item_limit_per_time_slot',
      'stock',
      'category_stock',
    ])
  );
  const store_theme = useTheme();
  const disabled = isUnavailable || limit === 0;
  const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;
  return (
    <ClickableComponent
      disabled={disabled}
      dataSet={{ media: ids.itemMediumContainer }}
      onPress={onPress}
      style={[styles.itemMediumContainer, StyleSheet.flatten(containerStyle)]} // mobile style
      buttonStyle={[
        styles.itemMediumContainer,
        StyleSheet.flatten(containerStyle),
      ]} // web style
      plain
    >
      <View style={ThemeStyle.spacingBottomSmall}>
        {/* Item image */}
        <BaseCard image={image} imageStyle={{ aspectRatio: 1 / 1 }} />
        {/* show quick cart if not disabled */}
        {!disabled && (
          <QuickCart
            item={itemInfo}
            onError={onError}
            store_theme={store_theme}
          />
        )}
      </View>
      {/* Item name */}
      <Text {...titleProps} numberOfLines={2}>
        {name}
      </Text>
      {/* Item price or original price and compare price if has discount */}
      {!isMealPlan && (
        <View style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRowCenter]}>
          <Text
            {...subTitleProps}
            style={[
              subTitleProps.style,
              ThemeStyle.spacingRightSmall,
              hasDiscount && ThemeStyle.strikethrough,
            ]}
          >
            {Service.commafyNumber(
              Number(hasDiscount ? regular_price : price),
              true
            )}
          </Text>
          {hasDiscount && (
            <Text
              {...titleProps}
              style={[titleProps.style, ThemeStyle.noticeText]}
            >
              {Service.commafyNumber(Number(discounted_price), true)}
            </Text>
          )}
        </View>
      )}
      {/* Item limit */}
      {Boolean(limit) && (
        <Text
          {...subTitleProps}
          style={[subTitleProps.style, ThemeStyle.noticeText]}
        >
          {limit} items left!
        </Text>
      )}
    </ClickableComponent>
  );
}

ItemMedium.propTypes = itemMediumProps;

export default ItemMedium;
