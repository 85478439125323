import React, { useState } from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import ThemeStyle from '../../Theme/styles';

const lineHeight = 21;

const ReadMore = ({ readmoreContainerStyle, value, numberOfLines }) => {
  const [readMore, setReadMore] = useState(false);
  const [showButtonReadMore, setShowButtonReadMore] = useState(false); //To show ur remaining Text
  const [lineNumber, setNumberOfLines] = useState(undefined);
  const readMoreBtnTxt = showButtonReadMore ? 'Show less' : 'Show more';
  //toggle for read more button
  const toggleReadMoreButton = () => setShowButtonReadMore(!showButtonReadMore);

  const onLayout = (e) => {
    const limitHeight = numberOfLines * lineHeight; //get the limit height
    const currentHeight = e.nativeEvent.layout.height; //get the current height
    if (currentHeight > limitHeight) {
      //if the value has more data than to set to limit height then set the line numner and show the read more button
      setNumberOfLines(numberOfLines);
      setReadMore(true);
      return;
    }
  };
  return (
    <View style={readmoreContainerStyle}>
      <Text
        onLayout={onLayout}
        numberOfLines={showButtonReadMore ? undefined : lineNumber}
        category="p2"
        status="primary"
        style={[{ lineHeight: lineHeight }, ThemeStyle.spacingBottomSmall]}
      >
        {value}
      </Text>

      {readMore && (
        <Text
          category="p2"
          status="success"
          onPress={toggleReadMoreButton}
          style={{ lineHeight: lineHeight }}
        >
          {readMoreBtnTxt}
        </Text>
      )}
    </View>
  );
};

export default ReadMore;
