import React, { useState } from 'react';
import ActionSheet from 'react-native-actions-sheet';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import MealPlanTypes from '../MealPlanTypes';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

/**
 * useful data shape is
 * {
 *   value?: 'meal plan type 3_day or 5_day
 * }
 */
function MealPlanTypeSelectorSheet({ sheetId }) {
  const [data, setData] = useState();

  const _onTypeSelection = (type) => {
    modals.hide(modals.MEAL_PLAN_TYPE_SELECTOR, type);
  };

  return (
    <ActionSheet
      id={sheetId}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        <Text style={ThemeStyle.spacingBottom} {...SECTION_TITLE}>
          Select Meal Plan Type
        </Text>

        <View style={ThemeStyle.flexDirectionRowCenterCenter}>
          <MealPlanTypes
            value={data?.value}
            onChange={(data) => _onTypeSelection(data.value)}
          />
        </View>
      </View>
    </ActionSheet>
  );
}

export default MealPlanTypeSelectorSheet;
