import { Icon, Text } from '@ui-kitten/components';
import React from 'react';
import { Linking, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { ScrollView } from 'react-native-gesture-handler';

import validation from '../../Config/validationRules';
import externalLinks from '../../Config/externalLinks';

//THEMES
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import breakpoints from '../../Theme/styles/breakpoints';

import useFormField from '../../Hooks/useFormField';

import OTPTimer from '../../Screens/Auth/OTPTimer';

import Button from '../../Components/Button';
import Form from '../../Components/FormFields/Form';
import FormField from '../../Components/FormFields/FormField';
import SubmitButton from '../../Components/FormFields/SubmitButton';
import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../../Config/constants';

import routeList from '../../Routes/list';

const validationSchema = validation.createSchema({
  first_name: validation.firstNameSchema,
  last_name: validation.lastNameSchema,
  email: validation.emailSchema,
  phone_number: validation.phoneSchema,
});

const formInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
};

const iconSize = 30;

type Props = {
  modalId?: any; // use on web to close the modal
  timer?: number;
  onBackSignup?: Function; // optional, on web its not needed
  onCountdownEnd: Function;
  onSignupSubmit: Function;
};

const SignupForm = ({
  modalId,
  timer,
  onBackSignup,
  onCountdownEnd,
  onSignupSubmit,
}: Props) => {
  const navigation = useNavigation<any>();
  const { hideModalPrompt } = useModalPrompt();
  const { getInputProps, setSubmitButtonRef } = useFormField();

  const _goToTermsAndCondition = () => {
    navigation.navigate(routeList.LEGAL_BINDING_CONTRACT, {
      key: 'termsAndConditions',
      title: 'Terms And Conditions',
    });
    if (modalId) {
      hideModalPrompt(modalId, {});
    }
  };

  const _goToPrivacyPolicy = () => {
    Linking.openURL(externalLinks.PRIVACY_POLICY);
  };
  const showTimer = timer > 0;
  return (
    <ScrollView>
      {typeof onBackSignup === 'function' && (
        <View
          style={[
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
            ThemeStyle.spacingBottom,
          ]}
        >
          <Button onPress={onBackSignup} plain>
            <Icon
              name="arrow-back"
              fill={ThemeColor.darkGray}
              height={iconSize}
              width={iconSize}
            />
          </Button>

          <Text category="h6">Create your account</Text>

          <View style={{ width: iconSize }} />
        </View>
      )}

      <Form
        initialValues={formInitialValues}
        onSubmit={onSignupSubmit}
        validationSchema={validationSchema}
      >
        <View>
          <FormField
            type="email"
            name="email"
            label="Email *"
            placeholder=""
            direction="column"
            withBackground
            withClear
            {...getInputProps(1, true)}
          />
          <View
            dataSet={{ media: ids.inputRowContainer }}
            style={styles.inputRowContainer}
          >
            <View style={constants.isWeb && ThemeStyle.flex1}>
              <FormField
                direction="column"
                name="first_name"
                label="First Name *"
                placeholder=""
                withBackground
                withClear
                {...getInputProps(2, true)}
              />
            </View>
            <View dataSet={{ media: ids.separator }} style={styles.separator} />
            <View style={constants.isWeb && ThemeStyle.flex1}>
              <FormField
                direction="column"
                name="last_name"
                label="Last Name *"
                placeholder=""
                withBackground
                withClear
                {...getInputProps(3, true)}
              />
            </View>
          </View>

          <FormField
            type="phone"
            name="phone_number"
            label="Phone *"
            placeholder=""
            direction="column"
            withBackground
            withClear
            {...getInputProps(4, true)}
          />

          <OTPTimer
            timer={timer}
            text="You can sign up again after"
            onCountdownEnd={onCountdownEnd}
          />

          <SubmitButton
            onRef={setSubmitButtonRef}
            disabled={showTimer}
            style={constants.isWeb && { borderRadius: 0 }}
            text="Next"
          />
        </View>
      </Form>

      <View style={styles.legalBindingRow}>
        <Text category="p2">
          {/* By clicking Sign up, Continue with Facebook or Continue with Google, you agree to our */}
          By tapping “Sign up”, you agree to our{' '}
        </Text>

        <Button testID="tncButton" onPress={_goToTermsAndCondition} plain>
          <Text category="p2" status="success" style={styles.underline}>
            Terms and Conditions
          </Text>
        </Button>

        <Text category="p2"> and </Text>

        <Button testID="ppButton" onPress={_goToPrivacyPolicy} plain>
          <Text category="p2" status="success" style={styles.underline}>
            Privacy Policy
          </Text>
        </Button>

        <Text category="p2">.</Text>
      </View>
    </ScrollView>
  );
};

const { ids, styles } = StyleSheet.create({
  underline: {
    textDecorationLine: 'underline',
  },
  inputRowContainer: {
    [`@media ${breakpoints.sm}`]: ThemeStyle.flexDirectionRowCenter,
  },
  separator: {
    [`@media ${breakpoints.sm}`]: ThemeStyle.rowSeparator,
  },
  legalBindingRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...ThemeStyle.spacingVerticalMedium,
  },
});

export default SignupForm;
