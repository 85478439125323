import React from 'react';
import { View, Linking, FlatList } from 'react-native';
import { Text, Icon, Divider, Layout } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';

import links from '../../Config/externalLinks';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

import Button from '../Button';

import AppStore from '../../Assets/AppStore.svg';
import GooglePlayStore from '../../Assets/GooglePlayStore.svg';

const Footer = () => {
  const onPressAppStore = () => Linking.openURL(links.APP_STORE);

  const onPressGooglePlayStore = () => Linking.openURL(links.PLAY_STORE);

  //#region COMPANY

  const onPressBlog = () => Linking.openURL(links.BLOG);

  const onPressAbout = () => Linking.openURL(links.ABOUT);

  const onPressContact = () => Linking.openURL(links.CONTACT);
  //#endregion

  //#region PARTNERSHIPS
  const onPressPickupEnterprise = () => Linking.openURL(links.ENTERPRISE);

  const onPressCharities = () => Linking.openURL(links.CHARITIES);

  const onPressPickupAmbassador = () => Linking.openURL(links.AMBASSADOR);

  //#endregion
  //#region SUPPORT

  const onPressFAQS = () => Linking.openURL(links.FAQ);

  const onPressReportViolation = () => {};

  //#endregion

  //#region LEGAL

  const onPressTermsofSevice = () => Linking.openURL(links.TERMS_OF_SERVICES);

  const onPressPrivacy = () => Linking.openURL(links.PRIVACY_POLICY);

  const onPressTrustCentre = () => Linking.openURL(links.TRUST_CENTRE);

  //#endregion

  ////#region SOCIAL MEDIA
  const onPressFaceBook = () => Linking.openURL(links.FACEBOOK);

  const onPressInstagram = () => Linking.openURL(links.INSTRAGRAM);

  const onPressYoutube = () => Linking.openURL(links.YOUTUBE);
  //#endregion

  const COMPANY = [
    {
      title: 'Blog',
      action: onPressBlog,
    },
    {
      title: 'About',
      action: onPressAbout,
    },
    {
      title: 'Contact',
      action: onPressContact,
    },
  ];

  const PARTNERSHIPS = [
    {
      title: 'Pickup for enterprise',
      action: onPressPickupEnterprise,
    },
    {
      title: 'Charities',
      action: onPressCharities,
    },
    {
      title: 'Pickup ambassador',
      action: onPressPickupAmbassador,
    },
  ];

  const SUPPORT = [
    {
      title: 'FAQs',
      action: onPressFAQS,
    },
    {
      title: 'Report an issue',
      action: onPressReportViolation,
    },
  ];

  const LEGAL = [
    {
      title: 'Terms of service',
      action: onPressTermsofSevice,
    },
    {
      title: 'Privacy policy',
      action: onPressPrivacy,
    },
    {
      title: 'Trust centre',
      action: onPressTrustCentre,
    },
  ];

  const itemSeparator = () => <View style={{ height: 10 }} />;

  const headerComponent = (text) => (
    <View style={{ marginBottom: 10 }}>
      <Text category="c1">{text}</Text>
    </View>
  );

  return (
    <Layout>
      <View dataSet={{ media: ids.wrapper }} style={styles.wrapper}>
        <View
          dataSet={{ media: ids.contentWrapper }}
          style={styles.contentWrapper}
        >
          <View style={styles.colWrapper}>
            <FlatList
              key={'companyList'}
              listKey="company"
              keyExtractor={(item, index) => `companyList-${index}`}
              data={COMPANY}
              dataSet={{ media: ids.contentContainerStyle }}
              contentContainerStyle={styles.contentContainerStyle}
              ListHeaderComponent={headerComponent('Company')}
              renderItem={({ item, index }) => (
                <Button
                  key={`index-${index}`}
                  testID={`index-${index}`}
                  onPress={item.action}
                  plain
                >
                  <Text category="c1" status="primary">
                    {item.title}
                  </Text>
                </Button>
              )}
              ItemSeparatorComponent={itemSeparator}
            />
          </View>

          <View style={styles.colWrapper}>
            <FlatList
              key={'partnershipList'}
              listKey="partnership"
              keyExtractor={(item, index) => `partnershipList-${index}`}
              data={PARTNERSHIPS}
              dataSet={{ media: ids.contentContainerStyle }}
              contentContainerStyle={styles.contentContainerStyle}
              ListHeaderComponent={headerComponent('Partnerships')}
              renderItem={({ item, index }) => (
                <Button
                  key={`index-${index}`}
                  testID={`index-${index}`}
                  onPress={item.action}
                  plain
                >
                  <Text category="c1" status="primary">
                    {item.title}
                  </Text>
                </Button>
              )}
              ItemSeparatorComponent={itemSeparator}
            />
          </View>

          <View style={styles.colWrapper}>
            <FlatList
              key={'supportList'}
              listKey="support"
              keyExtractor={(item, index) => `supportList-${index}`}
              data={SUPPORT}
              dataSet={{ media: ids.contentContainerStyle }}
              contentContainerStyle={styles.contentContainerStyle}
              ListHeaderComponent={headerComponent('Support')}
              renderItem={({ item, index }) => (
                <Button
                  key={`index-${index}`}
                  testID={`index-${index}`}
                  onPress={item.action}
                  plain
                >
                  <Text category="c1" status="primary">
                    {item.title}
                  </Text>
                </Button>
              )}
              ItemSeparatorComponent={itemSeparator}
            />
          </View>
          <View style={styles.colWrapper}>
            <FlatList
              key={'legalList'}
              listKey="legal"
              keyExtractor={(item, index) => `legalList-${index}`}
              data={LEGAL}
              dataSet={{ media: ids.contentContainerStyle }}
              contentContainerStyle={styles.contentContainerStyle}
              ListHeaderComponent={headerComponent('Legal')}
              renderItem={({ item, index }) => (
                <Button
                  key={`index-${index}`}
                  testID={`index-${index}`}
                  onPress={item.action}
                  plain
                >
                  <Text category="c1" status="primary">
                    {item.title}
                  </Text>
                </Button>
              )}
              ItemSeparatorComponent={itemSeparator}
            />
          </View>
        </View>

        <View
          dataSet={{ media: ids.appButtonContainer }}
          style={styles.appButtonContainer}
        >
          <Button
            onPress={onPressAppStore}
            style={styles.button_app_store}
            plain
          >
            <AppStore />
          </Button>

          <Divider style={{ height: 10 }} />

          <Button
            style={styles.button_app_store}
            onPress={onPressGooglePlayStore}
            plain
          >
            <GooglePlayStore />
          </Button>
        </View>
      </View>

      <View dataSet={{ media: ids.bottomWrapper }} style={styles.bottomWrapper}>
        <View style={ThemeStyle.flex1}>
          <View style={styles.colWrapper}>
            <Button onPress={() => {}} plain>
              <Text category="c1" status="primary">
                Copyright © 2024 Pickup.ph. This site was made with ❤️ in the
                Philippines.
              </Text>
            </Button>
          </View>
        </View>
        <View
          dataSet={{ media: ids.social_media_container }}
          style={styles.social_media_container}
        >
          <Button onPress={onPressFaceBook} plain>
            <Icon
              name={'facebook'}
              fill={ThemeColor.darkGray}
              pack="material"
              style={styles.icon_size}
            />
          </Button>
          <Button onPress={onPressInstagram} plain>
            <Icon
              name={'instagram'}
              fill={ThemeColor.darkGray}
              pack="material"
              style={styles.icon_size}
            />
          </Button>
          <Button onPress={onPressYoutube} plain>
            <Icon
              name={'youtube'}
              fill={ThemeColor.darkGray}
              pack="material"
              style={styles.icon_size}
            />
          </Button>
        </View>
      </View>
    </Layout>
  );
};

const { ids, styles } = StyleSheet.create({
  wrapper: {
    display: 'flex',
    justiFyContent: 'flex-start',
    borderTopColor: ThemeColor.gray,
    borderTopWidth: 1,
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column-reverse',
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column-reverse',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexDirection: 'row',
    },
  },
  contentWrapper: {
    flex: 1,
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column',
      alignSelf: 'center',
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column',
      alignSelf: 'center',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'row',
      alignSelf: 'flex-start',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexDirection: 'row',
      alignSelf: 'flex-start',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexDirection: 'row',
      alignSelf: 'flex-start',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexDirection: 'row',
      alignSelf: 'flex-start',
    },
  },
  contentContainerStyle: {
    [`@media ${BreakPoints.xs}`]: {
      alignItems: 'center',
      textAlign: 'center',
    },
    [`@media ${BreakPoints.sm}`]: {
      alignItems: 'center',
      textAlign: 'center',
    },
    [`@media ${BreakPoints.md}`]: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    [`@media ${BreakPoints.lg}`]: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    [`@media ${BreakPoints.xl}`]: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    [`@media ${BreakPoints.xxl}`]: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  colWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '2%',
  },
  bottomWrapper: {
    display: 'flex',
    justiFyContent: 'flex-start',
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column-reverse',
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column-reverse',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexDirection: 'row',
    },
  },
  appButtonContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '2%',
    paddingLeft: '2%',
    paddingBottom: '2%',
    paddingRight: '0%',
    [`@media ${BreakPoints.xs}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.sm}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.md}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.lg}`]: {
      alignItems: 'flex-end',
    },
    [`@media ${BreakPoints.xl}`]: {
      alignItems: 'flex-end',
    },
    [`@media ${BreakPoints.xxl}`]: {
      alignItems: 'flex-end',
    },
  },
  icon_size: { width: 20, height: 20 },
  button_app_store: {
    borderRadius: 5,
    alignItems: 'center',
    justiFyContent: 'center',
    backgroundColor: ThemeColor.darkGray,
  },
  social_media_container: {
    flexDirection: 'row',
    alignItems: 'center',
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'center',
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'center',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'flex-end',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexDirection: 'flex-end',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexDirection: 'flex-end',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexDirection: 'flex-end',
    },
  },
});

export default Footer;
