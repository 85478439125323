import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';

import UrlParameter from '../../Helper/UrlParameter';
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import useGoBack from '../../Hooks/useGoBack';
import storeApi from '../../Service/api/store';
import routeList from '../../Routes/list';
import { activeAddressSelector } from '../../RTK/user/selectors';

import { myFavorites } from '../Home/sections';

function useSeeAllController(props) {
  const { createRequest } = useCancellableRequest();
  const goBack = useGoBack();
  const activeAddress = useSelector(activeAddressSelector);
  const userFavorites = useSelector((state) => state.favourite?.favouriteData);

  const cacheBuster = lodashGet(props.route, 'params.cacheBuster');
  const key = lodashGet(props.route, 'params.key');
  const title = lodashGet(props.route, 'params.title');
  const value = lodashGet(props.route, 'params.value');

  const initialData = [{ loading: true }, { loading: true }, { loading: true }];

  useEffect(() => {
    const noParams = !key || !value || !title;
    const favoriteFunnelButNoFavorites =
      value === myFavorites.tag.value && lodashIsEmpty(userFavorites);
    if (noParams || favoriteFunnelButNoFavorites) {
      goBack();
    }
  }, []);

  const _getQueryParams = (page) => {
    let queryParams = `?${UrlParameter.getLimitAndOffset(
      page
    )}&open_only=true&place_id=${activeAddress.place_id}`;
    if (key) {
      queryParams += `&${key}=${value}`;
      if (cacheBuster === true || cacheBuster === 'true') {
        queryParams += `&v=${new Date().getTime()}`;
      }
    } else if (typeof value === 'string') {
      queryParams += `&tags=${value}`;
    }
    return queryParams;
  };

  const _getStoresData = (page) => {
    const queryParams = _getQueryParams(page);
    return createRequest(storeApi.getAllStores, queryParams);
  };

  const _onCardPressed = (item) => () => {
    props.navigation.navigate(routeList.STORE, {
      id: item.id,
      distance: item.distance,
      duration: item.duration,
    });
  };

  return {
    initialData,
    key,
    title,
    value,
    getQueryParams: _getQueryParams,
    getStoresData: _getStoresData,
    onCardPressed: _onCardPressed,
  };
}

export default useSeeAllController;
