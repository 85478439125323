import React from 'react';
import PropTypes from 'prop-types';
import { Text, useTheme } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';

import constants from '../Config/constants';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';
import SwitchTab from './SwitchTab';
import useDetailsController from '../Screens/Store/controller/details';

const { ORDER_TYPES, ORDER_TYPE_DATA } = constants;

const SwitchTabOrderType = (props) => {
  const store_theme = useTheme();
  const { availableStoreOrderTypes, selectedOrderType, updateShopOrderType } =
    useDetailsController({
      additionalCondition: true,
      isModal: false,
    });

  const buttonColor = store_theme['button_color'];
  const buttonTextColor = store_theme['button_text_color'];

  // web specific UI
  const _renderItem = (data, index, { isButtonActive, isTextActive }) => {
    const applyButtonColor = isButtonActive && buttonTextColor;
    return (
      <Button
        key={index}
        style={({ hovered }) => [
          ThemeStyle.flex1,
          ThemeStyle.flexDirectionRowCenterCenter,
          { paddingVertical: 15 },
          hovered &&
            !isButtonActive && {
              borderRadius: 5,
              backgroundColor: buttonColor
                ? `${buttonColor}1A`
                : ThemeColor.green100,
            },
        ]}
        onPress={() => !isButtonActive && updateShopOrderType(data)}
        isHoverable
      >
        <Text
          category="c1"
          status={isTextActive ? 'control' : 'primary'}
          style={applyButtonColor && { color: buttonTextColor }}
        >
          {data.label}
        </Text>
      </Button>
    );
  };

  const conditionalStyle = constants.isWeb
    ? {
        activeColor: buttonColor,
        containerStyle: {
          ...ThemeStyle.flexDirectionRow,
          borderRadius: 5,
          zIndex: 1,
        },
        renderItem: _renderItem,
      }
    : {};

  const orderTypes = props.showMealPlan
    ? lodashFilter(
        ORDER_TYPE_DATA,
        (d) => availableStoreOrderTypes[d.apiDataKey]
      )
    : lodashFilter(
        ORDER_TYPE_DATA,
        (d) =>
          availableStoreOrderTypes[d.apiDataKey] &&
          d.value !== ORDER_TYPES.MEAL_PLAN
      );

  return (
    <SwitchTab
      data={orderTypes}
      value={selectedOrderType || null}
      onChange={updateShopOrderType}
      {...conditionalStyle}
      {...props}
    />
  );
};

SwitchTabOrderType.propTypes = {
  size: PropTypes.string,
  showMealPlan: PropTypes.bool,
};

export default SwitchTabOrderType;
