import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';

import lodashFilter from 'lodash/filter';
import lodashSortBy from 'lodash/sortBy';

import messages from '../../../../Config/messages';

import MenuItemHelper from '../../../../Helper/MenuItem';

import useModalPrompt from '../../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';

import { MODALPROMPT } from '../../../../Components/Web/Modal/ModalPrompt/config';
import { shopSelector } from '../../../../RTK/shop/selectors';

const useListSectionItemController = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const shopData = useSelector(shopSelector);
  const { showModalPrompt } = useModalPrompt();

  const [searchItems, setSearchItems] = useState({});

  const onItemPress = (item) => {
    showModalPrompt(MODALPROMPT.itemModal, {
      item: {
        ...item,
        item_id: item?.id,
      },
      route: route,
      navigation: navigation,
    });
  };

  const searchItemByName = async (name) => {
    if (name == '') {
      setSearchItems({});
      return;
    }
    setSearchItems([]);
    let categories = lodashSortBy(shopData?.categories, ['category_position']); // sort category
    let filterItemByName = shopData.menu_list
      .map((item) => item.data)
      .map((data) =>
        data.filter((obj) =>
          obj.name.toLowerCase().includes(name.toLowerCase())
        )
      )
      .flat();

    if (filterItemByName.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        let filterItemByCategory = lodashFilter(
          filterItemByName,
          (item) => item.category === categories[i].category_name
        );

        if (filterItemByName.length > 0 && filterItemByCategory.length > 0) {
          setSearchItems((prevObject) => ({
            ...prevObject,
            categories: [...categories],
            menu_list: [
              {
                title: shopData.categories[i].category_name,
                subTitle: shopData.categories[i].is_exclusive
                  ? messages.MEAL_PLAN_EXCLUSIVE
                  : '',
                isExclusive: shopData.categories[i].is_exclusive,
                categoryStock: MenuItemHelper.getCategoryOrItemStock(
                  shopData.categories[i]
                ), // for display purpose only of category stock
                data: [...filterItemByCategory],
              },
            ],
          }));
        }
      }
    } else {
      let filterItemByCategories = shopData.menu_list
        .map((item) => item.data)
        .map((data) =>
          data.filter((obj) =>
            obj.category.toLowerCase().includes(name.toLowerCase())
          )
        )
        .flat();

      for (let i = 0; i < categories.length; i++) {
        let filterItemByCategory = lodashFilter(
          filterItemByCategories,
          (item) => item.category === categories[i].category_name
        );

        if (
          filterItemByCategories.length > 0 &&
          filterItemByCategory.length > 0
        ) {
          setSearchItems((prevObject) => ({
            ...prevObject,
            categories: [...categories],
            menu_list: [
              {
                title: shopData.categories[i].category_name,
                subTitle: shopData.categories[i].is_exclusive
                  ? messages.MEAL_PLAN_EXCLUSIVE
                  : '',
                isExclusive: shopData.categories[i].is_exclusive,
                categoryStock: MenuItemHelper.getCategoryOrItemStock(
                  shopData.categories[i]
                ), // for display purpose only of category stock
                data: [...filterItemByCategory],
              },
            ],
          }));
        }
      }
    }
  };

  return {
    searchItems,
    searchItemByName,
    onItemPress,
  };
};

export default useListSectionItemController;
