import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Animated, StyleSheet, View } from 'react-native';
import { Icon, Text, Spinner } from '@ui-kitten/components';
import {
  isEmpty as lodashIsEmpty,
  isEqual as lodashIsEqual,
  pick as lodashPick,
} from 'lodash';

import modals from '../Components/Sheets/modals';
import constants from '../Config/constants';
import useMealPlan from '../Hooks/useMealPlan';
import withCart from '../Hooks/withCart';
import withController from '../Hooks/withController';
import withNavigation from '../Hooks/withNavigation';

import { allCartSelector } from '../RTK/cart/selectors';
import { checkoutOrderTypeSelector } from '../RTK/checkout/selectors';
import { shopSelector } from '../RTK/shop/selectors';
import { isLoggedInSelector } from '../RTK/user/selectors';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';
import QuickCartQtyBadge from './QuickCartQtyBadge';

//@ts-ignore: JS Code error
import { RAZZLE_BUILD_MODE } from '@env';
import { MODALPROMPT } from './Web/Modal/ModalPrompt/config';

const iconSize = 25;
const buttonSize = iconSize * 1.5;
const timeoutDuration = 3; // seconds
class QuickCart extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.opacityRef = createRef();
    this.opacityRef.current = new Animated.Value(0);
    this.state = {
      shouldExpand: false,
      submitting: false,
      thisProductOnCart: [],
    };
  }

  componentDidMount() {
    this._getLatestProductOnCart();
  }

  componentDidUpdate(prevProps) {
    // this is for checking the cart data, store menu if not same pull the latest from redux
    const { cartData: prevCartData, shopData: prevShopData } = prevProps;
    const { cartData: currCartData, shopData: currShopData } = this.props;
    const isShopDataEqual = lodashIsEqual(prevShopData, currShopData); // is shop data equal
    const isCartDataEqual = lodashIsEqual(prevCartData, currCartData); // is cart data equal
    const shouldGetLatestProduct = !isCartDataEqual || !isShopDataEqual; // if not both
    if (shouldGetLatestProduct) {
      this._getLatestProductOnCart();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  shouldComponentUpdate(prevProps, prevState) {
    const keysToCompare = [
      'item',
      'shopData',
      'cartData',
      'isAddingOrUpdatingCart',
    ];
    const previousProps = lodashPick(prevProps, keysToCompare);
    const currentProps = lodashPick(this.props, keysToCompare);
    const isPropsEqual = lodashIsEqual(previousProps, currentProps);
    const isStateEqual = lodashIsEqual(prevState, this.state);
    const shouldUpdate = !isPropsEqual || !isStateEqual;
    return shouldUpdate;
  }

  _getLatestProductOnCart = () => {
    const { shopData, item, getThisProductOnCart } = this.props;
    const storeId = shopData?.id;
    const thisProductOnCart = getThisProductOnCart(storeId, item.id);
    this.setState({ thisProductOnCart });
  };

  _showIncrementDecrementButton = (immediate) => {
    if (this.props.mealPlan.isMealPlan) {
      // prevent animation in background if + is press if order type is meal plan
      return;
    }
    clearTimeout(this.timeout); // clear previous timeout
    // update state to show the quick cart - + button (but it has opacity of default 0)
    this.setState({ shouldExpand: true }, async () => {
      if (this.timeout === null) {
        // if no timeout yet it means first show of - + button, animate it to show
        await this._animateOpenOrClose(true);
      }
      // create a timer
      this.timeout = setTimeout(
        async () => {
          if (this.state.submitting) {
            // recursion, do not hide the expanded quickcart if still submitting
            this._showIncrementDecrementButton(immediate);
          } else {
            // if no interaction or not submitting
            await this._animateOpenOrClose(false); // close animation
            this.setState({ shouldExpand: false }); // udpate state to hide the element
            this.timeout = null; // remove timer
          }
        },
        immediate ? 0 : timeoutDuration * 1000
      );
    });
  };

  _animateOpenOrClose = (isOpen) => {
    return new Promise((resolve) => {
      Animated.timing(this.opacityRef.current, {
        toValue: isOpen ? 1 : 0,
        duration: 250,
        useNativeDriver: true,
      }).start(resolve);
    });
  };

  _onQtyPressed = () => {
    const { thisProductOnCart } = this.state;
    if (thisProductOnCart.length === 1) {
      this._showIncrementDecrementButton();
    } else {
      modals.show(modals.MULTIPLE_VARIANT, { items: thisProductOnCart });
    }
  };

  _onAddToCartPressed = (qty, immediateCollapse) => {
    if (
      constants.isWeb && // To check if web platform
      !this.props.isLogin && // To check if not login
      RAZZLE_BUILD_MODE !== 'branded' // To check if not branded store
    ) {
      return this.props.showModalPrompt(MODALPROMPT.authentication, {
        isLogin: true,
        navigation: this.props.navigation,
      });
    }
    qty = typeof qty === 'number' ? qty : 1;
    const {
      item,
      selectedOrderType,
      shopData,
      addOrUpdateCart,
      getThisProductOnCart,
      onError,
      removeThisProductOnCart,
    } = this.props;
    // timing of animation of expand or collapse when user click the increase/decrease/delete button
    this._showIncrementDecrementButton(immediateCollapse);
    const store_id = shopData?.id;
    const store_name = shopData?.name;
    // get existing item on cart
    const existingItemOnCart = getThisProductOnCart(store_id, item.id);
    const firstExistingItem = existingItemOnCart?.[0];
    // add the existing quantity to button press (-1 or +1)
    const toBeAddedQty = qty + (firstExistingItem?.quantity || 0);
    // if toBeAddedQty become zero, it means remove button
    const isRemove = toBeAddedQty <= 0;
    // callbacks
    const _onError = (e) => {
      if (constants.isWeb) {
        onError?.(e);
      }
      this.setState({ submitting: false });
    };
    const _onSuccess = () => {
      this.setState({ submitting: false }, this._getLatestProductOnCart);
    };
    // setting loader and api call
    this.setState({ submitting: true }, async () => {
      const itemWithReduxState = {
        ...item,
        cart_details_id: firstExistingItem?.cart_details_id,
        extras: firstExistingItem?.extras,
        quantity: toBeAddedQty,
      };
      if (isRemove) {
        removeThisProductOnCart({
          item: itemWithReduxState,
          storeId: store_id,
          onError: _onError,
          onSuccess: _onSuccess,
        });
      } else {
        // if add or update cart only
        addOrUpdateCart({
          from: 'Quick Cart',
          item: itemWithReduxState,
          orderType: selectedOrderType,
          store: { id: store_id, name: store_name },
          onCancel: () => this.setState({ submitting: false }),
          onError: _onError,
          onSuccess: _onSuccess,
        });
      }
    });
  };

  _onReduceCartPressed = (cartQty) => () => {
    this._onAddToCartPressed(-1, cartQty === 1);
  };

  render() {
    const {
      disabled,
      isAddingOrUpdatingCart,
      isCanReschedule,
      item,
      mealPlan,
      position,
      shopData,
      store_theme = {},
    } = this.props;
    const { thisProductOnCart, shouldExpand, submitting } = this.state;
    const noQuickCart = !lodashIsEmpty(item?.extra_group);
    const isAddedToCart = !lodashIsEmpty(thisProductOnCart);
    const showPlusButton = isCanReschedule(shopData?.id, item?.id); // true = can see + button to trigger change schedule if stock limit reached, else hide + button
    const cartQty =
      thisProductOnCart?.reduce?.((acc, obj) => acc + obj.quantity, 0) || 1; // 0 || 1, default value to 1

    if (noQuickCart && lodashIsEmpty(thisProductOnCart)) {
      return null;
    } else if (!mealPlan.isMealPlan && shouldExpand) {
      return (
        <View>
          <Animated.View
            style={[
              styles.addButton,
              { opacity: this.opacityRef.current },
              position,
            ]}
          >
            {submitting && (
              <View style={styles.collapsedLoaderWrapper}>
                <View style={styles.collapsedLoaderContainer}>
                  <Spinner status="success" size="large" />
                </View>
              </View>
            )}

            <View style={styles.collapsedContentContainer}>
              <Button
                testID="quickCartDeductButton"
                onPress={this._onReduceCartPressed(cartQty)}
                style={[styles.icon, ThemeStyle.spacingRightMedium]}
                hitSlop={15}
                disabled={submitting || isAddingOrUpdatingCart}
                plain
              >
                <Icon
                  name={cartQty > 1 ? 'minus' : 'trash-outline'}
                  fill={store_theme['icon_color'] || ThemeColor.green}
                  style={styles.icon}
                />
              </Button>

              <Text
                category="c1"
                style={[
                  ThemeStyle.spacingLeftSmall,
                  ThemeStyle.spacingRightSmall,
                ]}
              >
                {cartQty}
              </Text>

              <Button
                testID="quickCartAddButton"
                onPress={this._onAddToCartPressed}
                style={[styles.icon, ThemeStyle.spacingLeftMedium]}
                hitSlop={15}
                disabled={
                  submitting || isAddingOrUpdatingCart || !showPlusButton
                }
                lighterDisabledOpacity
                plain
              >
                <Icon
                  name="plus"
                  fill={store_theme['icon_color'] || ThemeColor.green}
                  style={styles.icon}
                />
              </Button>
            </View>
          </Animated.View>
        </View>
      );
    } else if (!mealPlan.isMealPlan && isAddedToCart) {
      return (
        <View>
          <Button
            testID="quickInCartButton"
            onPress={this._onQtyPressed}
            style={[styles.addButton, position]}
            hitSlop={10}
            disabled={disabled || isAddingOrUpdatingCart}
            plain
          >
            <QuickCartQtyBadge
              size={buttonSize}
              textSize="p1"
              value={cartQty}
            />
          </Button>
        </View>
      );
    } else if (showPlusButton) {
      return (
        <View>
          <Button
            testID="quickCartButton"
            onPress={this._onAddToCartPressed}
            style={[styles.addButton, position]}
            hitSlop={10}
            disabled={disabled || isAddingOrUpdatingCart}
            plain
          >
            <View style={styles.addButtonContentContainer}>
              <Icon
                name="plus"
                fill={store_theme['icon_color'] || ThemeColor.green}
                style={styles.icon}
              />
            </View>
          </Button>
        </View>
      );
    } else {
      return null;
    }
  }
}

const styles = StyleSheet.create({
  addButton: {
    position: 'absolute',
    zIndex: 1,
  },
  addButtonContentContainer: {
    ...ThemeStyle.pageBackground,
    ...ThemeStyle.shadow,
    ...ThemeStyle.flexDirectionRowCenterCenter,
    width: buttonSize,
    height: buttonSize,
    borderRadius: iconSize,
  },
  collapsedContentContainer: {
    ...ThemeStyle.pageBackground,
    ...ThemeStyle.shadow,
    ...ThemeStyle.flexDirectionRowCenterCenter,
    ...ThemeStyle.pageHorizontalSpacing,
    height: buttonSize,
    borderRadius: iconSize,
  },
  collapsedLoaderWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  collapsedLoaderContainer: {
    ...ThemeStyle.flexDirectionRowCenterCenter,
    marginTop: 4,
  },
  icon: {
    width: constants.isWeb ? 20 : iconSize,
    height: constants.isWeb ? 20 : iconSize,
  },
});

QuickCart = withCart(QuickCart);
QuickCart = withNavigation(QuickCart);
QuickCart = withController(QuickCart, useMealPlan, 'mealPlan');

QuickCart.defaultProps = {
  position: { bottom: 10, right: 10 },
};

QuickCart.propsTypes = {
  disabled: PropTypes.bool,
  item: PropTypes.object.isRequired,
  position: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
  }),
  onError: PropTypes.func,
};

const mapStateToProps = (state) => ({
  shopData: shopSelector(state),
  cartData: allCartSelector(state),
  isAddingOrUpdatingCart: state.cart.isAddingOrUpdatingCart,
  selectedOrderType: checkoutOrderTypeSelector(state, state.shop.data?.id),
  isLogin: isLoggedInSelector(state),
});

export default connect(mapStateToProps)(QuickCart);
