import React, { useEffect, useRef, useCallback, memo } from 'react';
import { Animated } from 'react-native';

import Button from '../../Button';

const ScaleButtonAnimation = memo(
  ({
    dataSet,
    onPress,
    buttonStyle,
    contentStyle,
    onScaling,
    scale = 1.01,
    disabled = false,
    children,
    onLayout,
    originalScale = 1,
  }) => {
    const scaleValue = useRef(new Animated.Value(originalScale)).current;

    useEffect(() => {
      scaleValue.setValue(originalScale);
    }, [scaleValue, originalScale]);

    const handleScaleAnimation = useCallback(() => {
      Animated.timing(scaleValue, {
        toValue: scale,
        duration: 100,
        useNativeDriver: true,
      }).start();
      onScaling?.(true);
    }, [scale, onScaling, scaleValue]);

    const handleReset = useCallback(() => {
      Animated.timing(scaleValue, {
        toValue: originalScale,
        duration: 100,
        useNativeDriver: true,
      }).start();
      onScaling?.(false);
    }, [originalScale, onScaling, scaleValue]);

    return (
      <Button
        dataSet={dataSet}
        style={buttonStyle}
        onPress={onPress}
        onHoverIn={handleScaleAnimation}
        onHoverOut={handleReset}
        isHoverable
        disabled={disabled}
        onLayout={onLayout}
      >
        <Animated.View
          style={[contentStyle, { transform: [{ scale: scaleValue }] }]}
        >
          {children}
        </Animated.View>
      </Button>
    );
  }
);

export default ScaleButtonAnimation;
