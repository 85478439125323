import React from 'react';
import { ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import StyleSheet from 'react-native-media-query';
import { Layout, Spinner } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import CartList from '../../../Components/Web/Cart/CartList';
import EmptyData from '../../../Components/EmptyData';
import { FilterCategory, FilterTag } from '../../../Components/Filter';
import Footer from '../../../Components/Footer';
import StoreCard from '../../../Components/Card/Store';
import StoreCardLoader from '../../../Components/Card/StoreLoader';

import useFavorite from '../../../Hooks/useFavorite';

//REDUX
import { whenFilterSelector } from '../../../RTK/filter/selectors';
import { userCartFetchingSelector } from '../../../RTK/utility/selectors';
import { isLoggedInSelector } from '../../../RTK/user/selectors';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle, {
  globalSpacing,
  webPaddingHorizontal,
} from '../../../Theme/styles';

import BreakPoints from '../../../Theme/styles/breakpoints';

import ListHeader from '../ListHeader';

import useHomeController from './controller/index.controller';
import OrderForLaterSection from '../OrderForLaterSection';

const IMAGE_SIZE = 50;

const Home = (props) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isUserCartLoading = useSelector(userCartFetchingSelector);
  const whenFilter = useSelector(whenFilterSelector);
  const {
    allStoreEnd,
    allStorePage,
    homeAllStores,
    homeClosedStores,
    homeSegments,
    isFilterLoading,
    isGettingAllStore,
    refreshing,
    showAdAndHorizontalList,
    isCloseToBottom,
    onCardPressed,
  } = useHomeController(props);
  const { disableFavourite, isSavedToFavourite, toggleFavourite } =
    useFavorite();

  const _renderSections = () => {
    return (
      <ListHeader
        isLoading={isFilterLoading}
        resultCount={homeAllStores.length}
        refreshing={refreshing}
        segments={homeSegments}
        showAdAndHorizontalList={showAdAndHorizontalList}
      />
    );
  };

  const _renderAllStore = (index, item) => {
    return (
      <Layout key={index} style={ThemeStyle.pageVerticalSpacingMedium}>
        {item.loading ? (
          <StoreCardLoader fullWidthOnMobile />
        ) : (
          <StoreCard
            {...item}
            isSaved={isSavedToFavourite(item.id)}
            disableSave={disableFavourite}
            onSave={toggleFavourite(item)}
            onPress={onCardPressed(item)}
            fullWidthOnMobile
          />
        )}
      </Layout>
    );
  };

  _renderEmpty = () => {
    if (isGettingAllStore) {
      return null;
    }
    return (
      <View style={ThemeStyle.pageBottomSpacer}>
        <EmptyData type="filter" />
      </View>
    );
  };

  const _renderFooter = () => {
    if (allStoreEnd && homeClosedStores.length) {
      // if infinite scroll is done and has closed store
      return (
        <View style={ThemeStyle.pageBottomSpacer}>
          <OrderForLaterSection
            data={homeClosedStores}
            hasOpenStore={Boolean(homeAllStores.length)}
            whenFilter={whenFilter?.value}
            isSaved={isSavedToFavourite}
            disableSave={disableFavourite}
            onSave={toggleFavourite}
            onPress={onCardPressed}
          />
        </View>
      );
    }
    return null;
  };

  return (
    <Layout style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRow]}>
      <ScrollView
        dataSet={{ media: ids.contentContainer }}
        contentContainerStyle={styles.contentContainer}
        persistentScrollbar
        showsVerticalScrollIndicator
        scrollEventThrottle={1500}
        onScroll={({ nativeEvent }) => isCloseToBottom(nativeEvent)}
      >
        <View>
          <View>
            <FilterCategory />
            <FilterTag
              isLoading={isFilterLoading}
              resultCount={homeAllStores.length}
            />
          </View>
          <View>{_renderSections()}</View>
          {!lodashIsEmpty(homeAllStores) ? (
            <View dataSet={{ media: ids.container }} style={styles.container}>
              {homeAllStores.map((item, index) => _renderAllStore(index, item))}
            </View>
          ) : (
            _renderEmpty()
          )}

          {isGettingAllStore && allStorePage >= 1 && (
            <View
              style={[
                ThemeStyle.flexDirectionRowCenterCenter,
                ThemeStyle.spacingBottom,
              ]}
            >
              <Spinner size="giant" />
            </View>
          )}

          <View>{_renderFooter()}</View>
        </View>
        <Footer />
      </ScrollView>
      <View
        dataSet={{ media: ids.cartListContainer }}
        style={styles.cartListContainer}
      >
        <CartList
          isCartLoading={isLoggedIn ? isUserCartLoading?.loading : false}
          isClickableHeader={true}
        />
      </View>
    </Layout>
  );
};

const { ids, styles } = StyleSheet.create({
  contentContainer: {
    ...ThemeStyle.pageBackground,
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: webPaddingHorizontal - 50,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingHorizontal: webPaddingHorizontal - 40,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingHorizontal: webPaddingHorizontal - 30,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingLeft: webPaddingHorizontal - 10,
      paddingRight: webPaddingHorizontal - 50,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingLeft: webPaddingHorizontal - 10,
      paddingRight: webPaddingHorizontal - 50,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingLeft: webPaddingHorizontal - 10,
      paddingRight: webPaddingHorizontal - 50,
    },
  },
  listItemContainer: {
    ...ThemeStyle.alignItemsCenter,
    padding: globalSpacing / 2,
  },
  contentCenter: {
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 50,
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    marginBottom: globalSpacing / 2,
  },
  container: {
    display: 'grid',
    ...ThemeStyle.pageHorizontalSpacingSmall,
    columnGap: 10,
    gridColumnGap: 10,

    [`@media ${BreakPoints.md}`]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [`@media ${BreakPoints.lg}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxl}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxxl}`]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  cartListContainer: {
    width: 450,
    paddingLeft: 20,
    shadowColor: ThemeColor.shadow,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.0,
    elevation: 24,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
});

export default Home;
