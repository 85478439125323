import React, { Fragment, useRef } from 'react';
import { View } from 'react-native';

import AddReviewOrDisputeModal from '../../../Components/AddReviewOrDisputeModal';
import Button from '../../../Components/Button';
import modals from '../../../Components/Sheets/modals';
import ScreenWrapper from '../../../Components/ScreenWrapperWithTitleAnimation';
import StatusFilter from '../../../Components/StatusFilter';
import { ScaleButtonAnimation } from '../../../Components/Web/Intereaction';
import constants from '../../../Config/constants';
import ThemeStyle from '../../../Theme/styles';

import useController from '../controller/OrderHistory.controller';

import OrderItem from './OrderItem';
import ItemLoader from './ItemLoader';

const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;
const clickableProps = constants.isWeb
  ? {
      buttonStyle: ThemeStyle.webPageWrapper,
      contentStyle: ThemeStyle.webPageContainer,
    }
  : { plain: true };

const statusOptions = [
  { label: 'Completed', value: 'completed' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Cancelled', value: 'cancelled' },
];
const OrderHistory = (props) => {
  const listRef = useRef();
  const {
    state: { filterStatus, reviewModalData, reviewModalShow },
    fetchOrderHistory,
    onFetchedOrderHistory,
    onItemPressed,
    onReviewNotNow,
    onReviewPress,
    onStatusChange,
    toggleWebOrderAgain,
  } = useController(listRef);

  const _showOrderAgain = (data) => () => {
    if (constants.isWeb) {
      toggleWebOrderAgain(data);
    } else {
      modals.show(modals.ORDER_AGAIN, data);
    }
  };

  const _renderHeader = () => (
    <View style={!constants.isWeb && ThemeStyle.spacingTop}>
      <StatusFilter
        type="orders"
        status={filterStatus}
        options={statusOptions}
        optionLabel="Select order status"
        onChange={(res) => onStatusChange(res.value)}
      />
    </View>
  );

  const _renderItem = ({ item }) => {
    if (item.loading) {
      return <ItemLoader />;
    }
    return (
      <ClickableComponent onPress={onItemPressed(item)} {...clickableProps}>
        <OrderItem onOrderAgain={_showOrderAgain(item)} {...item} />
      </ClickableComponent>
    );
  };

  const conditionalScreenWrapperProps = constants.isWeb
    ? {
        contentContainerStyle: {
          flexGrow: 1,
          ...ThemeStyle.pageBackground,
        },
      }
    : {};

  return (
    <Fragment>
      <ScreenWrapper
        onRef={(r) => (listRef.current = r)}
        title="Order History"
        initialData={[
          { loading: true },
          { loading: true },
          { loading: true },
          { loading: true },
        ]}
        renderItem={_renderItem}
        onRequest={fetchOrderHistory}
        onRequestEnd={onFetchedOrderHistory}
        ListHeaderComponent={_renderHeader}
        stickyHeaderIndices={[0]}
        infiniteScroll
        withSeparator
        {...conditionalScreenWrapperProps}
      />

      <AddReviewOrDisputeModal
        show={reviewModalShow}
        data={reviewModalData}
        onReview={onReviewPress}
        onNotNow={onReviewNotNow}
      />
    </Fragment>
  );
};

export default OrderHistory;
