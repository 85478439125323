import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useToast } from 'react-native-toast-notifications';
import { RAZZLE_BUILD_MODE } from '@env';
import { useTheme } from '@ui-kitten/components';
import { isEmpty } from 'lodash';

import Toast from '../Components/Toast';
import constants from '../Config/constants';
import CartHelper from '../Helper/Cart';
import { getCartTerm } from '../Helper/RemoteConfig';
import { updateCheckoutDetails } from '../RTK/checkout';
import {
  checkoutOrderTypeSelector,
  checkoutStorePromoSelector,
} from '../RTK/checkout/selectors';
import { checkout } from '../RTK/defaultValues';
import { setCheckoutData } from '../RTK/mealPlan';
import {
  mealPlanItemsSelector,
  mealPlanStorePromoSelector,
} from '../RTK/mealPlan/selectors';
import { selectCart } from '../RTK/cart/selectors';
import checkoutApi from '../Service/api/checkout';

function usePromoCart(params) {
  const id = params?.id; // store id
  const fetchPromo = params?.fetchPromo; // should fetch the promo
  const store_theme = useTheme();

  const dispatch = useDispatch();
  const toast = useToast();
  const storeId = id || useSelector((state) => state.shop.data?.id);
  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, storeId)
  );
  const mealPlanItems = useSelector(mealPlanItemsSelector);

  const isMealPlan = selectedOrderType === constants.ORDER_TYPES.MEAL_PLAN;
  const storePromoSelector = isMealPlan
    ? mealPlanStorePromoSelector
    : checkoutStorePromoSelector;

  const storePromo = useSelector((state) => storePromoSelector(state, storeId));
  const cartStore = useSelector((state) => selectCart(state, storeId));
  const cartSubtotal = CartHelper.getTotal(cartStore?.[0]?.items);
  const enableFetchPromo = fetchPromo
    ? Boolean(storePromo?.code) && cartSubtotal >= storePromo?.minimum_purchase
    : false;
  const { data, refetch: _refetchPromo } = useQuery({
    queryKey: [storePromo?.code, enableFetchPromo],
    queryFn: () => {
      const payload = {
        is_cod: false,
        order_type: selectedOrderType,
        promo_code: storePromo?.code,
        store_id: storeId,
      };
      if (isMealPlan) {
        payload.order_type = constants.ORDER_TYPES.DELIVERY;
        payload.items = mealPlanItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
          extras: item.extras?.map((ie) => ie.id) || [],
        }));
      }
      if (RAZZLE_BUILD_MODE === 'branded') {
        if (isMealPlan) {
          payload.items = mealPlanItems.map((item) => ({
            id: item.id,
            quantity: item.quantity,
            extras: item.extras?.map((ie) => ie.id) || [],
          }));
        } else {
          payload.items =
            cartStore?.[0]?.items.map(({ id, quantity, extras }) => ({
              id,
              quantity,
              extras: extras.map(({ id }) => id),
            })) || [];
        }
      }
      return checkoutApi.verifyPromoCode(payload);
    },
    enabled: enableFetchPromo,
    refetchOnWindowFocus: false,
  });
  const promotion = data?.ok ? data?.data : {};

  const _addPromoToCart = (promo) => {
    if (isMealPlan) {
      dispatch(
        setCheckoutData({
          key: checkout.keys.PROMO_STORE,
          data: promo,
        })
      );
    } else {
      dispatch(
        updateCheckoutDetails({
          store_id: storeId,
          keyToUpdate: checkout.keys.PROMO_STORE,
          keyValue: promo,
        })
      );
    }
    const message = `Promo has been added to ${getCartTerm()}`;
    if (constants.isWeb) {
      toast.show(message, {
        type: 'success',
        placement: 'top',
        duration: 2000,
        ...(!isEmpty(store_theme['icon_color']) && {
          style: {
            backgroundColor: store_theme['icon_color'],
          },
        }),
      });
    } else {
      Toast.show(message);
    }
  };

  const _onPromoRemove = () => {
    if (isMealPlan) {
      dispatch(
        setCheckoutData({
          key: checkout.keys.PROMO_STORE,
          data: undefined,
        })
      );
    } else {
      dispatch(
        updateCheckoutDetails({
          store_id: storeId,
          keyToUpdate: checkout.keys.PROMO_STORE,
          keyValue: undefined,
        })
      );
    }
  };

  return {
    promotion,
    promotionMinimum:
      promotion?.minimum_purchase || storePromo?.minimum_purchase || 0,
    storePromo,
    addPromoToCart: _addPromoToCart,
    onPromoRemove: _onPromoRemove,
    refetchPromo: _refetchPromo,
  };
}

export default usePromoCart;
