import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';

import {
  isGuestSelector,
  isLoggedInSelector,
  userSelector,
} from '../RTK/user/selectors';

import { SavedAddresses, AddEditAddress, AddressMap } from '../Screens/Address';
import StoreInformation from '../Screens/StoreInformation';
import Product from '../Screens/Product';
import ProductSpecialInstruction from '../Screens/Product/SpecialInstruction';
import Cart from '../Screens/Cart';
import CheckoutUserDetails from '../Screens/Checkout/UserDetails';
import OrderTracking from '../Screens/OrderTracking';
import OrderDetails from '../Screens/OrderDetails';

import Account, {
  Profile,
  PaymentMethod,
  AddPayment,
  Notification,
  GiftCard,
  ReferAndEarn,
  ChoosePlan,
  GetHelp,
  Legal,
  LegalBindingContract,
} from '../Screens/Account';

import routeList from './list';
import DrawerNavigator from './DrawerNavigator';

import useSession from '../Hooks/useSession';
import useShouldCallApi from '../Hooks/useShouldCallApi';

import { setAsLoggedIn, logout } from '../RTK/user';
import Cookie from '../Service/Cookie';

import CheckoutWebview from '../Screens/Checkout/CheckoutWebview';

import ResetPassword from '../Screens/DeepLinkingLanding/ResetPassword';
import SignupVerificationSuccess from '../Screens/DeepLinkingLanding/SignupVerificationSuccess';
import { FullReviewList } from '../Screens/StoreReviews';
import CheckoutNavigator from './CheckoutNavigator';
import RootNavigatorBase from './RootNavigatorBase';
import Search from '../Screens/Search';

const Stack = createStackNavigator();

function restrictedRoute() {
  return (
    <>
      <Stack.Screen name={routeList.HOME} component={DrawerNavigator} />
      {/* App Navigator*/}
      <Stack.Screen name={routeList.SEARCH} component={Search} />
      <Stack.Screen
        name={routeList.STORE_INFORMATION}
        component={StoreInformation}
      />
      <Stack.Screen name={routeList.PRODUCT} component={Product} />
      <Stack.Screen
        name={routeList.PRODUCT_SPECIAL_INSTRUCTION}
        component={ProductSpecialInstruction}
      />
      <Stack.Screen name={routeList.CART} component={Cart} />
      <Stack.Screen
        name={routeList.CHECKOUT_NAVIGATOR}
        component={CheckoutNavigator}
      />
      <Stack.Screen
        name={routeList.CHECKOUT_WEBVIEW}
        component={CheckoutWebview}
      />
      <Stack.Screen
        name={routeList.CHECKOUT_USER_DETAILS}
        component={CheckoutUserDetails}
      />
      <Stack.Screen name={routeList.ORDER_TRACKING} component={OrderTracking} />
      <Stack.Screen name={routeList.ORDER_RATING} component={OrderDetails} />
      <Stack.Screen name={routeList.ORDER_DETAILS} component={OrderDetails} />
      <Stack.Screen name={routeList.STORE_REVIEWS} component={FullReviewList} />

      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen
          name={routeList.ADDRESS_SELECTOR}
          component={SavedAddresses}
        />
        <Stack.Screen
          name={routeList.ADDRESS_ADD_EDIT}
          component={AddEditAddress}
        />
        <Stack.Screen name={routeList.ADDRESS_MAP} component={AddressMap} />
      </Stack.Group>

      {/* Profile Screen */}
      <Stack.Screen name={routeList.ACCOUNT} component={Account} />
      <Stack.Screen name={routeList.PROFILE} component={Profile} />
      <Stack.Screen name={routeList.PAYMENT_METHOD} component={PaymentMethod} />
      <Stack.Screen name={routeList.ADD_PAYMENT} component={AddPayment} />
      <Stack.Screen name={routeList.NOTIFICATION} component={Notification} />
      <Stack.Screen name={routeList.GIFT_CARD} component={GiftCard} />
      <Stack.Screen name={routeList.REFER_AND_EARN} component={ReferAndEarn} />
      <Stack.Screen name={routeList.CHOOSE_PLAN} component={ChoosePlan} />
      <Stack.Screen name={routeList.GET_HELP} component={GetHelp} />
    </>
  );
}

function AuthNavigator() {
  const dispatch = useDispatch();

  const shouldCallApi = useShouldCallApi();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const isGuest = useSelector(isGuestSelector);

  const user = useSelector(userSelector);

  useSession(); // to run the session renewal

  useEffect(() => {
    _checkSession();
  }, [isLoggedIn]);

  const _checkSession = async () => {
    // we can use shouldCallApi for checking the cookie logic
    // since we not gonna use cookie if we are not gonna call api
    // it also match the condition of "we will not use cookie session logic if we are not logged in via email and password"
    if (shouldCallApi) {
      // this logic only apply if user is logged in via email and password
      const cookie = await Cookie.get();
      if (!lodashIsEmpty(cookie)) {
        // if has cookie set as logged in
        await dispatch(setAsLoggedIn());
      } else if (isLoggedIn) {
        // if already logged in and cookie is removed, logout
        await dispatch(logout()); // to clear data
      } else if (user) {
        // in case logout but has user info
        await dispatch(logout()); // to clear data
      }
    } else {
      // this logic for guest and logged in via social
      if (!lodashIsEmpty(user?.user)) {
        await dispatch(setAsLoggedIn());
      } else if (isLoggedIn && !isGuest) {
        await dispatch(logout()); // to clear data
      }
    }
  };

  return (
    <RootNavigatorBase>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {restrictedRoute()}
        <Stack.Screen name={routeList.LEGAL} component={Legal} />
        <Stack.Screen
          name={routeList.LEGAL_BINDING_CONTRACT}
          component={LegalBindingContract}
        />
        {/* For deep linking that can access even user is logged in */}
        <Stack.Screen
          name={routeList.RESET_PASSWORD}
          component={ResetPassword}
        />
        <Stack.Screen
          name={routeList.SIGNUP_VERIFICATION_SUCCESS}
          component={SignupVerificationSuccess}
        />
      </Stack.Navigator>
    </RootNavigatorBase>
  );
}

export default AuthNavigator;
