import React, { useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Divider, Layout, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import Form from '../../../FormFields/Form';
import GooglePlaces_Autocomplete from '../../../Map/GooglePlacesAutocomplete';
import MapView from '../../../Map';
import SubmitButton from '../../../FormFields/SubmitButton';

import validation from '../../../../Config/validationRules';

import BreakPoints from '../../../../Theme/styles/breakpoints';
import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';

import useController from '../../../../Screens/Address/controller/addEditAddress';

import AddLabelForm from '../../../../Screens/Address/AddLabelForm';

import ModalPrompt from './ModalPrompt';
import { MODALPROMPT } from './config';
import useModalPrompt from './hooks/useModalPrompt';

let thisFormInitialValues = {};
const validationSchema = validation.createSchema({
  label: validation.fieldRequired('Address label'),
});
const formDefaultValues = { label: null };

const AddressDetails = ({ modalId }) => {
  const [data, setData] = useState<{
    selectedAddress: string;
    status: string;
    type?: string;
  }>();

  const status = data?.status;

  const {
    state,
    autoCompleteRef,
    onSearchItemPressed,
    onSubmit,
    formRef,
    formInitialValues,
    onRegionChangeComplete,
  } = useController(data || { navigation: '', route: {}, selectedAddress: {} });

  const { hideModalPrompt } = useModalPrompt();
  const { selectedAddress, submitting, existingError } = state;
  const location = {
    lat: selectedAddress?.latitude || 0,
    lng: selectedAddress?.longitude || 0,
  };

  if (formInitialValues) {
    thisFormInitialValues = formInitialValues;
  } else {
    thisFormInitialValues = formDefaultValues;
  }

  const onSubmitChanges = async (...args) => {
    const center = {
      latitude: location.lat,
      longitude: location.lng,
    };

    await onSubmit(center, ...args);
  };
  const onClose = () => hideModalPrompt(modalId, null);

  return (
    <ModalPrompt
      id={modalId}
      title={status === 'new' ? 'Add New Address' : 'Edit Address'}
      dataSet={{ media: ids.addressDetailPopupModal }}
      mainContentWrapperStyle={styles.addressDetailPopupModal}
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      onBeforeShow={setData}
      onCloseButtonClick={onClose}
    >
      <Layout style={[ThemeStyle.flex1]}>
        <Form
          innerRef={formRef}
          initialValues={thisFormInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitChanges}
        >
          <AddLabelForm existingError={existingError} />

          <Divider style={ThemeStyle.divider} />

          <View style={ThemeStyle.flex2}>
            {/* Map Container */}

            <Layout style={ThemeStyle.spacingTopMedium}>
              {/* Search suggestion container */}
              <Layout style={styles.searchContainer}>
                <GooglePlaces_Autocomplete
                  testID="gpautocomplete"
                  ref={!submitting ? autoCompleteRef : null}
                  initialValue={selectedAddress?.address}
                  onSelected={onSearchItemPressed}
                  style={styles.googleplace_autocomplete}
                />

                <Divider style={ThemeStyle.divider} />
              </Layout>
            </Layout>
            {!lodashIsEmpty(selectedAddress) && (
              <View style={ThemeStyle.flex1}>
                <View style={styles.mapContainer}>
                  <MapView
                    testID="mapViewAddEdit"
                    region={location}
                    onRegionChanged={onRegionChangeComplete}
                  />
                </View>
              </View>
            )}
          </View>
          <View
            style={[
              ThemeStyle.spacingTop,
              ThemeStyle.spacingBottomMedium,
              ThemeStyle.flex3,
              ThemeStyle.alignItemsCenter,
            ]}
          >
            <SubmitButton
              size="medium"
              style={styles.saveButton}
              disabled={lodashIsEmpty(selectedAddress.address)}
              customLoader
            >
              <View>
                {submitting ? (
                  <ActivityIndicator size="large" color={ThemeColor.white} />
                ) : (
                  <Text
                    status={
                      lodashIsEmpty(selectedAddress.address)
                        ? 'primary'
                        : 'control'
                    }
                    category="c1"
                  >
                    Confirm location and details
                  </Text>
                )}
              </View>
            </SubmitButton>
          </View>
        </Form>
      </Layout>
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  searchContainer: {
    flex: 1,
    zIndex: 1,
    left: 0,
    right: 0,
  },
  saveButton: {
    borderRadius: 5,
  },

  mapContainer: {
    marginTop: 20,
    borderRadius: 10,
    alignSelf: 'center',
    width: '100%',
    height: 400,
  },
  googleplace_autocomplete: {
    ...ThemeStyle.regular,
    fontSize: 12,
  },
  addressDetailPopupModal: {
    [`@media ${BreakPoints.xs}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 450,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 500,
    },
  },
});

export { formDefaultValues };
export default React.memo(AddressDetails);
