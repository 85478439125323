import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';
import StyleSheet from 'react-native-media-query';
import Tooltip from 'rn-tooltip';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../../../Config/constants';

import BreakPoints from '../../../../Theme/styles/breakpoints';
import ThemeStyle, { globalSpacing } from '../../../../Theme/styles';
import ThemeColor from '../../../../Theme/colors';

import Button from '../../../Button';
import Image from '../../../Image';
import ListItem from '../../../ListItem';
import ModalPrompt from './ModalPrompt';

import ImageHelper from '../../../../Helper/Image';

import RateDelivery from '../../../../Screens/OrderRating/RateDelivery';
import RateStore from '../../../../Screens/OrderRating/RateStore';
import RatingItem from '../../../../Screens/OrderRating/RatingItem';
import TextInput from '../../../TextInput';

import useOrderRating from '../../../../Hooks/useOrderRating';

import { MODALPROMPT } from './config';
import PageLoader from '../../../../Screens/OrderRating/PageLoader';
import ErrorHint from '../../../ErrorHint';

import { SECTION_TITLE_LIGHT } from '../../../../Theme/typographyProps';

const { ORDER_TYPES } = constants;

type Data = {
  id?: string;
  defaultStar?: number;
};

const StoreRatings = () => {
  const { user } = useSelector((state: any) => state.user);
  const [data, setData] = useState<Data>();

  const {
    refs: { scrollList, publicReviewRef },
    state,
    lowestStarRatings,
    onDeliveryFeedbackChange,
    onGoBack,
    onItemReaction,
    onRatingChange,
    onStoreFeedbackChange,
    onSubmitPressed,
    setCustomFeedbackEnchancement,
    setErrorRateDelivery,
    setErrorRateStore,
    setErrorEnchanceExperience,
  } = useOrderRating({
    defaultDeliveryStar: data?.defaultStar,
    defaultStoreStar: data?.defaultStar,
    transaction_number: data?.id,
  });
  const image = ImageHelper.getOrderImage(state.details?.store_details);
  const isDefaultPickupImage = ImageHelper.isPickupDefaultBanner(image);
  const customSuggestion = state.isUpdate
    ? !lodashIsEmpty(state.customFeedbackEnchancement)
    : lowestStarRatings;
  const _renderFooter = () => {
    return (
      <View
        style={[
          ThemeStyle.pageVerticalSpacingMedium,
          ThemeStyle.alignItemsCenter,
        ]}
      >
        <View style={styles.buttonContainer}>
          <Button
            status={state.isFailedToRetrieveReview ? 'danger' : 'success'}
            onPress={
              state.isFailedToRetrieveReview ? onGoBack : onSubmitPressed
            }
            loading={state.submitting}
          >
            {state.isFailedToRetrieveReview
              ? 'Back'
              : state.isUpdate
              ? 'Update Review'
              : 'Submit Review'}
          </Button>
        </View>
      </View>
    );
  };

  return (
    <ModalPrompt
      id={MODALPROMPT.storeRatingsModal}
      title={`${state.isUpdate ? 'Update' : 'Add'} Ratings`}
      onBeforeShow={setData}
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      dataSet={{ media: ids.reviewPopupModal }}
      mainContentWrapperStyle={styles.reviewPopupModal}
      childContentContainerStyle={{ paddingHorizontal: 0 }}
      scrollEnabled={false}
    >
      {!state.isLoading ? (
        <ScrollView
          ref={scrollList}
          style={styles.wrapper}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <ListItem
            title={state.details?.store_details?.name}
            subTitle={dayjs(
              `${state.details?.order_date} ${state.details?.completed_time}`
            ).format(constants.DATE_DISPLAY_FORMAT)}
            accessoryRight={
              <View
                style={[
                  ThemeStyle.alignItemsCenter,
                  { backgroundColor: ThemeColor.green },
                ]}
              >
                <Image
                  source={{ uri: image }}
                  style={styles.storeImage}
                  resizeMode="contain"
                />
              </View>
            }
          />

          {state.details?.order_type === ORDER_TYPES.DELIVERY && (
            <ErrorHint
              name="rateDelivery"
              errorText="You need to rate your delivery experience"
              show={state.errorRateDelivery}
              scrollReference={scrollList}
              onClose={setErrorRateDelivery}
            >
              <RateDelivery
                starValue={state.deliveryRating}
                defaultFeedbacks={state.deliveryFeedback}
                onStarChange={onRatingChange(true)}
                onFeedbackChange={onDeliveryFeedbackChange}
              />
            </ErrorHint>
          )}

          <ErrorHint
            name="rateStore"
            errorText="You need to rate the store services"
            show={state.errorRateStore}
            scrollReference={scrollList}
            onClose={setErrorRateStore}
          >
            <RateStore
              storeName={state?.details?.store_details.name}
              userName={`${user?.first_name} ${user?.last_name?.[0]}`}
              starValue={state.storeRating}
              defaultFeedbacks={state.storeFeedback}
              onStarChange={onRatingChange(false)}
              onFeedbackChange={onStoreFeedbackChange}
            />
          </ErrorHint>
          {customSuggestion && (
            <ErrorHint
              name="enhanceExperience"
              errorText="Kindly review your highlights for a better customer experience"
              show={state.errorEnchanceExperience}
              scrollReference={scrollList}
              onClose={setErrorEnchanceExperience}
              height={70}
            >
              <View style={ThemeStyle.spacingTop}>
                <Text>What areas can be improved?</Text>
                <View style={ThemeStyle.spacingTop}>
                  <TextInput
                    value={state.customFeedbackEnchancement}
                    onChangeText={setCustomFeedbackEnchancement}
                    placeholder={
                      'Could you share more insights so we can enhance your experience?'
                    }
                    disabled={state.isUpdate}
                    direction="column"
                    maxLength={500}
                    textarea
                    showCharacterUsage
                  />
                </View>
              </View>
            </ErrorHint>
          )}

          <View style={[ThemeStyle.spacingBottom]}>
            <Divider style={ThemeStyle.divider} />
          </View>

          <View
            style={[
              ThemeStyle.spacingLeftSmall,
              ThemeStyle.flexDirectionRowCenterSpaceBetween,
            ]}
          >
            <Text
              {...SECTION_TITLE_LIGHT}
            >{`${user?.first_name} ${user?.last_name?.[0]}`}</Text>

            <Button
              onPress={() => publicReviewRef.current.toggleTooltip()}
              plain
            >
              <View
                style={[
                  ThemeStyle.flexDirectionRowCenter,
                  ThemeStyle.buttonContentGraySmall,
                ]}
              >
                <Text {...SECTION_TITLE_LIGHT}>Review</Text>
                <Tooltip
                  ref={publicReviewRef}
                  actionType="press"
                  backgroundColor={ThemeColor.green}
                  width={225}
                  height={50}
                  withOverlay={false}
                  containerStyle={styles.tooltipContainer}
                  popover={
                    <Text category="p2" appearance="alternative">
                      Customer and merchants will be able to see your review.
                    </Text>
                  }
                >
                  <Icon
                    name="info-outline"
                    fill={ThemeColor.green}
                    style={[styles.iconStyle, ThemeStyle.spacingLeftSmall]}
                  />
                </Tooltip>
              </View>
            </Button>
          </View>

          <RatingItem
            items={state.details.items}
            reactions={state.itemsReaction}
            onItemReaction={onItemReaction}
          />

          {_renderFooter()}
        </ScrollView>
      ) : (
        <View style={ThemeStyle.pageBackground}>
          <PageLoader />
        </View>
      )}
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 20,
    height: 530,
  },
  storeImage: {
    width: 50,
    height: 50,
  },
  buttonContainer: { width: 300 },
  tooltipContainer: {
    ...ThemeStyle.alignItemsStart,
    borderRadius: 5,
    padding: globalSpacing / 4,
  },
  iconStyle: {
    width: 15,
    height: 15,
  },
  reviewPopupModal: {
    paddingBottom: 40,
    [`@media ${BreakPoints.xs}`]: {
      width: 400,
      height: 600,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 450,
      height: 600,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 500,
      height: 600,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 550,
      height: 600,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 600,
      height: 600,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 600,
      height: 600,
    },
  },
});
export default StoreRatings;
