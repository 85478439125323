import React, { useRef } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Button from '../../Components/Button';
import EmptyData from '../../Components/EmptyData';
import List from '../../Components/List';
import ScaleButtonAnimation from '../../Components/Web/Intereaction/ScaleButtonAnimation';
import constants from '../../Config/constants';
import routeList from '../../Routes/list';
import ThemeStyle from '../../Theme/styles';

import Header from './Header';
import ItemOnGoing from './ItemOnGoing';
import ItemOnGoingLoader from './ItemOnGoingLoader';
import useOrderController from './index.controller';

const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;
const clickableProps = constants.isWeb
  ? {
      buttonStyle: ThemeStyle.webPageWrapper,
      contentStyle: ThemeStyle.webPageContainer,
    }
  : { plain: true };

const Orders = () => {
  const listRef = useRef();
  const navigation = useNavigation();
  const {
    data,
    error,
    highlightedOrderId,
    isRefreshing,
    showDetailsOrderId,
    onRefresh,
    onShare,
    onStoreNamePressed,
    onViewOrderPressed,
    toggleShowDetails,
  } = useOrderController();

  const _onHistoryPress = () => {
    navigation.navigate(routeList.ORDER_HISTORY);
  };

  const _renderHeader = () => <Header onHistoryPress={_onHistoryPress} />;

  const _renderEmpty = () => (
    <View
      style={[
        ThemeStyle.pageVerticalSpacing,
        ThemeStyle.pageHorizontalSpacing,
        ThemeStyle.alignItemsCenter,
      ]}
    >
      <EmptyData
        title={error ? 'Error' : 'No orders found'}
        subTitle={error}
        type={error ? 'favorites' : 'cart'}
      />
    </View>
  );

  const _renderItem = ({ item, index }) => {
    if (item.loading) {
      return <ItemOnGoingLoader />;
    }

    const isFirstHighlight = index === 0 && highlightedOrderId === 'first';
    return (
      <ClickableComponent
        onPress={toggleShowDetails(item)}
        href={constants.isWeb && `/store?id=${item?.id}`}
        {...clickableProps}
      >
        <ItemOnGoing
          {...item}
          isActive={isFirstHighlight || highlightedOrderId === item.id}
          showDetails={showDetailsOrderId === item.id}
          onShare={onShare(item.tracking_page)}
          onStoreNamePress={onStoreNamePressed(item)}
          onViewOrderPress={onViewOrderPressed(item)}
        />
      </ClickableComponent>
    );
  };

  return (
    <List
      ref={listRef}
      data={data}
      listKey="order-history"
      ListEmptyComponent={_renderEmpty}
      ListHeaderComponent={!constants.isWeb && _renderHeader}
      renderItem={_renderItem}
      pullToRefreshProps={{ onRefresh, refreshing: isRefreshing }}
      withSeparator
    />
  );
};

export default Orders;
