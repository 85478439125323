import { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { CANCEL_ERROR, CancelToken } from 'apisauce';
import {
  find as lodashFind,
  isEmpty as lodashIsEmpty,
  isEqual as lodashIsEqual,
  pick as lodashPick,
} from 'lodash';

import constants from '../../../Config/constants';
import messages from '../../../Config/messages';
import { isUserDetailsIncomplete } from '../../../Helper';
import CheckoutHelper from '../../../Helper/Checkout';
import Sentry from '../../../Helper/Sentry';
import useGetCurrentRoute from '../../../Hooks/useGetCurrentRoute';
import useToast from '../../../Hooks/useToast';

import { checkout } from '../../../RTK/defaultValues';
import { shopSelector } from '../../../RTK/shop/selectors';
import routeList from '../../../Routes/list';
import Service from '../../../Service';
import checkoutApi from '../../../Service/api/checkout';

import { paymentList } from '../PaymentOptions';
import { vehicleList } from '../VehicleOptions';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

const deliveryDefaultValue = {
  fee: undefined,
  priority_fee: 0,
  loading: false,
  error: '',
  mode: constants.DELIVERY_PAYMENT_OPTION.NON_CASH,
  codFreeDel: false,
};

function useCheckout(
  storeId,
  deliveryPaymentMethods,
  cartItems,
  checkoutData,
  dispatchCheckoutData,
  mealPlanType
) {
  const isNotOnload = useRef(false);
  const delQuotationCancelSource = useRef();
  const scrollRef = useRef();
  const navigation = useNavigation();
  const toast = useToast();
  const { showModalPrompt } = useModalPrompt();
  const currentScreen = useGetCurrentRoute();
  const user = useSelector((state) => state.user.user);
  const savedPayment = useSelector(
    (state) => state.utility.settings.savedPayment
  );
  const storeMealPlanDiscount = useSelector(
    (state) => shopSelector(state)?.meal_plan_discount
  );
  const storeMealPlanDeliveryDiscount = useSelector(
    (state) => shopSelector(state)?.free_delivery_amount
  );

  const [deliveryQuotation, setDeliveryQuotation] =
    useState(deliveryDefaultValue);
  const [detailsRequiredPrompt, setDetailsRequiredPrompt] = useState(false);
  const [detailsYAxis, _] = useState(0);

  const hasBulk = !lodashIsEmpty(lodashFind(cartItems, { is_bulk: true }));
  const isMealPlan = !lodashIsEmpty(mealPlanType); // if not empty it means user is in meal plan checkout
  const userDetails = checkoutData[checkout.keys.USER_DETAILS] || {};
  // call _getDeliveryQuotation every time checkout data change but dont call onload
  useEffect(() => {
    if (isNotOnload.current && !lodashIsEmpty(checkoutData)) {
      _getDeliveryQuotation();
    }
  }, [
    // no need to add dependency for schedule and order type because
    // on load upon setting default form value quotation should be called
    checkoutData[checkout.keys.DELIVERY_ADDRESS],
    checkoutData[checkout.keys.DELIVERY_SCHEDULE],
    checkoutData[checkout.keys.PAYMENT_OPTIONS],
    checkoutData[checkout.keys.VEHICLE_OPTIONS],
  ]);

  // call when user details is changed (if user profile is not yet complete and user put their details on the
  // checkout user details page)
  useEffect(() => {
    if (isNotOnload.current) {
      setDetailsRequiredPrompt(false); // removed prompt
    }
  }, [checkoutData[checkout.keys.USER_DETAILS]]);

  // flag, last use effect to run the first 2 useEffect first before this
  useEffect(() => {
    isNotOnload.current = true;
    _setDefaultValues(); // set checkout default values (common form for normal and meal plan checkout)
    return () => {
      if (!Boolean(checkoutData[checkout.keys.VEHICLE_OPTIONS])) {
        dispatchCheckoutData(checkout.keys.VEHICLE_OPTIONS, undefined);
      }
    };
  }, []);

  const _getDeliveryQuotation = async () => {
    const isNotCheckout =
      currentScreen !== routeList.CHECKOUT &&
      currentScreen !== routeList.CHECKOUT_MEAL_PLAN;

    if (isNotCheckout) {
      return; // do nothing if gets called if user is not in checkout page
    }
    const {
      DELIVERY_ADDRESS,
      DELIVERY_SCHEDULE,
      PAYMENT_OPTIONS,
      VEHICLE_OPTIONS,
    } = checkout.keys;
    // reset delivery quotation state and make loading as true
    setDeliveryQuotation({ ...deliveryDefaultValue, loading: true });
    // payload construction
    const deliveryAddress = checkoutData[DELIVERY_ADDRESS];
    const payload = {
      store_id: storeId,
      delivery_payment_method: checkoutData[PAYMENT_OPTIONS]?.value,
      delivery_vehicle: checkoutData[VEHICLE_OPTIONS]?.value,
      order_date: checkoutData[DELIVERY_SCHEDULE]?.date || undefined, // in case empty string, if undefined it will automatically remove it self from payload
      order_time: checkoutData[DELIVERY_SCHEDULE]?.time || undefined, // in case empty string, if undefined it will automatically remove it self from payload
      meal_plan_type: mealPlanType || undefined, // in case empty string, if undefined it will automatically remove it self from payload
      address: {
        lat: deliveryAddress?.geo_json_point.coordinates[1],
        lng: deliveryAddress?.geo_json_point.coordinates[0],
        address_string: deliveryAddress?.formatted_address,
      },
    };
    // api request
    delQuotationCancelSource.current?.cancel(); // cancel previous pending request
    delQuotationCancelSource.current = CancelToken.source();
    const { ok, data, problem } = await checkoutApi.getDeliveryFeeQuotation(
      payload,
      delQuotationCancelSource.current.token
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    const stateToUpdate = {
      ...deliveryDefaultValue,
      mode: payload.delivery_payment_method,
    };
    // handling api result
    if (ok) {
      // update only the delivery fee
      if (data?.selected?.is_success) {
        const { selected } = data;
        const mealPlanDeliveryFeePromo = isMealPlan
          ? storeMealPlanDeliveryDiscount
          : 0;
        const totalFreeDelivery = mealPlanDeliveryFeePromo * cartItems.length;
        const actualTotalDeliveryFee =
          totalFreeDelivery >= selected.delivery_fee
            ? 0
            : selected.delivery_fee - totalFreeDelivery;
        stateToUpdate.fee = actualTotalDeliveryFee;
        stateToUpdate.priority_fee = selected.priority_fee;
      } else {
        stateToUpdate.error =
          data?.selected?.message?.errors?.[0]?.message ||
          Service.handleErrorMessage(
            data?.selected?.message || messages.COMMON_ERROR_MESSAGE
          );
        if (constants.isWeb) {
          showModalPrompt(MODALPROMPT.errorModal, {
            title: 'Error',
            message:
              data?.selected?.message?.errors?.[0]?.message ||
              Service.handleErrorMessage(
                data?.selected?.message || messages.COMMON_ERROR_MESSAGE
              ),
          });
        }
      }
    } else {
      const errMsg = data?.message?.[0] || 'Quotation Error';
      Sentry.reportError('Error getting delivery quotation', data);
      stateToUpdate.error = Service.handleErrorMessage(errMsg);
      if (
        errMsg === 'address - store maximum delivery distance limit exceeded'
      ) {
        if (constants.isWeb) {
          showModalPrompt(MODALPROMPT.errorModal, {
            modalTitle: 'Oopss! Out of delivery zone',
            message:
              'The address you have selected is outside the delivery range. Please select a different address or try with our other stores.',
          });
        }
        Alert.alert(
          'Delivery to the selected address is not allowed',
          'The address you have selected is outside the delivery range. Please select a different address or try with our other stores.'
        );
      } else if (errMsg?.includes?.('ERR_UNKNOWN')) {
        // error message for third party delivery quotation error
        if (constants.isWeb) {
          showModalPrompt(MODALPROMPT.errorModal, {
            modalTitle: 'Delivery Quotation Unavailable',
            message:
              'We regret to inform you that there is an issue retrieving the third-party delivery quotation at the moment. Please try again later or contact customer support for assistance.',
          });
        }
        Alert.alert(
          'Delivery Quotation Unavailable',
          'We regret to inform you that there is an issue retrieving the third-party delivery quotation at the moment. Please try again later or contact customer support for assistance.'
        );
      } else {
        // Generic error message for failed delivery quotation
        if (constants.isWeb) {
          showModalPrompt(MODALPROMPT.errorModal, {
            modalTitle: 'Delivery Quotation Unavailable',
            message:
              'We regret to inform you that there is an issue with retrieving the delivery quotation. Please retry or contact customer support for assistance.',
          });
        }
        Alert.alert(
          'Delivery Quotation Unavailable',
          'We regret to inform you that there is an issue with retrieving the delivery quotation. Please retry or contact customer support for assistance.'
        );
      }
    }
    setDeliveryQuotation(stateToUpdate);
  };

  const _goToUserDetailsForm = () => {
    navigation.navigate(routeList.CHECKOUT_USER_DETAILS, {
      isFromMealPlan: isMealPlan,
    });
  };

  const _onPaymentCardChange = (name) => (value) => {
    const existingData = JSON.parse(
      JSON.stringify(checkoutData?.[checkout.keys.PAYMENT_METHOD])
    );
    existingData[name] = value;
    dispatchCheckoutData(checkout.keys.PAYMENT_METHOD, existingData);
  };

  const _onPaymentMethodChange = (value) => {
    dispatchCheckoutData(checkout.keys.PAYMENT_METHOD, { method: value });
  };

  const _setDefaultValues = async () => {
    const {
      DELIVERY_ADDRESS,
      PAYMENT_METHOD,
      PAYMENT_OPTIONS,
      USER_DETAILS,
      VEHICLE_OPTIONS,
    } = checkout.keys;
    // ===== set default payment method =====
    const defaultPaymentMethod = savedPayment.filter((p) => p.default);
    if (
      lodashIsEmpty(checkoutData[PAYMENT_METHOD]) &&
      !lodashIsEmpty(defaultPaymentMethod)
    ) {
      await dispatchCheckoutData(PAYMENT_METHOD, defaultPaymentMethod[0]);
    }
    // ===== set default user details =====
    const checkoutUserDetails = checkoutData[USER_DETAILS];
    const isUserDetailsUpdated = !lodashIsEqual(
      lodashPick(user, ['first_name', 'last_name', 'phone']),
      {
        first_name: checkoutUserDetails?.firstName,
        last_name: checkoutUserDetails?.lastName,
        phone: checkoutUserDetails?.phone,
      }
    );
    if (
      lodashIsEmpty(checkoutUserDetails) ||
      isUserDetailsIncomplete(checkoutUserDetails, true) ||
      isUserDetailsUpdated
    ) {
      await dispatchCheckoutData(USER_DETAILS, {
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        phone: user.phone || '',
        email: user.email || '',
      });
    }
    // ===== set default selected address using the first user addressed (its an active address/default) =====
    const isPreviousAddressDeleted = !user.addresses
      .map((e) => e.place_id)
      .includes(checkoutData[DELIVERY_ADDRESS]?.place_id);
    if (
      lodashIsEmpty(checkoutData[DELIVERY_ADDRESS]) || // if no checkout delivery address yet OR
      isPreviousAddressDeleted // previous selected address is deleted on user addresses
    ) {
      await dispatchCheckoutData(DELIVERY_ADDRESS, user.addresses[0]);
    }
    // ===== set default delivery payment options =====
    if (lodashIsEmpty(checkoutData[PAYMENT_OPTIONS])) {
      // if no payment option is selected yet, auto select non_cash if has, but if only 1, auto select it
      const availableOption = lodashFind(paymentList, {
        value:
          deliveryPaymentMethods.length === 1
            ? deliveryPaymentMethods[0]
            : constants.DELIVERY_PAYMENT_OPTION.NON_CASH,
      });
      await dispatchCheckoutData(PAYMENT_OPTIONS, availableOption);
    }
    // ===== set default vehicle option =====
    const noVehicleSelected = lodashIsEmpty(checkoutData[VEHICLE_OPTIONS]);
    const isCarSelected = lodashIsEqual(
      vehicleList[0],
      checkoutData[VEHICLE_OPTIONS]
    );
    if (hasBulk && !isCarSelected) {
      // if has bulky item and car is not yet selected, select car
      await dispatchCheckoutData(VEHICLE_OPTIONS, vehicleList[0]);
    } else if (!hasBulk && (noVehicleSelected || isCarSelected)) {
      // if has no bulky item and no vehicle is selected yet or car is selected, select motorcycle
      await dispatchCheckoutData(VEHICLE_OPTIONS, vehicleList[1]);
    }
  };

  const _validateForm = async (requiredData) => {
    let success = true;
    for (let data of requiredData) {
      const cdata = checkoutData[checkout.keys[data.key]]; // cdata = checkout data
      const isUserDetails = data.key === 'USER_DETAILS';
      const isCard = data.key === 'PAYMENT_METHOD' && cdata?.method === 'card';
      const inCompleteUserDetails = isUserDetails
        ? isUserDetailsIncomplete(cdata, true)
        : false;
      const isEmpty = lodashIsEmpty(cdata);
      const hasValidationSchema = !lodashIsEmpty(data.validationSchema);
      let title = '';
      let message = '';

      if (isEmpty || inCompleteUserDetails) {
        title = 'Information Required';
        message = data.message || `${data.label} is required.`;
        if (inCompleteUserDetails) {
          setDetailsRequiredPrompt(true);
          scrollRef.current?.scrollTo({ y: 0, animated: true });
        }
      } else if (hasValidationSchema) {
        // has validation schema from Yup, run it
        await new Promise((resolve) => {
          data.validationSchema
            .validate(data.getValidationPayload(cdata))
            .then(() => resolve())
            .catch((err) => {
              title = 'Information Required';
              message = err.message;
              resolve();
            });
        });
      } else if (isCard) {
        // if card, and card info is not completed
        const result = CheckoutHelper.validateCard(
          cdata?.cardNumber,
          cdata?.cardMonth,
          cdata?.cardYear,
          cdata?.cardCvv
        );
        if (!result.isValid) {
          title = result.title;
          message = result.message;
        }
      }
      if (!!title && !!message) {
        success = false;
        toast.hide();
        toast.show(`${title}\n${message}`, false);
        break; // stop loop
      }
    }
    return success;
  };

  return {
    ref: {
      scroll: scrollRef,
    },
    state: {
      detailsRequiredPrompt,
      detailsYAxis,
    },
    deliveryQuotation,
    hasBulk,
    noUserDetails: isUserDetailsIncomplete(user),
    storeId,
    storeMealPlanDeliveryDiscount,
    storeMealPlanDiscount,
    user,
    userDetails,
    getDeliveryQuotation: _getDeliveryQuotation,
    goToUserDetailsForm: _goToUserDetailsForm,
    onPaymentCardChange: _onPaymentCardChange,
    onPaymentMethodChange: _onPaymentMethodChange,
    validateForm: _validateForm,
  };
}

export default useCheckout;
