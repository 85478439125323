import { createSelector } from '@reduxjs/toolkit';
import lodashFilter from 'lodash/filter';

import constants from '../../Config/constants';

const cart = (state) => state.cart;

const getStoreCartData = (cartData, storeId) => {
  if (storeId) {
    return lodashFilter(cartData, { store_id: storeId });
  }

  return cartData;
};

const allCartSelector = createSelector(cart, (state) =>
  state?.cartData.filter?.(
    (cd) => cd.status !== constants.ORDER_STATUS.CONVERTED
  )
);

const pendingCartSelector = createSelector(allCartSelector, (carts) =>
  lodashFilter(carts, (c) => c.status === constants.ORDER_STATUS.PENDING)
);

const selectCart = createSelector(
  [
    allCartSelector, // base selector
    (_, storeId) => storeId, // for 2nd param
  ],
  getStoreCartData
);

export { allCartSelector, pendingCartSelector, selectCart };
