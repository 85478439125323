import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import {
  capitalize,
  formatDistance,
  formatDuration,
  pluralize,
} from '../../Helper';
import PromoHelper from '../../Helper/Promo';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import Badge from '../Badge';
import Button from '../Button';
import RatingText from '../RatingText';
import { ScaleButtonAnimation } from '../Web/Intereaction';

import BaseCard from './';
import styles, { ids, titleProps, subTitleProps } from './styles';
import ImageHelper from '../../Helper/Image';

const FeaturedPromoRow = ({ containerStyle, icon, small, text }) => (
  <View style={[localStyles.featuredPromoContainer, containerStyle]}>
    {Boolean(icon) && (
      <Icon
        name={icon}
        pack="material"
        fill={ThemeColor.white}
        style={[{ width: 15, height: 15 }, ThemeStyle.spacingRightSmall]}
      />
    )}
    <Text appearance="alternative" category={small ? 'c2' : 'c1'}>
      {text}
    </Text>
  </View>
);

const FeaturedPromo = ({ promo }) => {
  const featured = PromoHelper.getDiscount(promo[0]);
  const more = promo.length - 1;

  return (
    <View style={localStyles.featuredPromoWrapper}>
      {/* Featured */}
      <FeaturedPromoRow
        containerStyle={localStyles.featuredLeft}
        icon="ticket-confirmation"
        text={featured.discount}
      />
      {/* More Promo */}
      {more >= 1 && (
        <FeaturedPromoRow
          containerStyle={localStyles.featuredRight}
          text={`${more}+ more ${pluralize(more, 'promotion')}`}
          small
        />
      )}
    </View>
  );
};

function StoreCard({
  id,
  banner,
  partner_banner,
  disableSave,
  distance,
  duration,
  featured_promos,
  fullWidthOnMobile,
  imageOverlay,
  isSaved,
  name,
  store_tags = [],
  onPress,
  onSave,
}) {
  const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;
  return (
    <ClickableComponent onPress={onPress} plain>
      <View
        dataSet={{
          media: fullWidthOnMobile
            ? ids.storeResponsiveMobileContainer
            : ids.storeResponsiveContainer,
        }}
        style={[
          styles.storeContainer,
          fullWidthOnMobile
            ? styles.storeResponsiveMobileContainer
            : styles.storeResponsiveContainer,
        ]}
      >
        {/* Store image & Featured promo */}
        <View style={ThemeStyle.spacingBottomSmall}>
          {/* Store image and overlay */}
          <BaseCard
            image={ImageHelper.getStoreBannerImage(
              {
                partner_banner,
                banner,
              },
              true
            )}
            imageOverlay={imageOverlay}
          />
          {/* Featured promo */}
          {!lodashIsEmpty(featured_promos) && (
            <FeaturedPromo promo={featured_promos} />
          )}
        </View>
        {/* Store name & Save button */}
        <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
          <Text {...titleProps}>{name}</Text>
          <Button
            disabled={disableSave}
            hitSlop={{ top: 10, left: 10, bottom: 10 }}
            onPress={onSave}
            plain
          >
            <Icon
              name={isSaved ? 'heart' : 'heart-outline'}
              fill={isSaved ? ThemeColor.green : ThemeColor.green}
              style={styles.storeIcon}
            />
          </Button>
        </View>
        {/* Icon & Delivery duration, Store distance and store rating */}
        <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
          <View style={ThemeStyle.flexDirectionRowCenter}>
            {Boolean(duration) && (
              <Icon
                name="motorbike-electric"
                pack="material"
                fill={ThemeColor.green}
                style={[styles.storeIcon, ThemeStyle.spacingRightSmall]}
              />
            )}
            <Text {...subTitleProps}>
              {Boolean(duration) && `${formatDuration(duration)} min · `}
              {formatDistance(distance)}
            </Text>
          </View>

          <RatingText storeId={id} {...subTitleProps} />
        </View>
        {/* Store tags */}
        {!lodashIsEmpty(store_tags) && (
          <View
            style={[
              ThemeStyle.flexDirectionRowCenter,
              ThemeStyle.spacingTopSmall,
            ]}
          >
            {store_tags.slice(0, 3).map((tag, i) => (
              <View
                key={i}
                style={[{ flexShrink: 1 }, ThemeStyle.spacingRightSmall]}
              >
                <Badge
                  style={{
                    borderRadius: 20,
                    paddingHorizontal: 8,
                  }}
                  type={Badge.TYPES.SOLID}
                  numberOfLines={1}
                  adjustsFontSizeToFit
                  size={constants.isWeb ? 'c1' : undefined}
                >
                  {capitalize(tag)}
                </Badge>
              </View>
            ))}
          </View>
        )}
      </View>
    </ClickableComponent>
  );
}

const localStyles = StyleSheet.create({
  featuredPromoWrapper: {
    ...StyleSheet.absoluteFillObject,
    ...ThemeStyle.flexDirectionColumnSpaceBetween,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingMedium,
  },
  featuredPromoContainer: {
    ...ThemeStyle.flexDirectionRowCenter,
    ...ThemeStyle.alignSelfStart,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingSmall,
    ...ThemeStyle.shadow,
    backgroundColor: ThemeColor.green,
    borderRadius: 5,
  },
  featuredLeft: {
    ...ThemeStyle.alignSelfStart,
  },
  featuredRight: {
    ...ThemeStyle.alignSelfEnd,
    paddingVertical: globalSpacing / 8,
    borderRadius: 50,
  },
});

StoreCard.propTypes = {
  id: PropTypes.string.isRequired,
  banner: PropTypes.string.isRequired,
  disableSave: PropTypes.bool.isRequired,
  distance: PropTypes.number.isRequired,
  duration: PropTypes.number,
  featured_promos: PropTypes.arrayOf(
    PropTypes.shape({ discount: PropTypes.string.isRequired })
  ),
  fullWidthOnMobile: PropTypes.bool,
  imageOverlay: PropTypes.node,
  isSaved: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  store_tags: PropTypes.arrayOf(PropTypes.string),
  onPress: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default StoreCard;
