import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ScrollView, View } from 'react-native';
import { Divider, Spinner, Text } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import { useNavigation } from '@react-navigation/native';
import Modal from 'react-native-modal';
import dayjs from 'dayjs';

import { CANCEL_ERROR } from 'apisauce';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFind from 'lodash/find';

import Button from '../../Components/Button';
import modals from '../../Components/Sheets/modals';
import constants from '../../Config/constants';
import { capitalize } from '../../Helper';
import AnalyticsHelper, { CUSTOM_EVENTS } from '../../Helper/Analytics';
import {
  getCartTerm,
  getCheckoutContinueDelay,
} from '../../Helper/RemoteConfig';
import Sentry from '../../Helper/Sentry';
import withNavigation from '../../Hooks/withNavigation';
import { checkout } from '../../RTK/defaultValues';
import { clearOrderNumber, updateCheckoutDetails } from '../../RTK/checkout';
import routeList from '../../Routes/list';
import Service from '../../Service';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';
import { TITLE, SECTION_SUB_TITLE } from '../../Theme/typographyProps';

const { ORDER_TYPES } = constants;

let placeOrderTimer = null;
let countdownTimer = null;
function PlacingOrderModal({
  data,
  redirectUrlParams,
  visible,
  onRequest,
  onError,
  togglePlaceOrder,
}) {
  const timeToCancelOrder = getCheckoutContinueDelay();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(timeToCancelOrder);
  const [hideButton, setHideButton] = useState(false);
  const { cart, details } = data;
  const {
    [checkout.keys.DELIVERY_ADDRESS]: dAddress,
    [checkout.keys.ORDER_TYPE]: otype,
    [checkout.keys.PAYMENT_METHOD]: payment,
  } = details;

  useEffect(() => {
    if (visible) {
      // when modal is visible start counter to continue to checkout
      setHideButton(false);
      setCountdown(timeToCancelOrder);
      _clearTimer();
      countdownTimer = setInterval(
        () => setCountdown((prev) => prev - 1),
        1000
      );
      placeOrderTimer = setTimeout(() => {
        clearInterval(countdownTimer);
        _proceedToPlaceOrder();
      }, timeToCancelOrder * 1000);
    } else {
      // when modal is close clear timer for placing order
      _clearTimer();
    }
  }, [visible]);

  const _clearTimer = () => {
    clearTimeout(placeOrderTimer);
    clearTimeout(countdownTimer);
    placeOrderTimer = null;
    countdownTimer = null;
  };

  const _proceedToPlaceOrder = async () => {
    setHideButton(true); // hide cancel order button
    const { ok, status, data: apiData, problem } = await onRequest(); // api call
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    await togglePlaceOrder(true); // remove placing order modal bcs api call is done
    if (ok) {
      const checkoutParam = {
        storeId: data.storeId,
        url: apiData.redirect,
        payment,
        ...redirectUrlParams,
      };
      AnalyticsHelper.customEvent(CUSTOM_EVENTS.checkingOut);
      /// This is for web to clear the order number when accessing checkout webview
      dispatch(clearOrderNumber());
      dispatch(
        updateCheckoutDetails({
          store_id: data.storeId,
          keyToUpdate: checkout.keys.CONTINUE_TO_PAYMENT,
          keyValue: {
            checkout_date: dayjs().toISOString(),
            ...checkoutParam,
          },
        })
      );
      navigation.navigate(routeList.CHECKOUT_WEBVIEW, checkoutParam);
    } else if (status === 400) {
      Sentry.reportError('Error placing order', apiData);
      const isSpecificIdError = typeof apiData?.message[0] === 'string';
      if (isSpecificIdError) {
        const newErrMsg = apiData?.message
          .map((e) => {
            let [keyId, msg] = e.split(' - ');
            let [key, id] = keyId.split('.');
            const item = lodashFind(data.cart, { item_id: id });
            const transformedError = Service.handleErrorMessage(keyId);
            const keyName = key?.replace?.(/_/, ' ');
            const itemName = item?.name?.replace?.(/_/, ' ');
            if (msg) {
              return `- ${
                itemName || capitalize(keyName)
              }: ${Service.handleErrorMessage(msg)}`;
            } else if (
              transformedError?.toLowerCase() !== keyId?.toLowerCase()
            ) {
              return `- ${transformedError}`;
            } else {
              return `- ${itemName || capitalize(keyName)}`;
            }
          })
          .join('\n');
        if (constants.isWeb) {
          onError?.({
            errorType: constants.ERROR_TYPE.GENERIC,
            title: 'Validation Failed',
            message: newErrMsg.replace(/-/g, ''),
            unavailableItems: [],
          });
          return;
        } else {
          _showError('Validation Failed', newErrMsg);
        }
      } else if (!lodashIsEmpty(apiData?.message?.unavailable_items)) {
        const unavailableItems = apiData?.message?.unavailable_items.map(
          (itemId) => {
            const item = lodashFind(data.cart, { item_id: itemId });
            return item.name;
          }
        );
        if (constants.isWeb) {
          onError?.({
            errorType: constants.ERROR_TYPE.UNAVAILABLE,
            title: 'Unavailable',
            message: `Ooops! Looks like some of the item in your ${getCartTerm()} went out of stock while you were checking out. To proceed, select another time slot in the delivery schedule.`,
            unavailableItems: apiData?.message?.unavailable_items.map(
              (itemId) => {
                const item = lodashFind(data.cart, { item_id: itemId });
                return item.cart_details_id;
              }
            ),
          });
        } else {
          _showError(
            'Unavailable',
            `The following item is unavailable\n\n${Service.handleErrorMessage(
              unavailableItems
            )}\n\nPlease check and try again`
          );
        }
      } else {
        if (constants.isWeb) {
          onError?.({
            errorType: constants.ERROR_TYPE.GENERIC,
            title: 'Validation Failed',
            message: Service.handleFormError(apiData?.message, true).replace(
              /-/g,
              ''
            ),
            unavailableItems: [],
          });
        } else {
          _showError(
            'Validation Failed',
            Service.handleFormError(apiData?.message, true)
          );
        }
      }
    } else {
      const defaultMessage =
        'An error occured while trying to place your order.';
      Sentry.reportError('Error placing order', apiData);
      if (constants.isWeb) {
        onError?.({
          errorType: constants.ERROR_TYPE.GENERIC,
          title: 'Please try again',
          message: Service.handleErrorMessage(
            apiData?.message || defaultMessage
          ).replace(/-/g, ''),
          unavailableItems: [],
        });
      } else {
        _showError(
          'Please try again',
          Service.handleErrorMessage(apiData?.message || defaultMessage)
        );
      }
    }
  };

  const _showError = (title, message) => {
    setTimeout(() => {
      modals.show(modals.PROMPT, {
        title,
        message,
        buttonStatus: 'danger',
        buttonText: 'Close',
      });
    }, 500);
  };

  if (lodashIsEmpty(cart) || lodashIsEmpty(dAddress)) {
    return null;
  }

  if (constants.isWeb) {
    return (
      <Modal
        testID="placingOrderModal"
        isVisible={visible}
        animationIn="fadeIn"
        animationOut="fadeOut"
        deviceHeight={deviceHeight}
        style={{ margin: 0 }}
        useNativeDriver
        statusBarTranslucent
      >
        <View
          dataSet={{ media: ids.contentContainer }}
          style={styles.contentContainer}
        >
          <View
            style={[ThemeStyle.flex1, { marginTop: constants.statusBarHeight }]}
          >
            {/* Submitting and loader */}
            <View style={ThemeStyle.pageHorizontalSpacing}>
              <View
                style={[
                  ThemeStyle.flexDirectionRowSpaceBetween,
                  ThemeStyle.alignItemsCenter,
                  ThemeStyle.spacingBottomSmall,
                ]}
              >
                <Text
                  appearance="alternative"
                  category={constants.isWeb ? 's1' : 'h5'}
                >
                  Submitting Order...
                </Text>

                <View
                  style={[
                    ThemeStyle.alignItemsCenter,
                    ThemeStyle.justifyContentCenter,
                  ]}
                >
                  <Spinner size="giant" status="control" />
                  {!hideButton && (
                    <Text
                      appearance="alternative"
                      category="h6"
                      style={{ position: 'absolute' }}
                    >
                      {countdown}
                    </Text>
                  )}
                </View>
              </View>

              {/* Address */}
              {otype?.value === ORDER_TYPES.DELIVERY && (
                <Text appearance="alternative" {...SECTION_SUB_TITLE}>
                  {dAddress.formatted_address}
                </Text>
              )}
            </View>

            {/* Divider */}
            <Divider
              style={[
                ThemeStyle.spacingTopMedium,
                ThemeStyle.spacingBottomMedium,
              ]}
            />

            {/* Order details */}
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={[ThemeStyle.flex1, ThemeStyle.pageHorizontalSpacing]}
            >
              <View style={ThemeStyle.spacingBottomSmall}>
                <Text appearance="alternative" {...TITLE}>
                  {data?.storeName}
                </Text>
              </View>

              {cart.map((item, index) => (
                <View style={ThemeStyle.spacingBottomMedium} key={index}>
                  <Text appearance="alternative">
                    {item.quantity}&times; {item.name}
                  </Text>
                  {item?.extras?.map((e, i) => (
                    <Text
                      appearance="alternative"
                      style={ThemeStyle.spacingLeft}
                      key={i}
                      {...SECTION_SUB_TITLE}
                    >
                      + {e.name}
                    </Text>
                  ))}
                </View>
              ))}
            </ScrollView>
          </View>

          {!hideButton && (
            <View style={ThemeStyle.alignItemsCenter}>
              <Button
                testID="cancelOrderButton"
                style={ThemeStyle.buttonContentWhite}
                onPress={togglePlaceOrder}
                plain
              >
                <Text>Cancel order</Text>
              </Button>
            </View>
          )}
        </View>
      </Modal>
    );
  }
  return (
    <Modal
      testID="placingOrderModal"
      isVisible={visible}
      animationIn="fadeIn"
      animationOut="fadeOut"
      deviceHeight={deviceHeight}
      style={{ margin: 0 }}
      useNativeDriver
      statusBarTranslucent
    >
      <View
        style={[ThemeStyle.flex1, { marginTop: constants.statusBarHeight }]}
      >
        {/* Submitting and loader */}
        <View style={ThemeStyle.pageHorizontalSpacing}>
          <View
            style={[
              ThemeStyle.flexDirectionRowSpaceBetween,
              ThemeStyle.alignItemsCenter,
              ThemeStyle.spacingBottomSmall,
            ]}
          >
            <Text
              appearance="alternative"
              category={constants.isWeb ? 's1' : 'h5'}
            >
              Submitting Order...
            </Text>

            <View
              style={[
                ThemeStyle.alignItemsCenter,
                ThemeStyle.justifyContentCenter,
              ]}
            >
              <Spinner size="giant" status="control" />
              {!hideButton && (
                <Text
                  appearance="alternative"
                  category="h6"
                  style={{ position: 'absolute' }}
                >
                  {countdown}
                </Text>
              )}
            </View>
          </View>

          {/* Address */}
          {otype?.value === ORDER_TYPES.DELIVERY && (
            <Text appearance="alternative" {...SECTION_SUB_TITLE}>
              {dAddress.formatted_address}
            </Text>
          )}
        </View>

        {/* Divider */}
        <Divider
          style={[ThemeStyle.spacingTopMedium, ThemeStyle.spacingBottomMedium]}
        />

        {/* Order details */}
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={[ThemeStyle.flex1, ThemeStyle.pageHorizontalSpacing]}>
            <View style={ThemeStyle.spacingBottomSmall}>
              <Text appearance="alternative" {...TITLE}>
                {data?.storeName}
              </Text>
            </View>

            {cart.map((item, index) => (
              <View style={ThemeStyle.spacingBottomMedium} key={index}>
                <Text appearance="alternative">
                  {item.quantity}&times; {item.name}
                </Text>
                {item?.extras?.map((e, i) => (
                  <Text
                    appearance="alternative"
                    style={ThemeStyle.spacingLeft}
                    key={i}
                    {...SECTION_SUB_TITLE}
                  >
                    + {e.name}
                  </Text>
                ))}
              </View>
            ))}
          </View>
        </ScrollView>
      </View>

      {!hideButton && (
        <View
          style={[
            ThemeStyle.alignItemsCenter,
            {
              marginBottom: constants.isWeb ? 50 : constants.statusBarHeight,
            },
          ]}
        >
          <Button
            testID="cancelOrderButton"
            style={ThemeStyle.buttonContentWhite}
            onPress={togglePlaceOrder}
            plain
          >
            <Text>Cancel order</Text>
          </Button>
        </View>
      )}
    </Modal>
  );
}

const { ids, styles } = StyleSheet.create({
  contentContainer: {
    height: 680,
    alignSelf: 'center',
    rowGap: 10,
    [`@media ${BreakPoints.xs}`]: {
      width: 390,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 410,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 470,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 510,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 550,
    },
  },
});

PlacingOrderModal.propTypes = {
  data: PropTypes.shape({
    storeId: PropTypes.string,
    storeName: PropTypes.string,
    cart: PropTypes.arrayOf(PropTypes.object),
    details: PropTypes.object,
  }).isRequired,
  redirectUrlParams: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onRequest: PropTypes.func.isRequired,
  togglePlaceOrder: PropTypes.func.isRequired,
};

export default withNavigation(PlacingOrderModal);
