import React, { useEffect, useState } from 'react';
import ActionSheet from 'react-native-actions-sheet';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';
import TextInput from '../TextInput';
import ErrorPromptBelowInput from '../FormFields/ErrorPromptBelowInput';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

function DistanceSelectorSheet({ sheetId }) {
  const [data, setData] = useState();
  const [distance, setDistance] = useState(constants.DEFAULT_DISTANCE);

  const isLessThanMin = distance < constants.DEFAULT_DISTANCE;
  const isMoreThanMax = distance > constants.DISTANCE_MAX;
  const disableViewResult = isLessThanMin || isMoreThanMax;

  useEffect(() => {
    if (data?.value?.value) {
      setDistance(data.value.value);
    }
  }, [data?.value]);

  const _onViewResult = () => {
    if (distance >= constants.DEFAULT_DISTANCE)
      modals.hide(modals.DISTANCE_SELECTOR, { value: distance });
  };
  const _onReset = () =>
    modals.hide(modals.DISTANCE_SELECTOR, {
      value: constants.DEFAULT_DISTANCE,
    });

  return (
    <ActionSheet
      id={sheetId}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE}>
          Set store distance
        </Text>

        <Text style={ThemeStyle.spacingBottom} appearance="hint">
          Minimum {constants.DEFAULT_DISTANCE}
          {constants.DISTANCE_UNIT}
        </Text>

        <View style={ThemeStyle.spacingBottom}>
          <TextInput
            direction="column"
            type="number"
            placeholder={`e.g: 10 (10 ${constants.DISTANCE_UNIT})`}
            value={String(distance)}
            onChangeText={setDistance}
            withBackground
          />
          {isLessThanMin && (
            <ErrorPromptBelowInput
              text={`Minimum of ${constants.DEFAULT_DISTANCE} ${constants.DISTANCE_UNIT}`}
            />
          )}
          {isMoreThanMax && (
            <ErrorPromptBelowInput
              text={`More than ${constants.DISTANCE_MAX} ${constants.DISTANCE_UNIT} is not allowed`}
              warning
            />
          )}
        </View>

        <Button
          status="success"
          style={ThemeStyle.spacingBottomMedium}
          onPress={_onViewResult}
          disabled={disableViewResult}
        >
          {({ style: { fontWeight, ...rest } }) => (
            <Text style={rest}>View Results</Text>
          )}
        </Button>

        <Button
          status="control"
          onPress={_onReset}
          disabled={distance == constants.DEFAULT_DISTANCE}
        >
          {({ style: { fontWeight, ...rest } }) => (
            <Text style={rest}>Reset</Text>
          )}
        </Button>
      </View>
    </ActionSheet>
  );
}

export default DistanceSelectorSheet;
